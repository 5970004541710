/**
 * Renders a heatmap with points for each profile
 * @flow
 */
import { map } from "lodash";
import React, { Component } from "react";
import cx from "classnames";
import "./vendor";

import { getLetterColor } from "../../../data/utils/helpers";
import { calculatePoints, drawHeatmap } from "./utils";
import { Tyto } from "../../../typings/tyto";
// import Button from "../button/";
import { UserThumb } from "..";

import "./style.scss";

const negativeValue = 20;
const labels: {
  label: string;
  style: React.CSSProperties;
  letterStyle: React.CSSProperties;
  type: string;
}[] = [
  {
    label: "Driver",
    style: {
      top: `-${negativeValue}px`,
      left: "0px",
    },
    letterStyle: {
      top: "0px",
      left: "0px",
    },
    type: "d",
  },
  {
    label: "Influencer",
    style: {
      top: `-${negativeValue}px`,
      left: "50%",
    },
    letterStyle: {
      top: "0px",
      left: "50%",
    },
    type: "i",
  },
  {
    label: "Stabilizer",
    style: {
      bottom: `-${negativeValue}px`,
      left: "50%",
    },
    letterStyle: {
      top: "50%",
      left: "50%",
    },
    type: "s",
  },
  {
    label: "Analyzer",
    style: {
      bottom: `-${negativeValue}px`,
      left: "0px",
    },
    letterStyle: {
      top: "50%",
      left: "0px",
    },
    type: "c",
  },
];

interface Props {
  heatMapType?: 1 | 2 | 3;
  profiles: Tyto.DISCProfileMini[];
  size: number;
  onUserSelect?: (userID: number) => void;
}

interface State {
  heatMapType: number;
}

export default ({
  heatMapType: PropsHeatMapType,
  onUserSelect,
  profiles,
  size = 300,
}: Props) => {
  const heatmap = React.useRef<HTMLDivElement | null>(null);
  const [heatMapType, updateHeatMapType] = React.useState(
    PropsHeatMapType || 3
  );

  React.useEffect(() => {
    _drawHeatmap({
      profiles,
      size,
      heatmap,
      heatMapType,
    });
  }, [profiles, size, heatMapType]);

  React.useEffect(() => {
    if (PropsHeatMapType && PropsHeatMapType !== heatMapType) {
      updateHeatMapType(PropsHeatMapType || 3);
    }
  }, [PropsHeatMapType]);

  if (!profiles) {
    return null;
  }

  const points: {
    model: Tyto.DISCProfileMini;
    x: number;
    y: number;
    count: number;
    profile: Tyto.DISCProfileMini;
    quadrant: string;
  }[] = calculatePoints(profiles, heatMapType, size);
  const counts = points.reduce(
    (m: { [x: string]: number }, { quadrant }) => {
      m[quadrant]++;
      return m;
    },
    { D: 0, I: 0, S: 0, C: 0 }
  );
  // const offset = this.props.size / 20;
  const offset = 0;

  return (
    <div className="disc-heatmap-cont" style={{ height: size + 75 }}>
      <div
        className="disc-heatmap-info"
        style={{ height: size + 25, width: size }}
      >
        <div
          className="disc-heatmap-points-cont disc-grid-bg has-grid"
          style={{
            height: size,
            width: size,
            margin: `${negativeValue}px 0px ${negativeValue}px`,
          }}
        >
          {labels.map(({ label, style, type }) => (
            <p
              className="disc-heatmap-section-labels"
              key={label}
              style={{
                ...style,
                color: getLetterColor(type),
              }}
            >
              {label}
            </p>
          ))}

          {labels.map(({ label, letterStyle, type }) => (
            <p
              className="disc-heatmap-section-grid-letter"
              key={label}
              style={{
                ...letterStyle,
                fontSize: `${Math.floor(size / 4)}px`,
              }}
            >
              {type.toUpperCase()}
            </p>
          ))}

          <div
            className="disc-heatmap-points"
            ref={heatmap}
            style={{
              height: size - offset * 2,
              left: offset,
              top: offset,
              width: size - offset * 2,
            }}
          >
            {points.map(({ x, y, profile }) => (
              <UserPoint
                key={profile.personID}
                heatMapType={heatMapType}
                onUserSelect={onUserSelect}
                profile={profile}
                x={x}
                y={y}
              />
            ))}
          </div>
        </div>

        {/* <div className="disc-heatmap-btns">
          <Button
            className={
              "cc-disc-heatmap-btn" +
              (heatMapType == 3 ? " cc-disc-heatmap-btn-selected" : "")
            }
            type={heatMapType == 3 ? "color" : "hollow"}
            onClick={() => updateHeatMapType(3)}
            value="Ideal"
          />
          <Button
            className={
              "cc-disc-heatmap-btn" +
              (heatMapType == 1 ? " cc-disc-heatmap-btn-selected" : "")
            }
            onClick={() => updateHeatMapType(1)}
            type={heatMapType == 1 ? "color" : "hollow"}
            value="Stressed"
          />
        </div> */}

        {/* <div>
          <span className="disc-heatmap-cnt-txt ibm"> Counts by type: </span>
          {map(counts, (count, type) => (
            <span className="ibm" style={{ fontSize: 13 }} key={type}>
              {`${type}:`}
              <strong style={{ padding: "0 6px 0 2px" }}>{count}</strong>
            </span>
          ))}
        </div> */}
      </div>
    </div>
  );
};

const _drawHeatmap = ({
  profiles,
  size,
  heatmap,
  heatMapType,
}: {
  profiles: Tyto.DISCProfileMini[];
  size: number;
  heatmap: React.MutableRefObject<HTMLDivElement | null>;
  heatMapType: number;
}) => {
  // @ts-ignore
  if (profiles && heatmap) {
    drawHeatmap(
      profiles,
      // @ts-ignore
      heatmap.current,
      heatMapType,
      size
    );
  } else {
    console.warn(
      `Could not draw HeatMap. No Profiles? (${!profiles}). No heatmap? (${!heatmap})`
    );
  }
};

interface UserPointProps {
  heatMapType: 1 | 2 | 3;
  onUserSelect?: (userID: number) => void;
  profile: Tyto.DISCProfileMini;
  x: number;
  y: number;
}

const UserPoint = ({
  heatMapType,
  onUserSelect,
  profile,
  x,
  y,
}: UserPointProps) => {
  const [hovering, updateHovering] = React.useState(false);

  return (
    <div
      className="disc-heatmap-point-cont"
      key={profile.personID}
      //   style={{ top: y - 6, left: x - 6 }}
      style={{ top: y, left: x, zIndex: hovering ? 100 : undefined }}
      title={profile.personName || ""}
    >
      {/* <div className="disc-heatmap-point" /> */}
      <UserThumb
        asset={profile.profileImageAsset}
        className={cx(
          "disc-heatmap-point-userthumb",
          hovering && "disc-heatmap-point-userthumb-hovering"
        )}
        discValues={{
          d: profile[`d${heatMapType}` as "d3"],
          i: profile[`i${heatMapType}` as "i3"],
          s: profile[`s${heatMapType}` as "s3"],
          c: profile[`c${heatMapType}` as "c3"],
        }}
        omitHoverIcon={true}
        onClick={() => {
          if (onUserSelect) {
            onUserSelect(profile.personID);
          }
        }}
        onMouseEnter={() => updateHovering(true)}
        onMouseLeave={() => updateHovering(false)}
        size={hovering ? 30 : 14}
        style={{
          left: `-${hovering ? 15 : 7.5}px`,
          top: `-${hovering ? 15 : 7.5}px`,
        }}
        userName={profile.personName || ""}
      />
    </div>
  );
};
