/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import {
  StoreContext,
  RecipientsProps
} from "../../data/stores/contexts/AppStore";
import {
  addHandler,
  getRecipientsWithCallback,
  loadRecentlyRetrieved
} from "../../data/utils";
import { getFocusedPerson } from "../../data/utils/helpers/";
import { StoreState } from "../../data/stores/contexts/AppStore";
import TytoCalls from "../../data/tyto/";
import { Icon, Message, TextButton } from "../../components/common/";
import RecentlyRetrieved from "./subcomponents/RecentlyRetrieved";
import Person from "../person/";
import Team from "../team/";

import "./NewMessage.scss";
import { Tyto } from "../../typings/tyto";

export default () => {
  let store = React.useContext(StoreContext);
  const [noLiveUpdates, updateNoLiveUpdates] = React.useState(false);

  React.useEffect(() => {
    getRecipients({
      AppStore: store
    });

    // * Handler for changes to "CC" and "To" field
    addHandler(
      (people: Office.EmailAddressDetails[]) => {
        if (store.dispatch) {
          loadData(store, people);
        } else {
          console.log(
            "Tried to trigger recipients update but no store.dispatch found :("
          );
        }
      },
      errorMsg => {
        updateNoLiveUpdates(true);
      }
    );
  }, []);

  const { state } = store;

  return (
    <section className="interface-section">
      {noLiveUpdates && (
        <p
          style={{
            fontSize: "10px",
            color: "#000",
            width: "100%",
            textAlign: "center",
            margin: "0px"
          }}
        >
          <TextButton
            className="new-message-noliveupdates-refresh-button"
            onClick={() => {
              getRecipients({
                AppStore: store
              });
            }}
            value="Refresh Data"
          />

          <Icon
            className="new-message-noliveupdates-info-icon"
            title="Because this is a very old version of Outlook, the ability to live update information is not available for this Add-In. If you would like the ability to have the recipients update in real-time please consider using Outlook on the Web or updating your Outlook client."
            icon="question-circle"
            size={10}
          />
        </p>
      )}

      {state ? renderInterface(state) : <p>Creating State...</p>}
    </section>
  );
};

const renderInterface = (state: StoreState) => {
  // * [Option 1] No Recipients
  if (!state.recipients || !state.recipients.length) {
    if (
      !Array.isArray(state.recentlyRetrieved) ||
      !state.recentlyRetrieved.length
    ) {
      console.log("No recents found: ", state.recentlyRetrieved);
      return (
        <section>
          <Message value="No Recipients" style={{ fontWeight: "bold" }} />
        </section>
      );
    }

    if (!state.focusedPerson) {
      return <RecentlyRetrieved recentlyRetrieved={state.recentlyRetrieved} />;
    }
  }

  // return (
  //   <div style={{ minWidth: "200px", minHeight: "200px" }}>
  //     <h1 style={{ color: "#000", fontSize: "24px" }}>Single Recipient</h1>
  //   </div>
  // );

  // * [Option 2] Only one Recipient
  if (
    (state.recipients && state.recipients.length === 1) ||
    state.focusedPerson
  ) {
    const firstPerson = getFocusedPerson({
      recipients: state.recipients,
      recentlyRetrieved: state.recentlyRetrieved,
      focusedPerson: state.focusedPerson
    });

    if (!firstPerson || !firstPerson.emailAddress) {
      return <Message value="no info" />;
    }

    const discMini =
      state.discMini[firstPerson.emailAddress.toLowerCase()] ||
      state.recentlyRetrieved.find(
        profile =>
          firstPerson.emailAddress.toLowerCase() ===
          profile.emails[0].toLowerCase()
      );

    return (
      <Person
        discData={state.discData[firstPerson.emailAddress.toLowerCase()]}
        discMini={discMini}
        discProfileNA={state.discDataNull.some(
          email => email === firstPerson.emailAddress.toLowerCase()
        )}
        email={firstPerson.emailAddress}
      />
    );
  }

  const recipients = _.get(state, "personalDiscMini.emails[0]", undefined) ? [...(state.recipients || []), { emailAddress: _.get(state, "personalDiscMini.emails[0]"), displayName: _.get(state, "personalDiscMini.personName", "Me"), recipientType: "externalUser" } as Office.EmailAddressDetails] : state.recipients;

  // * [Option 3] Multiple Recipients
  return (
    <Team
      discDataNull={state.discDataNull}
      discMini={state.discMini}
      recipients={recipients}
    />
  );
};

const getRecipients = async ({ AppStore }: { AppStore: RecipientsProps }) => {
  if (AppStore.dispatch && Office) {
    loadRecents(AppStore);
    const recipients = await getRecipientsWithCallback();
    loadData(AppStore, recipients);
  }
};

const loadData = (
  store: RecipientsProps,
  recipients: Office.EmailAddressDetails[]
) => {
  if (store.dispatch) {
    store.dispatch({
      callback: callbackPayload => {
        TytoCalls.DISCProfilesMini.get({
          emails: callbackPayload.emails.join(",")
        })
          .then((resp: any) => {
            if (!store.dispatch) {
              return;
            }

            store.dispatch({
              payload: {
                emailsUsed: callbackPayload.emails,
                discMini: resp.discProfiles
              },
              type: "DISC_MINI_PROFILES_LOADED"
            });
          })
          .catch((err: any) => {
            console.warn(err);
          });
      },
      type: "RECIPIENTS_LOADED",
      payload: { recipients }
    });
  }
};

const loadRecents = (store: RecipientsProps) => {
  if (store.dispatch) {
    const recents = loadRecentlyRetrieved();
    store.dispatch({
      payload: {
        recentlyRetrieved: recents
      },
      type: "RECENT_CONTACTS_LOADED"
    });
  }
};
