/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Message, ToggleSection } from "../../../components/common/";

import "./TeamTips.scss";

interface Props {
  closedAtStart?: boolean;
  mixpanelKey?: string;
  onLinkClick?: () => void;
  tips: Array<React.FC | string>;
  title: string;
}

export default (props: Props) => {
  return (
    <ToggleSection
      title={props.title}
      className=""
      closedAtStart={!!props.closedAtStart}
      mixpanelKey={props.mixpanelKey}
      onLinkClick={props.onLinkClick}
      renderFunction={(isExpanded) => {
        return isExpanded ? (
          <ul className="team-team-tips-tip-list">
            {Array.isArray(props.tips) && props.tips.length ? (
              props.tips.map((SingleTip, curIdx) => (
                <li
                  className={cx(
                    "team-team-tips-tip",
                    !!curIdx && "team-team-tips-tip-not-first"
                  )}
                  key={typeof SingleTip === "string" ? SingleTip : curIdx}
                >
                  {/* <span className="team-team-tips-tip-dot">*</span> */}
                  {typeof SingleTip === "string" ? SingleTip : <SingleTip />}
                </li>
              ))
            ) : (
              <li className="team-team-tips-tip tips-empty-msg">
                <Message value="No Tips Found" style={{ marginTop: "20px" }} />
              </li>
            )}
          </ul>
        ) : (
          <span style={{ visibility: "hidden" }} />
        );
      }}
    />
  );
};

// import { Message, ToggleSection } from "../../../components/common/";
// import { TOGGLE_SECTION_BLOCKS } from "../../../data/constants/";

// import "./TeamTips.scss";

// interface Props {
//   closedAtStart?: boolean;
//   tips: string[];
//   title: string;
// }

// export default (props: Props) => {
//   return (
//     <ToggleSection
//       title={props.title}
//       className=""
//       mixpanelKey={decideBlockType(props.title)}
//       closedAtStart={!!props.closedAtStart}
//       renderFunction={(isExpanded) => {
//         return isExpanded ? (
//           <ul className="team-team-tips-tip-list">
//             {Array.isArray(props.tips) && props.tips.length ? (
//               props.tips.map((tip, curIdx) => (
//                 <li
//                   className={cx(
//                     "team-team-tips-tip",
//                     !!curIdx && "team-team-tips-tip-not-first"
//                   )}
//                 >
//                   {/* <span className="team-team-tips-tip-dot">*</span> */}
//                   {tip}
//                 </li>
//               ))
//             ) : (
//               <li className="team-team-tips-tip tips-empty-msg">
//                 <Message value="No Tips Found" style={{ marginTop: "20px" }} />
//               </li>
//             )}
//           </ul>
//         ) : (
//           <span style={{ visibility: "hidden" }} />
//         );
//       }}
//     />
//   );
// };

// const decideBlockType = (title: string) => {
//   if (!title) {
//     return TOGGLE_SECTION_BLOCKS.Team.TEAM_TIPS;
//   }

//   switch (title.toLowerCase()) {
//     case "meeting tips":
//       return TOGGLE_SECTION_BLOCKS.Team.TEAM_TIPS_MEETING;
//     case "decision making":
//       return TOGGLE_SECTION_BLOCKS.Team.TEAM_TIPS_DM;
//     case "strength areas":
//       return TOGGLE_SECTION_BLOCKS.Team.TEAM_TIPS_STRENGTHS;
//     case "challenges":
//       return TOGGLE_SECTION_BLOCKS.Team.TEAM_TIPS_CHALLENGES;
//     default:
//       return TOGGLE_SECTION_BLOCKS.Team.TEAM_TIPS;
//   }
// };
