/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon from "../icon/";
import { iconType } from "../icon/typings/";

import "./style.scss";

type ButtonShape = "rounded" | "square";

type ButtonFill = "hollow" | "color" | "active";

export interface Props {
  className?: string;
  disabled?: boolean;
  iconLeft?: iconType;
  iconRight?: string;
  iconProps?: Object;
  onClick: () => void;
  shape?: ButtonShape;
  style?: React.CSSProperties;
  type?: ButtonFill;
  value: string;
}

export default (props: Props) => {
  return (
    <button
      className={cx(
        "cc-button",
        getShapeStyling(props.shape),
        getTypeStyling(props.type),
        props.className
      )}
      disabled={props.disabled}
      onClick={props.onClick}
      style={props.style}
    >
      {props.iconLeft && (
        <Icon
          className="cc-button-icon"
          icon={props.iconLeft}
          size={20}
          {...(props.iconProps || {})}
        />
      )}

      {props.value}

      {props.iconRight && (
        <span className="cc-button-icon" style={props.iconProps} />
      )}
    </button>
  );
};

const getShapeStyling = (shape?: ButtonShape) => {
  switch (shape) {
    case "rounded":
      return "cc-button-rounded";
    case "square":
    default:
      return "cc-button-square";
  }
};

const getTypeStyling = (type?: ButtonFill) => {
  switch (type) {
    case "hollow":
      return "cc-button-hollow";
    case "active":
      return "cc-button-active";
    case "color":
    default:
      return "cc-button-color";
  }
};
