/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Button, Input } from "../../../components/common";
import TytoCalls from "../../../data/tyto/";

import "./Reset-Password.scss";

interface Props {
  onUpdate: () => void;
  onCancel: () => void;
}

export default (props: Props) => {
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [curPassword, updateCurPassword] = React.useState("");
  const [newPassword, updateNewPassword] = React.useState("");
  const [newPasswordConfirmed, updateNewPasswordConfirmed] = React.useState("");
  const [saving, updateSaving] = React.useState(false);

  const allValuesFilled = curPassword && newPassword && newPasswordConfirmed;
  const newPasswordsDoNotMatch = !!(
    newPassword &&
    newPasswordConfirmed &&
    newPassword !== newPasswordConfirmed
  );

  return (
    <div className="reset-password-cont">
      {errorMsg && <p className="reset-password-error-msg">{errorMsg}</p>}

      <p className="reset-password-label">Current Password</p>
      <Input
        containerClassName="reset-password-input-cont"
        className="reset-password-input"
        hideClear={true}
        name="Current Password"
        onChange={newVal => updateCurPassword(newVal)}
        type="password"
        value={curPassword}
      />

      <p className="reset-password-label">New Password</p>
      <Input
        containerClassName={cx(
          "reset-password-input-cont",
          newPasswordsDoNotMatch && "reset-password-input-cont-invalid"
        )}
        className="reset-password-input"
        name="New Password"
        hideClear={true}
        onChange={newVal => updateNewPassword(newVal)}
        type="password"
        value={newPassword}
      />

      <p className="reset-password-label">Confirm New Password</p>
      <Input
        containerClassName={cx(
          "reset-password-input-cont",
          newPasswordsDoNotMatch && "reset-password-input-cont-invalid"
        )}
        className="reset-password-input"
        name="Confirm New Password"
        onChange={newVal => updateNewPasswordConfirmed(newVal)}
        hideClear={true}
        type="password"
        value={newPasswordConfirmed}
      />

      <div className="reset-password-buttons-cont">
        <Button
          className="reset-password-button"
          disabled={saving}
          onClick={props.onCancel}
          type="hollow"
          shape="square"
          value="Cancel"
        />

        <Button
          className="reset-password-button"
          disabled={saving || newPasswordsDoNotMatch || !allValuesFilled}
          onClick={() => {
            updateSaving(true);

            saveNewPassword({
              curPassword,
              newPassword,
              confirmNewPasword: newPasswordConfirmed,
              onError: errMsg => {
                updateErrorMsg(errMsg);
                updateSaving(false);
              },
              onSuccess: () => {
                updateSaving(false);
                props.onUpdate();
              }
            });
          }}
          type="color"
          shape="square"
          value={saving ? "Setting Password..." : "Set Password"}
        />
      </div>
    </div>
  );
};

const saveNewPassword = async ({
  curPassword,
  newPassword,
  confirmNewPasword,
  onError,
  onSuccess
}: {
  curPassword: string;
  newPassword: string;
  confirmNewPasword: string;
  onError: (msg: string) => void;
  onSuccess: () => void;
}) => {
  if (
    !curPassword ||
    !newPassword ||
    !confirmNewPasword ||
    newPassword !== confirmNewPasword
  ) {
    return;
  }

  try {
    const resp = await TytoCalls.PersonMyPassword.put({
      oldPassword: curPassword,
      password: newPassword
    });

    onSuccess();
  } catch (err) {
    debugger;
    onError(
      typeof err === "string"
        ? err
        : _.get(
            err,
            "error.msg",
            _.get(
              err,
              "msg",
              "An error occurred while attempting to reset your password."
            )
          )
    );
  }
};
