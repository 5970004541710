/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

// import TeamTips from "../../team/subcomponents/TeamTips";
import { TOGGLE_SECTION_BLOCKS } from "../../../data/constants/";
import { DISCValueRender, Icon } from "../../../components/common/";
import { Tyto } from "../../../typings/tyto";
import Tips from "../../team/subcomponents/TeamTips";

import "./PersonCommTips.scss";

interface CommTips {
  header: string;
  items: {
    value: string;
  }[];
}

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discData: Tyto.DISCInfo;
  discMini: Tyto.DISCProfileMini;
  personName: string;
}

export default ({
  discCompareProfile,
  discData,
  discMini,
  personName,
}: Props) => {
  //   const [commTips, updateCommTips] = React.useState(pullCommTipsFromJSON(discData));
  const [commTips, updateCommTips] = React.useState(
    getCommTips(discCompareProfile)
  );

  React.useEffect(() => {
    updateCommTips(getCommTips(discCompareProfile));
  }, [discCompareProfile]);
  return (
    <DISCValueRender
      className="meta-sidepane-person-renderer-wrapper"
      isCompareValue={true}
      compareProfile={discCompareProfile}
      targetKey="communicationTips"
      discMiniProfile={discMini}
    >
      <Tips
        closedAtStart={false}
        mixpanelKey={TOGGLE_SECTION_BLOCKS.Person.COMM_TIPS}
        title={`${personName || "Their"} Email Tips`}
        tips={commTips}
      />
    </DISCValueRender>
  );
};

const getCommTipsArr = (discCompareProfile: Tyto.DISCCompareProfile) => {
  if (!discCompareProfile.communicationTips) {
    return [];
  }

  const [dos, donts] = discCompareProfile.communicationTips.reduce(
    (accum: Tyto.DISCCompareProfile.CommTip[][], item) => {
      accum[item.blnDo ? 0 : 1].push(item);

      return accum;
    },
    [
      [], // * Dos
      [], // * Don'ts
    ]
  );

  return [_.sortBy(dos, ["seq"], ["asc"]), _.sortBy(donts, ["seq"], ["asc"])];
};

const getCommTips = (discCompareProfile?: Tyto.DISCCompareProfile) => {
  if (!discCompareProfile) {
    return [];
  }

  // * For handling temporarly using static style commTips instead of customized Tips
  // * Customized tips found at commented out line below (discCompareProfile.communicationTips)
  const [doThese, dontDoThese] = getCommTipsArr(discCompareProfile);
  // const tipsArray = getStaticStyleCommTipsFormatted(discCompareProfile);
  // const tipsArray = discCompareProfile.communicationTips;

  return [...doThese, ...dontDoThese].map((tip) => {
    return () => {
      return (
        <React.Fragment key={tip.discCommTipID}>
          <Icon
            className={cx(
              "team-team-tips-tip-icon",
              tip.blnDo
                ? "team-team-tips-tip-icon-do"
                : "team-team-tips-tip-icon-donot"
            )}
            size={16}
            icon={tip.blnDo ? "check" : "close"}
          />
          <span className="team-team-tips-tip-text">{tip.tipDescription}</span>
        </React.Fragment>
      );
    };
  });
};
