/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Tyto } from "../../../../typings/tyto";
import UserThumb from "../../userthumb/";

import "./PlotPoint.scss";

const DEFAULT_COL_WIDTH = 180 / 4;

interface Props {
  colWidth: number;
  discLetter: string;
  discValue: number;
  discValues: {
    d: number;
    i: number;
    s: number;
    c: number;
  };
  forceBackground?: string;
  gradient: string;
  isFocusedLetter: boolean;
  isSelected: boolean;
  percentile?: {
    d: number;
    i: number;
    s: number;
    c: number;
  };
  selectProfile?: () => void;
  useR3Gradient: boolean;
  isHovered: boolean;
  onHover: (isLeave?: boolean) => void;
  personName?: string;
  photoAsset?: Tyto.Asset;
}

export default ({
  colWidth,
  discLetter,
  discValue,
  discValues,
  forceBackground,
  gradient,
  isSelected,
  onHover,
  isHovered,
  isFocusedLetter,
  percentile,
  personName = "",
  photoAsset,
  selectProfile,
  useR3Gradient
}: Props) => {
  const [plotRadius, updatePlotRadius] = React.useState(
    Math.floor((colWidth * (useR3Gradient ? 0.25 : 0.45)) / 2)
  );
  const [plotPosition, updatePlotPosition] = React.useState(0);

  React.useEffect(() => {
    updatePlotPosition(
      calcPlotPosition(discValue, colWidth || DEFAULT_COL_WIDTH)
    );
    // updatePlotRadius(calcPlotRadius(discValue, colWidth || DEFAULT_COL_WIDTH));
    console.log("I think the colWidth is: ", colWidth);
    updatePlotRadius(
      Math.floor((colWidth * (useR3Gradient ? 0.25 : 0.45)) / 2)
    );
  }, [discValue, colWidth]);

  if (isFocusedLetter) {
    const isHighValueExpection = Math.floor(discValue) >= 7;
    const isTextLowValueException = Math.floor(discValue) <= -6;
    const valueBetween16 = 8 + Math.floor(discValue);

    return (
      <div
        className={cx(
          "cc-disc-plot-graph-plot-point-focused-cont",
          `cc-disc-plot-graph-plot-point-focused-cont-${discLetter}`,
          isHighValueExpection &&
            "cc-disc-plot-graph-plot-point-focused-cont-flipped"
        )}
        style={{
          bottom: isHighValueExpection
            ? ""
            : `${valueBetween16 * ((1 / 16) * 100)}%`,
          top: isHighValueExpection
            ? `${(16 - valueBetween16) * ((1 / 16) * 100)}%`
            : ""
        }}
      >
        <span style={{ paddingRight: "4px" }}>
          {discValue >= 0 ? "+" : "-"}
        </span>
        {Math.abs(discValue).toFixed(1)}

        {percentile && (
          <div
            className={cx(
              "cc-disc-plot-graph-plot-point-focused-blurb-cont",
              isTextLowValueException &&
                "cc-disc-plot-graph-plot-point-focused-blurb-cont-move-to-top"
            )}
            style={{ width: `${colWidth}px` }}
          >
            <p className="cc-disc-plot-graph-plot-point-focused-blurb-span">
              higher than
            </p>
            <p className="cc-disc-plot-graph-plot-point-focused-blurb-span">
              {percentile[discLetter as "d" | "i" | "s" | "c"] || "some "}%
            </p>
            <p className="cc-disc-plot-graph-plot-point-focused-blurb-span">
              of users
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div
        className={cx(
          "cc-disc-plot-graph-plot-point",
          !useR3Gradient && `cc-disc-plot-graph-plot-point-${discLetter}`,
          useR3Gradient &&
            !isSelected &&
            !selectProfile &&
            "cc-disc-plot-not-selected",
          useR3Gradient && isSelected && "cc-disc-plot-is-selected",
          !!selectProfile && isHovered && "cc-disc-plot-can-is-hovered",
          !!selectProfile && "cc-disc-plot-can-be-selected"
        )}
        onClick={selectProfile}
        onMouseEnter={
          selectProfile
            ? () => {
                if (onHover) {
                  onHover();
                }
              }
            : undefined
        }
        onMouseLeave={
          selectProfile
            ? () => {
                if (onHover) {
                  onHover(true);
                }
              }
            : undefined
        }
        style={{
          width: plotRadius * 2,
          maxWidth: plotRadius * 2,
          height: plotRadius * 2,
          top:
            plotPosition < 0
              ? `${50 + Math.abs(plotPosition) * 50}%`
              : `${50 - plotPosition * 50}%`,
          // background:
          //   useR3Gradient && isSelected
          //     ? `linear-gradient(to bottom right${gradient})`
          //     : "",
          background: calcPlotColor({
            forceBackground,
            gradient,
            isSelected,
            useR3Gradient
          }),
          zIndex: isSelected ? 13 : 11
        }}
        role={selectProfile ? "button" : ""}
      >
        {useR3Gradient && isSelected && !selectProfile && (
          <div
            className="cc-disc-plot-graph-plot-point-withgradient-inner"
            // style={{ height: plotRadius, width: plotRadius }}
          />
        )}

        {isHovered && !!selectProfile && (
          <p
            className={`cc-disc-plot-graph-plot-point-value cc-disc-plot-graph-plot-point-value-${discLetter}`}
          >
            {discValue.toFixed(2)}
          </p>
        )}

        {(isHovered || isSelected) && !!selectProfile && (
          <UserThumb
            className="cc-disc-plot-graph-plot-point-userthumb"
            asset={photoAsset}
            size={plotRadius * 2}
            discValues={discValues}
            userName={personName}
          />
        )}
      </div>
    </>
  );
};

const calcPlotColor = (info: {
  forceBackground?: string;
  gradient: string;
  isSelected: boolean;
  useR3Gradient?: boolean;
}) => {
  if (info.forceBackground) {
    return info.forceBackground;
  } else if (info.useR3Gradient && info.isSelected) {
    return `linear-gradient(to bottom right${info.gradient})`;
  }

  return "";
};

export const calcPlotPosition = (discValue: number, colWidth: number) => {
  // * Revised to be linear
  return (discValue / 4) * 0.5;

  // TODO: Make programmatic function to calc position
  // ! Not scalable - just a rough first iteration
  // if (Math.abs(discValue) < 2) {
  //   const value = (discValue / 2) * 0.5;

  //   return value;
  // } else if (Math.abs(discValue) < 4) {
  //   const value = (discValue < 0 ? -0.5 : 0.5) + (discValue / 4) * 0.25;

  //   return value;
  // } else if (Math.abs(discValue) < 6) {
  //   const value = (discValue < 0 ? -0.75 : 0.75) + (discValue / 6) * 0.125;

  //   return value;
  // } else {
  //   const value = (discValue < 0 ? -0.875 : 0.875) + (discValue / 8) * 0.125;

  //   return value;
  // }
};

const calcPlotRadius = (discValue: number, colWidth: number) => {
  if (!discValue) {
    return 0;
  }

  let radiusValue = 0;
  const absDiscValue = Math.abs(discValue);

  // TODO: Make programmatic function to calc position
  // ! Not scalable - just a rough first iteration
  if (absDiscValue < 2) {
    radiusValue = (absDiscValue / 2) * 0.25;
  } else if (absDiscValue < 4) {
    radiusValue = (absDiscValue / 4) * 0.5;
  } else if (absDiscValue < 6) {
    radiusValue = (absDiscValue / 6) * 0.75;
  } else {
    radiusValue = (absDiscValue / 8) * 1;
  }

  return radiusValue * (colWidth * 0.85);

  //   return (Math.abs(discValue) / 8) * colWidth;
};
