/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import {
  StoreContext,
  RecipientsProps,
} from "../../data/stores/contexts/AppStore";
import { AuthResult } from "../../typings";

import { login } from "../../data/utils";
import {
  Button,
  Crane,
  Input,
  Loading,
  TextButton,
} from "../../components/common/";
import MultipleSessionsMatched from "./subcomponents/MultipleSessionsMatched";

import "./Logon.scss";

export default () => {
  let context = React.useContext(StoreContext);
  const [loginEmail, updateLoginEmail] = React.useState<string>(_.get(Office, "context.mailbox.userProfile.emailAddress", ""));
  const [password, updatePassword] = React.useState("");
  const [loggingIn, updateLoggingIn] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [authResults, updateAuthResults] = React.useState<AuthResult[] | undefined>(undefined);

  const failedSessionMsg = _.get(
    context,
    "state.failedSessionReason",
    undefined
  );

  return (
    <section className="interface-section login-main-wrapper">
      <div className="logon-inner-wrapper">

        {authResults && authResults.length ? (
          <MultipleSessionsMatched
            AppStore={context}
            authResults={authResults}
          />
        ) : (
          <div className="logon-inputs-box">
            <div className="logon-title-images">
              <Crane className="logon-title-crane" />
              <img
                className="logon-title-logo"
                title="TeamTools"
                src="/teamtools.png"
              />
            </div>

            <p className="logon-blurb">
              Be more prepared for meetings. Write better emails. Understand your
              coworkers better.
            </p>

            <p className="logon-blurb logon-blurb-2">
              Requires a TeamTools account for your organization. Find out more or
              get started at{" "}
              <a
                href="https://teamtools.com"
                target="_blank"
                style={{ color: "inherit" }}
              >
                teamtools.com
              </a>
            </p>

            {failedSessionMsg && (
              <p className="login-failedretrieval-msg">{failedSessionMsg}</p>
            )}

            <Input
              autofocus={!_.get(Office, "context.mailbox.userProfile.emailAddress", "")}
              containerClassName="logon-input-cont"
              hideClear={true}
              name="login-email"
              onChange={(value) => updateLoginEmail(value)}
              placeholder="email"
              value={loginEmail}
            />

            <Input
              autofocus={!!_.get(Office, "context.mailbox.userProfile.emailAddress", "")}
              containerClassName="logon-input-cont"
              hideClear={true}
              name="password"
              onChange={(value) => updatePassword(value)}
              onEnter={() =>
                loginCall({
                  context,
                  loginEmail,
                  password,
                  updateAuthResults,
                  updateError,
                  updateLoggingIn,
                })
              }
              placeholder="password"
              type="password"
              value={password}
            />

            <Button
              className="login-button"
              disabled={!loginEmail || !password || loggingIn}
              onClick={() =>
                loginCall({
                  context,
                  loginEmail,
                  password,
                  updateAuthResults,
                  updateError,
                  updateLoggingIn,
                })
              }
              value={loggingIn ? "Logging in..." : "Login"}
              type="color"
              shape="square"
            />

            <div className="logon-links-cont">
              <a
                className="logon-link"
                href="http://www.teamtools.com/help"
                target="_blank"
              >
                Help
              </a>

              <a
                className="logon-link"
                href="http://www.mocaworks.com/privacy.html"
                target="_blank"
              >
                Privacy
              </a>
            </div>

            <div className="login-teamtools-img-cont">
              <img className="login-teamtools-img" src="/teamtools-small.png" />
            </div>
          </div>
        )}

        <div className="logon-inner-wrapper-animation" />
      </div>

      <p style={{ fontSize: "10px" }}>
        {error && <p className="login-error-msg">{error}</p>}
      </p>
    </section>
  );
};

const loginCall = async ({
  context,
  loginEmail,
  password,
  updateAuthResults,
  updateError,
  updateLoggingIn,
}: {
  context: RecipientsProps;
  loginEmail: string;
  password: string;
  updateAuthResults: (authResults: AuthResult[]) => void;
  updateError: (msg: string) => void;
  updateLoggingIn: (newVal: boolean) => void;
}) => {
  if (context.dispatch) {
    updateLoggingIn(true);
    const userInfo = await login(loginEmail, password);

    if (!userInfo || !userInfo.authResults) {
      updateError("Error Occurred: Recieved undefined.");
      updateLoggingIn(false);
    } else if (userInfo.error && userInfo.error.sts && userInfo.error.msg) {
      const errorString = JSON.stringify(userInfo.error);

      const msgSts = `${_.get(userInfo, "error.sts", undefined)}`;

      // if (msgSts === "-225") {
      //   updateShowDomain();
      // }

      updateError(
        `Error Occurred: ${
          userInfo.error.sts ? `sts: ${userInfo.error.sts} ` : ""
        }${userInfo.error.msg || errorString || "Could Not parse error"}${
          userInfo.error.technical
            ? ` techincal: ${userInfo.error.technical}`
            : ""
        }`
      );
      updateLoggingIn(false);
    } else if (!userInfo.authResults.length) {
      const respAsString = JSON.stringify(userInfo);
      updateError(
        `Error Occurred while trying to login. (No session): ${respAsString}`
      );
      updateLoggingIn(false);
    } else {
      if (userInfo.authResults.length === 1) {
        const session = _.get(userInfo, "authResults[0].authSession", undefined);

        context.dispatch({
          type: "USER_LOGGED_IN",
          payload: session,
        });
      } else if (userInfo.authResults.length > 1) {
        updateAuthResults(userInfo.authResults);
      }
    }
  } else {
    updateError("Error: Cannot Login. (No dispatch)");
  }
};
