/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../typings/tyto";
import TytoCalls from "../../data/tyto/";
import {
  StoreContext,
  RecipientsProps,
} from "../../data/stores/contexts/AppStore";
import { triggerMixPanelEvent } from "../../data/logging/";

import TeamPeople from "./subcomponents/TeamPeople";
import TeamGroupStyle from "./subcomponents/TeamGroupStyle";
// import TeamAveragePersonality from "./subcomponents/TeamAveragePersonality";
import TeamTips from "./subcomponents/TeamTips";
import { calcGroupStyle, getGroupStyleWithStdDeviation } from "./utils/";
import {
  webAppLinkWithSession,
  // webAppGroupsPreview,
} from "../../data/utils/webapp/";
import { Icon } from "../../components/common/";

import "./Team.scss";

interface Props {
  recipients?: Office.EmailAddressDetails[];
  discMini?: {
    [x: string]: Tyto.DISCProfileMini;
  };
  discDataNull: string[];
}

export default (props: Props) => {
  let store = React.useContext(StoreContext);

  // * Local State -----------
  const teamCont = React.useRef(null);
  const [discDataAsArray, updateDiscDataAsArray] = React.useState(() => {
    const emails = getEmailsFromRecipients(props.recipients);

    if (!emails.length || !props.discMini) {
      return [];
    }

    const initialDiscData =
      emails && props.discMini
        ? (emails
            .map((email) => {
              if (props.discMini) {
                return props.discMini[email];
              }

              return undefined;
            })
            .filter((profile) => !!profile) as Tyto.DISCProfileMini[])
        : [];
    return initialDiscData;
  });
  const [groupStyle, updateGroupStyle] = React.useState(
    calcGroupStyle(discDataAsArray || [])
  );
  const [
    groupStyleKeyWithStdDeviation,
    updateGroupStyleKeyWithStdDeviation,
  ] = React.useState(getGroupStyleWithStdDeviation(groupStyle));
  const [teamStyle, updateTeamStyle] = React.useState(
    getTeamStyle(store, getGroupStyleWithStdDeviation(groupStyle))
  );

  // * Local Effects -----------
  // * [E-1] - Keeping Available Profiles Up To Date
  React.useEffect(() => {
    const emails = getEmailsFromRecipients(props.recipients);

    if (!emails.length || !props.discMini) {
      updateDiscDataAsArray([]);
    }

    const newDiscData =
      emails && props.discMini
        ? (emails
            .map((email) => {
              if (props.discMini) {
                return props.discMini[email];
              }

              return undefined;
            })
            .filter((profile) => !!profile) as Tyto.DISCProfileMini[])
        : [];

    updateDiscDataAsArray(newDiscData);

    const newGroupStyle = calcGroupStyle(newDiscData);
    updateGroupStyle(newGroupStyle);

    // const newTeamStyleKey = `${newGroupStyle[0] ? newGroupStyle[0].label : ""}${
    //   newGroupStyle[1] ? newGroupStyle[1].label : ""
    // }`;
    // const curTeamStyleKey = `${groupStyle[0] ? groupStyle[0].label : ""}${
    //   groupStyle[1] ? groupStyle[1].label : ""
    // }`;
    const newTeamStyleKey = getGroupStyleWithStdDeviation(newGroupStyle);
    const curTeamStyleKey = getGroupStyleWithStdDeviation(groupStyle);

    updateGroupStyleKeyWithStdDeviation(newTeamStyleKey);

    if (newTeamStyleKey && newTeamStyleKey !== curTeamStyleKey) {
      loadTeamStyle(store, newTeamStyleKey);
    }
  }, [props.recipients, props.discMini, props.discDataNull]);

  React.useEffect(() => {
    if (store.state) {
      const teamStyleKey = getGroupStyleWithStdDeviation(groupStyle);

      const newTeamStyle = getTeamStyle(store, teamStyleKey);

      updateTeamStyle(newTeamStyle);
    }
  }, [store]);

  React.useEffect(() => {
    if (groupStyleKeyWithStdDeviation) {
      const curTeamStyle = getTeamStyle(store, groupStyleKeyWithStdDeviation);

      if (!curTeamStyle) {
        loadTeamStyle(store, groupStyleKeyWithStdDeviation);
      }
    }
  }, []);

  const personIDsAsString = discDataAsArray
    ? discDataAsArray.map((mini) => mini.personID).join(",")
    : "";
  const sessionKey = _.get(store, "state.sessionData.sessionKey", "");
  const isEmail = /^MSG/i.test(_.get(store, "state.INTERFACE_CONTEXT", "MSGREAD"));
  const isEvent = /^APPT/i.test(_.get(store, "state.INTERFACE_CONTEXT", "MSGREAD"));

  // * Render --------------------
  return (
    <section className="team-main-cont" ref={teamCont}>
      <h1 className="team-main-title">Group Dynamic</h1>
      <div className="team-main-preview-link-cont">
        {sessionKey && discDataAsArray && !!discDataAsArray.length && (
          <a
            className="team-main-preview-link"
            target="_blank"
            href={`${webAppLinkWithSession(
              sessionKey
            )}/groups/preview?personIDs=${personIDsAsString}`}
            onClick={() => _logMixpanelEvent(discDataAsArray)}
          >
            Preview Group{" "}
            <Icon
              className="team-main-preview-link-icon"
              icon="external-link"
              size={10}
            />
          </a>
        )}
      </div>

      <TeamPeople
        discMini={discDataAsArray}
        discDataNull={props.discDataNull}
        recipients={props.recipients || []}
      />

      <TeamGroupStyle
        discMini={discDataAsArray}
        groupStyle={groupStyle}
        groupStyleKeyWithStdDeviation={groupStyleKeyWithStdDeviation}
        setFocusedUser={(userID: number) => {
          setFocusedUser({
            userID,
            Store: store,
          });
        }}
        teamContRef={teamCont}
        teamStyle={teamStyle}
      />

      {/* <TeamAveragePersonality discMini={discDataAsArray} /> */}

      {isEmail && <TeamTips
        title="When Emailing this Group"
        tips={teamStyle ? teamStyle.emailTips.split("\n") : []}
      />}
      {isEvent && <TeamTips
        title="Facilitating this Group"
        tips={teamStyle ? teamStyle.meetingTips.split("\n") : []}
      />}
      <TeamTips
        closedAtStart={false}
        title="Decision Making Style"
        tips={teamStyle ? teamStyle.decisionMaking.split("\n") : []}
      />
      <TeamTips
        title="Strength Areas"
        tips={teamStyle ? teamStyle.strengthAreas.split("\n") : []}
      />
      <TeamTips
        closedAtStart={false}
        title="Challenges Areas"
        tips={teamStyle ? teamStyle.challengeAreas.split("\n") : []}
      />
    </section>
  );
};

const getEmailsFromRecipients = (
  recipients?: Office.EmailAddressDetails[]
): string[] => {
  if (Array.isArray(recipients)) {
    return recipients.map((recipient) => recipient.emailAddress.toLowerCase());
  }

  return [];
};

const getTeamStyle = (
  store: RecipientsProps,
  styleKey: string
  // groupStyle: { label: string; value: number }[]
): Tyto.DISCTeamProfile | undefined => {
  // if (!Array.isArray(groupStyle) || !groupStyle.length) {
  //   console.warn("Attempted to retrieve teamStyle with empty groupStyle array");
  //   return undefined;
  // }
  if (!styleKey) {
    console.warn("Attempted to retrieve teamStyle with empty groupStyle array");
    return undefined;
  }

  // const teamStyleKey = `${groupStyle[0] ? groupStyle[0].label : ""}${
  //   groupStyle[1] ? groupStyle[1].label : ""
  // }`;

  console.log(
    "Attemped Get of teamStyle with key: ",
    styleKey,
    " from data: ",
    store.state ? store.state.discTeamStyle : undefined
  );

  return _.get(store, `state.discTeamStyle.${styleKey.slice(0, 2)}`);
};

const loadTeamStyle = async (store: RecipientsProps, styleKey: string) => {
  if (!styleKey) {
    return;
  }

  try {
    const teamStyleResp: any = await TytoCalls.DISCProfile.Team.get({
      styleKey: styleKey.slice(0, 2),
    });

    if (store.dispatch) {
      store.dispatch({
        payload: {
          discStyle: teamStyleResp.discStyle,
        },
        type: "DISC_TEAM_PROFILE_LOADED",
      });
    }
  } catch (err) {}
};

const _logMixpanelEvent = (discDataAsArray?: Tyto.DISCProfileMini[]) => {
  if (!discDataAsArray) {
    return;
  }

  triggerMixPanelEvent("TEAM_LAUNCH_WEBAPP", {
    groupSize: discDataAsArray.length,
  });
};

const setFocusedUser = ({
  userID,
  Store,
}: {
  userID?: number;
  Store: RecipientsProps;
}) => {
  if (Store && Store.dispatch && Store.state) {
    const emailAddress = userID ? Store.state.personIDEmailMap[userID] : "";
    const profile = Store.state.discMini[emailAddress.toLowerCase()];

    Store.dispatch({
      payload: {
        email: profile.emails[0].toLowerCase(),
      },
      type: "SET_FOCUSED_PROFILE",
    });
  }
};
