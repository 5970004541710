import { AccountSession } from "./AccountSession";
import { DISCProfileInteractive } from "./DiscProfile.Interactive";
import { DISCProfileInteractiveMockup } from "./DiscProfile.Interactive.Mockup";
import { DISCProfileTeam } from "./DiscProfile.Team";
import { DISCProfiles } from "./DiscProfiles";
import { DISCProfilesMini } from "./DiscProfilesMini";
import { LoginAuthenticate } from "./LoginAuthenticate";
import { LoginAuthenticate4 } from "./Login.Authenticate4";
import { Person } from "./Person";
import { PersonMyPassword } from "./Person.MyPassword";
import { PeopleKeysInitialize } from "./PeopleKeys.Initialize";
import { PeopleKeysNextPage } from "./PeopleKeys.NextPage";
import { PeopleKeysPages } from "./PeopleKeys.Pages";
import { PeopleKeysReport } from "./PeopleKeysCallback.Report";
import { PeopleKeysResponse } from "./PeopleKeys.Response";
import { PersonAdvanced } from "./PersonAdvanced";
import { TeamsByFunction } from "./TeamsByFunction";

export default {
  AccountSession,
  DISCProfiles,
  DISCProfile: {
    Interactive: DISCProfileInteractive,
    InteractiveMockup: DISCProfileInteractiveMockup,
    Team: DISCProfileTeam
  },
  DISCProfilesMini,
  LoginAuthenticate,
  Login: {
    Authenticate4: LoginAuthenticate4
  },
  PeopleKeys: {
    Initialize: PeopleKeysInitialize,
    NextPage: PeopleKeysNextPage,
    Pages: PeopleKeysPages,
    Report: PeopleKeysReport,
    Response: PeopleKeysResponse
  },
  Person,
  PersonMyPassword,
  PersonAdvanced,
  TeamsByFunction
};
