import mixpanel from "mixpanel-browser";
import * as _ from "lodash";

import { MixPanelTrackEvents } from "../../typings/logging";

export function initMixPanelPerson(userIdentifier: string | number) {
  mixpanel.identify(`${userIdentifier}`);
}

export function triggerMixPanelEvent(
  eventName: MixPanelTrackEvents.MixPanelEventType,
  eventData: Object
) {
  console.log(`LOGGING EVENT: `, { type: eventName, data: eventData });

  if (/localhost/i.test(window.location.hostname)) {
    return;
  }

  mixpanel.track(eventName, eventData);
}

export function setMixPanelUserProperties(
  userIdentifier: string | number,
  properties: Object
) {
  const info = {
    userID: userIdentifier,
    ...(properties || {}),
  };

  // const infoAsJson = JSON.stringify(info);

  if (_.get(mixpanel, "people.set", undefined)) {
    mixpanel.people.set(info);
  }
}

export const foo = "bar";
