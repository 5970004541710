/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { StoreContext } from "../../../data/stores/contexts/AppStore";
import {
  Icon,
  ToggleSection,
  UserThumb,
  UserThumbPlaceholder,
} from "../../../components/common";
import { TOGGLE_SECTION_BLOCKS } from "../../../data/constants/";

import { Tyto } from "../../../typings/tyto";

import "./TeamPeople.scss";

interface Props {
  discMini: Tyto.DISCProfileMini[];
  discDataNull: string[];
  recipients: Office.EmailAddressDetails[];
}

export default (props: Props) => {
  let store = React.useContext(StoreContext);

  return (
    <ToggleSection
      className="team-section-cont team-people-cont"
      mixpanelKey={TOGGLE_SECTION_BLOCKS.Team.PEOPLE}
      closedAtStart={true}
      renderFunction={(isExpanded, sectionWrapperRef) => {
        const [sectionWrapperWidth, updateSectionWrapperWidth] = React.useState(
          getSectionWidth(sectionWrapperRef)
        );
        React.useEffect(() => {
          updateSectionWrapperWidth(getSectionWidth(sectionWrapperRef));
        }, [sectionWrapperRef]);

        const recipientsFitCount = getRecipientsThatCanFitCount(
          sectionWrapperWidth
        );
        const firstRecipients = _.slice(
          props.recipients,
          0,
          recipientsFitCount ? recipientsFitCount - 1 : 0
        );
        const showMoreIcon =
          !isExpanded &&
          props.recipients.length &&
          recipientsFitCount &&
          recipientsFitCount - 1 < props.recipients.length;

        return (
          <>
            {(showMoreIcon ? firstRecipients : props.recipients).map(
              (recipient, idx) => (
                <UserProfile
                  discDataNull={props.discDataNull}
                  profile={
                    store && store.state
                      ? store.state.discMini[
                          recipient.emailAddress.toLowerCase()
                        ]
                      : undefined
                  }
                  idx={idx}
                  isExpanded={isExpanded}
                  key={recipient.emailAddress}
                  recipientInfo={recipient}
                  setAsFocusedProfile={() => {
                    if (store && store.dispatch && store.state) {
                      const profile =
                        store.state.discMini[
                          recipient.emailAddress.toLowerCase()
                        ];

                      store.dispatch({
                        payload: {
                          email: profile.emails[0].toLowerCase(),
                        },
                        type: "SET_FOCUSED_PROFILE",
                      });
                    }
                  }}
                />
              )
            )}

            {showMoreIcon && (
              <div className="team-people-person-userthumb-cont more-icon-cont">
                <button className="team-people-person-more-icon">
                  +{props.recipients.length - firstRecipients.length}
                </button>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

const getRecipientsThatCanFitCount = (sectionWrapperWidth: number) => {
  if (!sectionWrapperWidth) {
    return 4;
  }

  return Math.floor((sectionWrapperWidth - 10 - 24) / 33);
};

const getSectionWidth = (
  sectionContRef: React.MutableRefObject<null | HTMLElement>
) => {
  //   debugger;
  if (sectionContRef.current) {
    const info = sectionContRef.current.getBoundingClientRect();
    return info.width;
  }

  return 0;
};

interface UserProfileProps {
  discDataNull: string[];
  profile?: Tyto.DISCProfileMini;
  idx: number;
  isExpanded: boolean;
  recipientInfo: Office.EmailAddressDetails;
  setAsFocusedProfile: () => void;
}

const UserProfile = ({
  discDataNull,
  profile,
  idx,
  isExpanded,
  recipientInfo,
  setAsFocusedProfile,
}: UserProfileProps) => {
  // * Local State
  const [profileUnavailable, updateProfileUnavailable] = React.useState(
    checkIfNull(recipientInfo.emailAddress.toLowerCase(), discDataNull)
  );
  const [loading, updateLoading] = React.useState(() => {
    const isUnavailable = checkIfNull(
      recipientInfo.emailAddress.toLowerCase(),
      discDataNull
    );

    return !profile && !isUnavailable;
  });

  // * Local Effect
  // * [E-1] - Check if Profile is still loading, is Unavailable, or is Available
  React.useEffect(() => {
    const isUnavailable = checkIfNull(
      recipientInfo.emailAddress.toLowerCase(),
      discDataNull
    );

    if (loading) {
      updateLoading(!profile && !isUnavailable);
    }

    updateProfileUnavailable(isUnavailable);
  }, [discDataNull, profile]);

  return (
    <div
      className={cx(
        "team-people-person-userthumb-cont",
        !idx && "first-userthumb",
        isExpanded && "team-people-person-userthumb-cont-full-row"
      )}
    >
      {profile ? (
        <UserThumb
          asset={profile.profileImageAsset}
          className="team-people-person-userthumb"
          discMiniProfile={profile}
          discValues={{
            d: profile.d3,
            i: profile.i3,
            s: profile.s3,
            c: profile.c3,
          }}
          hasGradient={true}
          key={profile.personID}
          onClick={setAsFocusedProfile}
          size={43}
          userName={profile.personName}
        />
      ) : !profileUnavailable ? (
        <UserThumbPlaceholder
          className="team-people-person-userthumb"
          size={43}
        />
      ) : (
        <UserThumb
          asset={{} as Tyto.Asset}
          className={cx("team-people-person-userthumb", "unavailable-profile")}
          discValues={{
            d: 0,
            i: 0,
            s: 0,
            c: 0,
          }}
          hasGradient={false}
          size={43}
          userName={(
            recipientInfo.displayName || recipientInfo.emailAddress
          ).charAt(0)}
        />
      )}

      {isExpanded && (
        <div
          className="team-people-person-info-cont"
          role="button"
          onClick={setAsFocusedProfile}
        >
          <p className="team-people-person-info-name">
            {profile ? profile.personName : ""}
            {!profile && profileUnavailable && recipientInfo.emailAddress}
          </p>
          <p
            className={cx(
              "team-people-person-info-jobtitle",
              !profile && profileUnavailable && "profile-is-unavailable"
            )}
          >
            {profile ? profile.jobTitle : ""}
            {!profile && profileUnavailable && "Profile Unavailable"}
          </p>

          {!loading && profile && (
            <Icon
              buttonProps={{
                className: "team-people-person-info-btn",
              }}
              onClick={setAsFocusedProfile}
              icon="angle-right"
              isButton={true}
              size={30}
            />
          )}
        </div>
      )}
    </div>
  );
};

const checkIfNull = (email: string, discDataNull: string[]) => {
  // * Returns true if email is found in array of NA/NF profiles
  return discDataNull.some((nullEmail) => nullEmail.toLowerCase() === email);
};
