import { ENDPOINT_PERSON_MYPASSWORD_URL } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PERSON_MYPASSWORD_URL;

export const PersonMyPassword = {
  put(
    params: Endpoints.Tyto.Person.MyPassword.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("put", endpoint, { ...params }, callOpts);
  }
};
