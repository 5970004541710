import * as _ from "lodash";

import { Tyto } from "../../../typings/tyto";
import { PersonData } from "../../stores/contexts/AppStore";
import { SessionData } from "../../../typings";
import {
  DISC_COLOR_D,
  DISC_COLOR_I,
  DISC_COLOR_S,
  DISC_COLOR_C,
  DISC_COLOR_D_DARK,
  DISC_COLOR_I_DARK,
  DISC_COLOR_S_DARK,
  DISC_COLOR_C_DARK,
  GRADIENT_DIFF_PERCENT_WIDTH,
  SESSION_DATA_KEY,
} from "../../constants/";
import { iconType } from "../../../components/common/icon/typings/";

const discColorKeyMap = {
  d: DISC_COLOR_D,
  i: DISC_COLOR_I,
  s: DISC_COLOR_S,
  c: DISC_COLOR_C,
};

export function calcGradient(discValues: {
  d: number;
  i: number;
  s: number;
  c: number;
}) {
  if (!discValues) {
    return "";
  }

  // * [1] Remove non-positive values
  const positiveValues: {
    discKey: "d" | "i" | "s" | "c";
    value: number;
    labelSortValue: number;
  }[] = ([
    { discKey: "d", value: discValues.d, labelSortValue: 1 },
    { discKey: "i", value: discValues.i, labelSortValue: 2 },
    { discKey: "s", value: discValues.s, labelSortValue: 3 },
    { discKey: "c", value: discValues.c, labelSortValue: 4 },
  ] as {
    discKey: "d" | "i" | "s" | "c";
    value: number;
    labelSortValue: number;
  }[])
    .filter(({ value }) => value > 0)
    .reduce(
      (
        accum: {
          discKey: "d" | "i" | "s" | "c";
          value: number;
          labelSortValue: number;
        }[],
        discItem
      ) => {
        if (accum.length < 1) {
          return [...accum, discItem];
        } else {
          return _.sortBy([...accum, discItem], ["value"]).slice(-2);
        }
      },
      []
    );

  // * [2] Get total sum of positive values
  const sum: number = positiveValues.reduce((accum, discItem) => {
    accum += discItem.value;

    return accum;
  }, 0);

  // * [3] Check if IC are next to each other (to avoid inaccurqte gradient)
  const hasNeighboringIC = positiveValues
    .map((discItem) => discItem.discKey)
    .join("")
    .includes("ic");

  // * [4] Use values to give equivalent percentage out of 100%, then sort into DISC order
  const valuesWithPercentage = positiveValues.map((discItem) => {
    return {
      ...discItem,
      percentage: (discItem.value * 100) / sum,
    };
  });

  const gradient =
    valuesWithPercentage.length === 1
      ? `, ${getDISCColor(
          valuesWithPercentage[0].discKey,
          false
        )} 0% 10%, ${getDISCColor(valuesWithPercentage[0].discKey, true)} 100%`
      : valuesWithPercentage.reduce((accum, discItem, curIdx) => {
          let curItem = "";

          if (!curIdx) {
            const firstItemEnd = Math.max(
              Math.min(
                discItem.percentage - GRADIENT_DIFF_PERCENT_WIDTH / 2,
                100
              ),
              0
            );

            curItem = `0%, ${
              discColorKeyMap[discItem.discKey]
            } ${firstItemEnd.toFixed(3)}%`;
          } else {
            const secondItemStart = Math.max(
              Math.min(
                discItem.percentage + GRADIENT_DIFF_PERCENT_WIDTH / 2,
                100
              ),
              0
            );

            curItem = `${secondItemStart.toFixed(3)}%`;
          }

          return `${accum}, ${discColorKeyMap[discItem.discKey]} ${curItem}${
            hasNeighboringIC && discItem.discKey === "i" ? ", #fff 0%" : ""
          }`;
        }, "");

  return gradient;
}

export function calcGradientForIcon(discValues: {
  d: number;
  i: number;
  s: number;
  c: number;
}) {
  if (!discValues) {
    return undefined;
  }

  // * [1] Remove non-positive values
  const positiveValues: {
    discKey: "d" | "i" | "s" | "c";
    value: number;
    labelSortValue: number;
  }[] = ([
    { discKey: "d", value: discValues.d, labelSortValue: 1 },
    { discKey: "i", value: discValues.i, labelSortValue: 2 },
    { discKey: "s", value: discValues.s, labelSortValue: 3 },
    { discKey: "c", value: discValues.c, labelSortValue: 4 },
  ] as {
    discKey: "d" | "i" | "s" | "c";
    value: number;
    labelSortValue: number;
  }[])
    .filter(({ value }) => value > 0)
    .reduce(
      (
        accum: {
          discKey: "d" | "i" | "s" | "c";
          value: number;
          labelSortValue: number;
        }[],
        discItem
      ) => {
        if (accum.length < 1) {
          return [...accum, discItem];
        } else {
          return _.sortBy([...accum, discItem], ["value"]).slice(-2);
        }
      },
      []
    );

  // * [2] Get total sum of positive values
  const sum: number = positiveValues.reduce((accum, discItem) => {
    accum += discItem.value;

    return accum;
  }, 0);

  // * [3] Check if IC are next to each other (to avoid inaccurqte gradient)
  const hasNeighboringIC = positiveValues
    .map((discItem) => discItem.discKey)
    .join("")
    .includes("ic");

  // * [4] Use values to give equivalent percentage out of 100%, then sort into DISC order
  const valuesWithPercentage = positiveValues.map((discItem) => {
    return {
      ...discItem,
      percentage: (discItem.value * 100) / sum,
    };
  });

  return valuesWithPercentage.map((value, curIdx) => ({
    offsetPercent: !curIdx
      ? value.percentage - GRADIENT_DIFF_PERCENT_WIDTH / 2
      : value.percentage + GRADIENT_DIFF_PERCENT_WIDTH / 2,
    color: getDISCColor(value.discKey),
  }));
}

function calcMean(values: number[]) {
  return values.reduce((total, number) => total + number, 0) / values.length;
}

export function calcStandardDeviation(values: number[]) {
  let m = calcMean(values);

  return Math.sqrt(
    values.reduce((sq, n) => {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (values.length - 1)
  );
}

const getDISCColor = (discKey: "d" | "i" | "s" | "c", useDark?: boolean) => {
  switch (discKey) {
    case "d":
      if (useDark) {
        return DISC_COLOR_D_DARK;
      }

      return DISC_COLOR_D;
    case "i":
      if (useDark) {
        return DISC_COLOR_I_DARK;
      }

      return DISC_COLOR_I;
    case "s":
      if (useDark) {
        return DISC_COLOR_S_DARK;
      }

      return DISC_COLOR_S;
    case "c":
      if (useDark) {
        return DISC_COLOR_C_DARK;
      }

      return DISC_COLOR_C;
    default:
      return "#ffffff";
  }
};

const iconTypes: iconType[] = [
  "anchor",
  "angle-down",
  "angle-left",
  "angle-right",
  "angle-up",
  "balance-scale",
  "brain",
  "chess-knight",
  "chess-rook",
  "close",
  "circle",
  "comment-alt-exclamation",
  "compass",
  "dove",
  "drum",
  "envelope",
  "filter",
  "glass-cheers",
  "glasses",
  "hat-cowboy-side",
  "id-card",
  "knight",
  "leaf-oak",
  "lightbulb-on",
  "list-ul",
  "minus",
  "pencil-ruler",
  "pennant",
  "person-dolly",
  "phone",
  "plus",
  "project-diagram",
  "projector",
  "puzzle-piece",
  "question",
  "question-circle",
  "rabbit-fast",
  "rocket-launch",
  "route",
  "ruler-triangle",
  "search",
  "seedling",
  "sign-out",
  "slash",
  "solar-system",
  "sword",
  "umbrella",
  "users",
];

export function isValidIcon(icon?: iconType): iconType {
  console.log("isValidIcon recieved: ", icon);
  if (!icon) {
    return "question-circle";
  }

  const isValidIcon = iconTypes.some((validIcon) => validIcon === icon);
  console.log("isValidIcon revaluated to: ", isValidIcon, " With icon: ", icon);

  return isValidIcon ? icon : "question-circle";
}

export function findRecipientsWithoutPeopleInfo(
  peopleData: { [x: string]: PersonData },
  recipients: any[]
) {
  const peopleWithoutPeopleData = recipients.filter(
    (person) => !peopleData[person.emailAddress]
  );
  console.log("People without DISC data found: ", peopleWithoutPeopleData);

  return peopleWithoutPeopleData;
}

export function findRecipientsWithoutDISCMiniInfo(
  discMini: { [x: string]: Tyto.DISCProfileMini },
  recipients: Office.EmailAddressDetails[]
): Office.EmailAddressDetails[] {
  const peopleWithoutDISCMiniData = recipients.filter(
    (person) => !discMini[person.emailAddress.toLowerCase()]
  );
  console.log(
    `(${peopleWithoutDISCMiniData.length}) People without DISC data found: `,
    peopleWithoutDISCMiniData
  );

  return peopleWithoutDISCMiniData;
}

export function getFocusedPerson({
  focusedPerson,
  recipients,
  recentlyRetrieved,
}: {
  recipients?: Office.EmailAddressDetails[];
  focusedPerson?: string;
  recentlyRetrieved?: Tyto.DISCProfileMini[];
}) {
  if (!focusedPerson && (!recipients || !recipients.length)) {
    return undefined;
  }

  if (focusedPerson) {
    if (
      (!recipients || !recipients.length) &&
      (!recentlyRetrieved || !recentlyRetrieved.length)
    ) {
      return undefined;
    }

    const personFromRecipients = focusedPerson
      ? (recipients || []).find(
          (recipient) => recipient.emailAddress.toLowerCase() === focusedPerson
        )
      : undefined;

    if (personFromRecipients) {
      return personFromRecipients;
    }

    const personFromRecent = focusedPerson
      ? (recentlyRetrieved || []).find(
          (recent) => recent.emails[0].toLowerCase() === focusedPerson
        )
      : undefined;

    return personFromRecent
      ? {
          emailAddress: personFromRecent.emails[0],
          displayName: personFromRecent.personName,
        }
      : undefined;
  }

  return recipients ? recipients[0] : undefined;
}

export function getItemFromSessionData(key: keyof SessionData) {
  const sessionData = Office.context.roamingSettings.get(SESSION_DATA_KEY);

  if (!sessionData || typeof sessionData !== "object") {
    return undefined;
  }

  return sessionData[key];
}

export function getLetterColor(label: string, asDark?: boolean) {
  if (asDark) {
    switch (label) {
      case "d":
        return DISC_COLOR_D_DARK;
      case "i":
        return DISC_COLOR_I_DARK;
      case "s":
        return DISC_COLOR_S_DARK;
      case "c":
        return DISC_COLOR_C_DARK;
      default:
        return "#fff";
    }
  }

  switch (label) {
    case "d":
      return DISC_COLOR_D;
    case "i":
      return DISC_COLOR_I;
    case "s":
      return DISC_COLOR_S;
    case "c":
      return DISC_COLOR_C;
    default:
      return "#fff";
  }
}

export function getNameFromLetter(label: string) {
  switch (label) {
    case "d":
      return "Dominant";
    case "i":
      return "Influential";
    case "s":
      return "Steady";
    case "c":
      return "Concientious";
    default:
      return "";
  }
}

function mapMiniProfileByEmails(
  discMiniProfile: Tyto.DISCProfileMini
): { [x: string]: Tyto.DISCProfileMini } {
  if (!discMiniProfile || !discMiniProfile.emails) {
    return {};
  }

  const profileMappedToEmails = _.reduce(
    discMiniProfile.emails,
    (accum: { [x: string]: Tyto.DISCProfileMini }, email) => {
      accum[email.toLowerCase()] = discMiniProfile;

      return accum;
    },
    {}
  );

  console.log("Profile Mapped to Emails: ", profileMappedToEmails);
  return profileMappedToEmails;
}

export function mapPersonEmailsByPersonIDs(
  discMiniProfiles: Tyto.DISCProfileMini[]
): { [x: number]: string } {
  const mappedProfiles = _.reduce(
    discMiniProfiles,
    (accum, discMiniProfile) => {
      // * For incomplete DISC Profiles
      if (!discMiniProfile.styleKey3) {
        return accum;
      }

      const mappedByEmailPersonID = {
        [discMiniProfile.personID]: discMiniProfile.emails[0],
      };

      return {
        ...accum,
        ...mappedByEmailPersonID,
      };
    },
    {}
  );

  return mappedProfiles;
}

export function mapMiniProfilesByEmails(
  discMiniProfiles: Tyto.DISCProfileMini[],
  sessionDomainID: number
): { [x: string]: Tyto.DISCProfileMini } {
  const sorted = _.sortBy(discMiniProfiles, (profile) => {
    // * [1] - No assessment, return 0
    if (
      (profile.discStatus || "").toLocaleLowerCase() !== "ocenabled" ||
      !profile.styleKey3
    ) {
      return 0;
    }

    const hasValues = profile.d3 || profile.i3 || profile.s3 || profile.c3;

    // * [2] - Same domain
    if (profile.domainID && profile.domainID === sessionDomainID) {
      if (_.get(profile, "teamToolsPermit.hasBasicViewDisc", false)) {
        return hasValues ? 100 : 80;
      }

      return hasValues ? 50 : 40;
    }

    // * [3] - Different domain but has assessment
    if (_.get(profile, "teamToolsPermit.hasBasicViewDisc", false)) {
      return hasValues ? 90 : 70;
    }

    return 10;
  });

  const mappedProfiles = _.reduce(
    sorted,
    (accum, discMiniProfile) => {
      // * For incomplete DISC Profiles
      if (
        discMiniProfile.discStatus !== "ocENABLED" ||
        !discMiniProfile.styleKey3
      ) {
        return accum;
      }

      const mappedByEmails = mapMiniProfileByEmails(discMiniProfile);

      return {
        ...accum,
        ...mappedByEmails,
      };
    },
    {}
  );

  return mappedProfiles;
}

const DEFAULT_WIDTH = 225;

export function getSectionWidth(
  sectionContRef: React.MutableRefObject<null | HTMLElement>
) {
  if (sectionContRef.current) {
    const info = sectionContRef.current.getBoundingClientRect();
    return info.width;
  }

  return 0;
  //   return DEFAULT_WIDTH;
}

export function getIconTypeFromDISCType(discType: string): iconType {
  switch (discType.toLowerCase()) {
    case "advocate":
    case "peacemaker":
      return "dove";
    case "establisher":
    case "leader":
      return "knight";
    case "designer":
      return "pencil-ruler";
    case "logical thinker":
      return "brain";
    default:
      return "question";
  }
}

// * ============================================================
// * Methods for retrieving DISC data that respects permitMatrix
// * ============================================================

export interface RetrievedPersonValue {
  permitItem: Tyto.PermitMatrixItem;
  value?: any;
}

const DEFAULT_PERMIT: Tyto.PermitMatrixItem = {
  HIDE: true,
  reason: "WAITADMINREVEAL" as Tyto.PermitMatrixReason.WAITADMINREVEAL,
};

// * =======================
// * DISCMini specific logic
// * =======================
const personPermitKeyMap: {
  [x: string]: string;
  // [x: string]: keyof Tyto.PermitMatrix;
  // [x: number]: keyof Tyto.PermitMatrix;
} = {
  d1: "SCORES_",
  d2: "SCORES_",
  d3: "SCORES_",
  i1: "SCORES_",
  i2: "SCORES_",
  i3: "SCORES_",
  s1: "SCORES_",
  s2: "SCORES_",
  s3: "SCORES_",
  c1: "SCORES_",
  c2: "SCORES_",
  c3: "SCORES_",
  graphic: "discStyle.graphic",
  styleKey: "discStyle.styleKey",
  styleKey3: "discStyle.styleKey",
  styleName: "discStyle.styleName",
  styleName3: "discStyle.styleName",
  pdfLessonID: "Pdf",
};

const personKeyAliasFix: {
  [x: string]: string;
} = {
  Pdf: "pdfLessonID",
  styleKey: "styleKey3",
  styleName: "styleName3",
};

const personPermitKeyCircumventList: Set<string> = new Set([
  "descriptionGeneral",
  "emails",
  // "graphic",
  "jobTitle",
  "lastActivity",
  "permitMatrix",
  "personID",
  "personName",
  "phone1",
  "primaryElementID",
  "profileImageAsset",
  "profileImageID",
  // "styleKey3",
  // "styleName3",
  "teamRoot",
  "teamToolsInviteEmail",
  "teamToolsPermit",
]);

export function getPersonValueRespectfully({
  discMiniProfile,
  key,
}: {
  // key: keyof Tyto.DISCCompareProfile | keyof Tyto.DISCProfileMini;
  key: keyof Tyto.DISCProfileMini;
  discMiniProfile: Tyto.DISCProfileMini;
}): RetrievedPersonValue {
  // * If key is a part of list of items irrelevant to check permitMatrix for, return value
  if (personPermitKeyCircumventList.has(key)) {
    return {
      permitItem: DEFAULT_PERMIT,
      value: _.get(discMiniProfile, key, undefined),
    };
  }

  // * If not enough information to determine, simply return undefined
  if (!discMiniProfile || !key || !personPermitKeyMap[key]) {
    return {
      permitItem: DEFAULT_PERMIT,
      value: undefined,
    };
  }

  // * Snag key to check permitMatrix against
  const permitKey = personPermitKeyMap[key];

  const permit = _.get(
    discMiniProfile,
    `permitMatrix.${permitKey}`,
    DEFAULT_PERMIT
  ) as Tyto.PermitMatrixItem;

  const properKey = personKeyAliasFix[key] || key;

  // * Return value based of permission for specific item
  return {
    permitItem: permit,
    value: permit.HIDE
      ? undefined
      : _.get(discMiniProfile, properKey, undefined),
  };
}

// * ==============================
// * Compare Profile specific logic
// * ==============================
const comparePermitKeyMap: { [x: string]: string } = {
  communicationTips: "discCommTip_",
  intensity: "discStyleIntensity_",
  interactions: "discStyleInteraction_",
  personCommunicationTips: "discCommTip_",
  result: "discCommTip_",
  style: "discStyle", // ! Is not PermitItem, but contains PermitItems
  "result.d3percentile": "percentile_",
  "result.i3percentile": "percentile_",
  "result.s3percentile": "percentile_",
  "result.c3percentile": "percentile_",
  "style.styleKey": "",
  "style.styleName": "discStyle.styleName",
  "style.styleNamePKapi": "discStyle.styleNamePKapi",
  "style.descriptionGeneral": "discStyle.descriptionGeneral",
  "style.descriptionFull": "discStyle.descriptionFull",
  "style.characterGeneral": "discStyle.characterGeneral",
  "style.teamValues": "discStyle.teamValues",
  "style.challengeAreas": "discStyle.challengeAreas",
  "style.fear": "discStyle.fear",
  "style.motivators": "discStyle.motivators",
  "style.environmentIdeal": "discStyle.environmentIdeal",
  "style.want": "discStyle.want",
  "style.communicationDo": "discStyle.communicationDo",
  "style.communicationDont": "discStyle.communicationDont",
  "style.analyticDispostion": "discStyle.analyticDispostion",
  "style.teamContributions": "discStyle.teamContributions",
  "style.personalGrowthAreas": "discStyle.personalGrowthAreas",
  "style.graphic": "discStyle.graphic",
  "style.styleHeadline": "discStyle.styleHeadline",
  "style.styleTraits": "discStyle.styleTraits",
  "style.characteristic1": "discStyle.characteristic1",
  "style.characteristic1details": "discStyle.characteristic1details",
  "style.characteristic2": "discStyle.characteristic2",
  "style.characteristic2details": "discStyle.characteristic2details",
  "style.characteristic3": "discStyle.characteristic3",
  "style.characteristic3details": "discStyle.characteristic3details",
};

export function getCompareValueRespectfully({
  compareProfile,
  discMiniProfile,
  key,
}: {
  // key: keyof Tyto.DISCCompareProfile | keyof Tyto.DISCProfileMini;
  key: keyof Tyto.DISCCompareProfile;
  discMiniProfile: Tyto.DISCProfileMini;
  compareProfile?: Tyto.DISCCompareProfile;
}): RetrievedPersonValue {
  // debugger;
  // * If not enough information to determine, simply return undefined
  if (
    !discMiniProfile ||
    !compareProfile ||
    !key ||
    !comparePermitKeyMap[key]
  ) {
    console.log(
      `Compare Check returned early because not all info present. Has... discMini: ${discMiniProfile}, compare: ${compareProfile}, key: ${key}, permitItem: ${comparePermitKeyMap[key]}`
    );

    return {
      permitItem: DEFAULT_PERMIT,
      value: undefined,
    };
  }

  // * Snag key to check permitMatrix against
  const permitKey = comparePermitKeyMap[key];

  const permit = _.get(
    discMiniProfile,
    `permitMatrix.${permitKey}`,
    DEFAULT_PERMIT
  ) as Tyto.PermitMatrixItem;

  // * Return value based of permission for specific item
  return {
    permitItem: permit,
    value:
      permit.HIDE || permit.HIDE === undefined
        ? undefined
        : _.get(compareProfile, key, undefined),
  };
}
