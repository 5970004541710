/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import TytoCalls from "../../data/tyto/";
import { Tyto } from "../../typings/tyto";
import {
  StoreContext,
  RecipientsProps,
} from "../../data/stores/contexts/AppStore";
import { Message } from "../../components/common/";

import PersonCommTips from "./subcomponents/PersonCommTips";
// import PersonCompareGraph from "./subcomponents/PersonCompareGraph";
import PersonInteractions from "./subcomponents/PersonInteractions";
import PersonMotivators from "./subcomponents/PersonMotivators";
import PersonDISCGraph from "./subcomponents/PersonDISCGraph";
import PersonHeader from "./subcomponents/PersonHeader";
import PreviousNav from "./subcomponents/PreviousNav";
// import TeamTips from "../team/subcomponents/TeamTips";

import PersonHeaderPlaceholder from "./placeholders/Placeholder-PersonHeader";
import PersonToggleSectionPlaceholder from "./placeholders/Placeholder-ToggleSection";

import "./Person.scss";

interface Props {
  discData: Tyto.DISCInfo;
  discMini: Tyto.DISCProfileMini;
  discProfileNA: boolean;
  email: string;
  viewingTeam?: boolean;
}

export default ({
  discData,
  discMini,
  discProfileNA,
  email,
  viewingTeam,
}: Props) => {
  let store = React.useContext(StoreContext);

  const [loggedInProfile, updateLoggedInProfile] = React.useState(() => {
    if (store.state) {
      const { loggedInUserID, discMini, personIDEmailMap } = store.state;
      const key = personIDEmailMap[loggedInUserID];

      return discMini[key];
    }

    return undefined;
  });
  // const [compareProfile, updateCompareProfile] = React.useState<
  //   Tyto.DISCCompareProfile | undefined
  // >(() => {
  //   const personID = discMini ? discMini.personID : undefined;

  //   if (personID) {
  //     return getCompareProfile(store, personID);
  //   }

  //   return false;
  // });

  React.useEffect(() => {
    const personID = discMini ? discMini.personID : undefined;
    const hasCompareProfile = !!getCompareProfile(store, personID);

    if (!hasCompareProfile) {
      loadCompareProfile(store, discMini ? discMini.personID : undefined);
    }
  }, []);

  React.useEffect(() => {
    // if (store && store.state && !discProfileNA && !discData && discMini) {
    // TytoCalls.DISCProfiles.get({ personIDs: [discMini.personID] })
    //   .then((resp: any) => {
    //     if (store && store.dispatch) {
    //       store.dispatch({
    //         payload: {
    //           discProfiles: resp.discProfiles || []
    //         },
    //         type: "USERS_DISC_INFO_LOADED"
    //       });
    //     }
    //   })
    //   .catch((err: any) => {
    //     console.warn(
    //       "Error loading Full DISC Data in Person Interface top level component."
    //     );
    //   });
    // }
    console.log("Triggered DISCMini Change: ", discMini);
    if (discMini && discMini.personID) {
      // const newCompareProfile = getCompareProfile(store, discMini.personID);
      const hasCompareProfile = !!getCompareProfile(store, discMini.personID);

      if (!hasCompareProfile) {
        loadCompareProfile(store, discMini.personID);
      }
      // else {
      // updateCompareProfile(newCompareProfile);
      // }
    }

    if (store.state) {
      const { loggedInUserID, discMini, personIDEmailMap } = store.state;
      const key = personIDEmailMap[loggedInUserID];

      updateLoggedInProfile(discMini[key]);
    }
  }, [discMini]);

  // React.useEffect(() => {
  //   const curCompareProfile = getCompareProfile(
  //     store,
  //     discMini ? discMini.personID : 0
  //   );

  //   updateCompareProfile(curCompareProfile);
  // }, [store]);

  if (discProfileNA) {
    return (
      <section className="person-main-cont">
        {store && store.state && store.state.focusedPerson && (
          <PreviousNav
            buttonText="Back"
            onClick={() => clearFocusedPerson(store)}
          />
        )}
        <Message
          value={`No data available for ${email}`}
          style={{ fontWeight: "bold" }}
        />
      </section>
    );
  }

  if (!discMini) {
    return (
      <section className="person-main-cont">
        {store && store.state && store.state.focusedPerson && (
          <PreviousNav
            buttonText="Back"
            onClick={() => clearFocusedPerson(store)}
          />
        )}
        <PersonHeaderPlaceholder />

        <PersonToggleSectionPlaceholder />
        <PersonToggleSectionPlaceholder />
        <PersonToggleSectionPlaceholder />
        {/* <PersonInfo discData={discData} discMini={discMini} /> */}
      </section>
    );
  }

  const sessionKey: string | undefined = _.get(
    store,
    "state.sessionData.sessionKey"
  );
  const discCompareProfile = getCompareProfile(
    store,
    discMini ? discMini.personID : 0
  );
  const personName = _.get(discMini, "personName", "");
  const personFirstName = personName.split(" ")[0];

  return (
    <section className="person-main-cont">
      {store && store.state && store.state.focusedPerson && (
        <PreviousNav
          buttonText="Back"
          onClick={() => clearFocusedPerson(store)}
        />
      )}
      <PersonHeader
        discCompareProfile={discCompareProfile}
        discData={discData}
        discMini={discMini}
        sessionKey={sessionKey}
        viewingTeam={viewingTeam}
        loggedInUserDiscMini={_.get(store, "state.personalDiscMini", undefined)}
      />

      <PersonCommTips
        // discCompareProfile={compareProfile}
        discCompareProfile={discCompareProfile}
        discData={discData}
        discMini={discMini}
        personName={personName}
      />

      <PersonDISCGraph
        // discCompareProfile={compareProfile}
        discCompareProfile={discCompareProfile}
        discData={discData}
        discMini={discMini}
        personName={personFirstName}
      />

      <PersonInteractions
        // discCompareProfile={compareProfile}
        discCompareProfile={discCompareProfile}
        discMiniProfile={discMini}
        personalDISCMini={loggedInProfile}
        personName={personFirstName}
      />

      <PersonMotivators
        // discCompareProfile={compareProfile}
        discCompareProfile={discCompareProfile}
        discMiniProfile={discMini}
        personName={personFirstName}
      />

      {/* <PersonCompareGraph
        discData={discData}
        discMini={discMini}
        personalDiscMini={loggedInProfile}
      /> */}
    </section>
  );
};

const clearFocusedPerson = (store: RecipientsProps) => {
  if (store.dispatch) {
    store.dispatch({
      payload: {
        email: undefined,
      },
      type: "SET_FOCUSED_PROFILE",
    });
  }
};

const getCompareProfile = (AppStore: RecipientsProps, personID?: number) => {
  if (!personID) {
    return undefined;
  }

  return _.get(AppStore, `state.discCompareProfiles.${personID}`);
};

const loadCompareProfile = async (
  AppStore: RecipientsProps,
  personID?: number
) => {
  if (
    AppStore.state &&
    personID &&
    typeof AppStore.state.discCompareProfiles[personID] === "undefined"
  ) {
    try {
      const discCompareProfile: any = await TytoCalls.DISCProfile.Interactive.get(
        {
          personID,
        }
      );
      if (AppStore.dispatch) {
        AppStore.dispatch({
          payload: {
            discCompareProfile: discCompareProfile
              ? discCompareProfile.discProfile
              : undefined,
          },
          type: "DISC_COMPARE_PROFILE_LOADED",
        });
      }

      return true;
    } catch (err) {
      return false;
    }
  }
};
