/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../typings/tyto";
import { TOGGLE_SECTION_BLOCKS } from "../../../data/constants/";
import {
  DISCPlotGraph,
  DISCValueRender,
  Message,
  ToggleSection,
} from "../../../components/common/";
import { getSectionWidth } from "../../../data/utils/helpers/";

import "./PersonInteractions.scss";

interface Interactions {
  insight?: Tyto.DISCCompareProfile.Interaction;
  presented?: Tyto.DISCCompareProfile.Interaction;
  tips?: Tyto.DISCCompareProfile.Interaction;
}

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile: Tyto.DISCProfileMini;
  personalDISCMini?: Tyto.DISCProfileMini;
  personName: string;
}

export default (props: Props) => {
  const [interactions, updateInteractions] = React.useState<Interactions>(
    getInteractions(props.discCompareProfile)
  );

  React.useEffect(() => {
    updateInteractions(getInteractions(props.discCompareProfile));
  }, [props.discCompareProfile]);

  // const theirName =
  //   props.discMiniProfile && props.discMiniProfile.personName
  //     ? props.discMiniProfile.personName.split(" ")[0]
  //     : "They";

  return (
    <ToggleSection
      className="person-interactions-toggle-cont"
      closedAtStart={false}
      mixpanelKey={TOGGLE_SECTION_BLOCKS.Person.INTERACTIONS}
      title={`You & ${props.personName}`}
      renderFunction={(isExpanded, sectionWrapperRef) => {
        const [sectionWrapperWidth, updateSectionWrapperWidth] = React.useState(
          getSectionWidth(sectionWrapperRef)
        );
        React.useEffect(() => {
          updateSectionWrapperWidth(getSectionWidth(sectionWrapperRef));
        }, [sectionWrapperRef]);

        if (!isExpanded) {
          return <span style={{ visibility: "hidden" }} />;
        }

        return (
          <DISCValueRender
            className="meta-sidepane-person-renderer-wrapper"
            isCompareValue={false}
            targetKey="d3"
            targetKeys={[
              "interactions",
              "d3"
            ]}
            discMiniProfile={props.discMiniProfile}
          >
            <div className="person-interactions-compare-graph-cont">
              <DISCValueRender
                className="meta-sidepane-person-renderer-wrapper"
                isCompareValue={false}
                targetKey="d3"
                discMiniProfile={props.discMiniProfile}
              >
                {props.personalDISCMini && (
                  <DISCPlotGraph
                    groupStyle={[
                      {
                        d: props.discMiniProfile.d3,
                        i: props.discMiniProfile.i3,
                        s: props.discMiniProfile.s3,
                        c: props.discMiniProfile.c3,
                      },
                      {
                        d: props.personalDISCMini.d3,
                        i: props.personalDISCMini.i3,
                        s: props.personalDISCMini.s3,
                        c: props.personalDISCMini.c3,
                      },
                    ]}
                    size={sectionWrapperWidth}
                  />
                )}
              </DISCValueRender>
            </div>

            <DISCValueRender
                className="meta-sidepane-person-renderer-wrapper"
                compareProfile={props.discCompareProfile}
                isCompareValue={true}
                targetKey="interactions"
                discMiniProfile={props.discMiniProfile}
              >
                <h3 className="person-interactions-subtitle">
                  How you see {props.personName}
                </h3>
                <p className="person-interactions-tip">
                  <DISCValueRender
                    className="meta-sidepane-person-renderer-wrapper"
                    compareProfile={props.discCompareProfile}
                    isCompareValue={true}
                    targetKey="interactions"
                    discMiniProfile={props.discMiniProfile}
                  >
                    {interactions.insight ? (
                      interactions.insight.interactText
                    ) : (
                      <Message value="No insight found. :(" />
                    )}
                  </DISCValueRender>
                </p>

                <h3 className="person-interactions-subtitle">
                  How {props.personName} sees you
                </h3>
                <p className="person-interactions-tip">
                  <DISCValueRender
                    className="meta-sidepane-person-renderer-wrapper"
                    compareProfile={props.discCompareProfile}
                    isCompareValue={true}
                    targetKey="interactions"
                    discMiniProfile={props.discMiniProfile}
                  >
                    {interactions.presented ? (
                      interactions.presented.interactText
                    ) : (
                      <Message value="No insight found. :(" />
                    )}
                  </DISCValueRender>
                </p>

                <h3 className="person-interactions-subtitle">
                  Tips for working together
                </h3>
                <p className="person-interactions-tip">
                  <DISCValueRender
                    className="meta-sidepane-person-renderer-wrapper"
                    compareProfile={props.discCompareProfile}
                    isCompareValue={true}
                    targetKey="interactions"
                    discMiniProfile={props.discMiniProfile}
                  >
                    {interactions.tips ? (
                      interactions.tips.interactText
                    ) : (
                      <Message value="No insight found. :(" />
                    )}
                  </DISCValueRender>
                </p>
              </DISCValueRender>
          </DISCValueRender>
        );
      }}
    />
  );
};

const getInteractions = (
  discCompareProfile?: Tyto.DISCCompareProfile
): Interactions => {
  if (!discCompareProfile || !discCompareProfile.interactions) {
    return {};
  }

  const interactions = _.keyBy(discCompareProfile.interactions, (interaction) =>
    interaction.interactContext.toLowerCase()
  );

  return interactions as {
    insight: Tyto.DISCCompareProfile.Interaction;
    presented: Tyto.DISCCompareProfile.Interaction;
    tips: Tyto.DISCCompareProfile.Interaction;
  };
};
