/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../typings/tyto";
import RecentlyRetrievedPerson, { isInvalid } from "./RecentlyRetrievedPerson";
import { Message } from "../../../components/common/";

import "./RecentlyRetrieved.scss";

interface Props {
  recentlyRetrieved: Tyto.DISCProfileMini[];
}

export default ({ recentlyRetrieved }: Props) => {
  const recents = Array.isArray(recentlyRetrieved)
    ? _.uniqBy(recentlyRetrieved, "personID")
    : undefined;
  const recentsFiltered = Array.isArray(recents)
    ? recents.filter((recent) => !isInvalid(recent))
    : undefined;

  return (
    <section className="recently-retrieved-main-cont">
      <h1 className="recently-retrieved-title">Recent Contacts</h1>

      <ul className="recently-retrieved-people-list">
        {Array.isArray(recentsFiltered) && !!recentsFiltered.length ? (
          <>
            {recentsFiltered.map((discMiniProfile) => (
              <RecentlyRetrievedPerson
                key={discMiniProfile.personID}
                discMini={discMiniProfile}
              />
            ))}
          </>
        ) : (
          <Message value="No Recent Contacts found" />
        )}
      </ul>
    </section>
  );
};
