/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { awaitOfficeLoad, retrieveStoredSession } from "../data/utils";

import {
  StoreContext,
  RecipientsProps,
} from "../data/stores/contexts/AppStore";
import {
  clearRecentlyRetrieved,
  getInterfaceContextParam,
  getStoredPersonalDISCMini,
  getViewAsDarkMode,
  logout,
} from "../data/utils/";
import AppointmentCompose from "./appointment-compose/";
import AppointmentRead from "./appointment-read/";
import NewMessage from "./new-message/";
import InboxMessage from "./inbox-message/";
import SettingsScreen from "./settings/";
import WelcomeScreen from "./welcome-screen/";
import Logon from "./logon/";
import NoPermission from "./no-permission/";
import { Loading } from "../components/common/";
import { InterfaceContext } from "../typings";
import TytoCalls from "../data/tyto/";
import { Icon, Message } from "../components/common/";

import "./style.scss";
import person from "./person";

interface Props {}

export default (props: Props) => {
  let store = React.useContext(StoreContext);
  const [cannotLoadOwnProfile, updateCannotLoadOwnProfile] = React.useState(
    false
  );
  const [msOfficeError, updateMSOfficeError] = React.useState(false);
  const [versionTooLow, updateVersionTooLow] = React.useState(false);
  const [activeVersion, updateActiveVersion] = React.useState("1.1");

  React.useEffect(() => {
    onOfficeLoad({
      AppStore: store,
      updateCannotLoadOwnProfile,
      updateMSOfficeError,
      updateVersionTooLow,
      setActiveVersion: () => {
        const version = attemptToDetermineAPIVersion();
        updateActiveVersion(version);

        if (store.dispatch) {
          store.dispatch({
            payload: {
              version,
            },
            type: "SET_OFFICE_VERSION_NUMBER",
          });
        }
      },
    });
  }, [_.get(store, "state.sessionData")]);

  React.useEffect(() => {
    const interfaceContext = getInterfaceContextParam();

    if (store && store.state) {
      if (store.state.INTERFACE_CONTEXT !== interfaceContext) {
        debugger;
      }
    }
  }, [window.location]);

  const isDarkMode = _.get(store, "state.IS_DARK_MODE", false);

  if (msOfficeError || versionTooLow) {
    return (
      <div className={cx("main has-padding main-logo-cont", isDarkMode && "dark-mode")}>
        <div className="toplevel-nomsoffice-error-cont">
          <h3 className="toplevel-nomsoffice-error-title">Uh Oh!</h3>

          <p className="toplevel-nomsoffice-error-text">
            {versionTooLow
              ? `This Add-In requires at least version 1.3 of the Office API. It looks like you are using an earlier version (${activeVersion}). If you would look to use this Add In you will need to update or upgrade your Outlook client.`
              : "There seems to be an issue. Outlook could not be detected. This is likely an issue with the version of Outlook you are using and/or Operating System you are on."}
          </p>
        </div>
      </div>
    );
  }

  if (
    !store.state ||
    !store.state.officeIsReady ||
    !store.state.hasCheckedForStoredSession
  ) {
    return (
      <div className={cx("main has-padding main-logo-cont", isDarkMode && "dark-mode")}>
        <Loading size={100} type="squares" />
        {/* <Crane className="main-logo" /> */}
      </div>
    );
  }

  if (!store.state || !store.state.sessionData) {
    // return (
    //   <div className="main">
    //     <WelcomeScreen />
    //   </div>
    // );

    return (
      <div className={cx("main", isDarkMode && "dark-mode")}>
        <Logon />
      </div>
    );
  }

  if (store.state.showSettings) {
    return <SettingsScreen />;
  }

  const loggedInUserDISCMini = _.get(store, "state.personalDiscMini", false);
  const noPermission =
    !!loggedInUserDISCMini &&
    !_.get(store, "state.loggedInUserPermit.hasBasicViewDisc", false);

  if (!loggedInUserDISCMini) {
    return (
      <div className={cx("main has-padding main-logo-cont", isDarkMode && "dark-mode")}>
        <p className="active-version-string">
          {navigator.userAgent}
          <br />
          <br />
          Office API Version: {activeVersion}
        </p>
        <Loading size={100} className="main-logo" type="squares" />
        {/* <Crane className="main-logo" /> */}
        <p className="main-logo-description">Retriving Account Information</p>
      </div>
    );
  }

  return (
    <div className={cx("main has-padding", isDarkMode && "dark-mode")} style={{ position: "relative" }}>
      <Icon
        className="main-settings-open-button"
        isButton={true}
        buttonProps={{
          className: "main-settings-open-button-cont",
        }}
        icon="cog"
        onClick={() => {
          if (store.dispatch) {
            store.dispatch({
              payload: {},
              type: "TOGGLE_SHOW_SETTINGS",
            });
          }
        }}
        size={20}
      />

      {cannotLoadOwnProfile ? (
        <div>
          <p className="active-version-string">{activeVersion}</p>
          <Message value="Could Not Load your DISC Profile" />
        </div>
      ) : noPermission ? (
        <NoPermission />
      ) : (
        render(_.get(store, "state.INTERFACE_CONTEXT"))
      )}
    </div>
  );
};

const render = (interfaceContext: InterfaceContext) => {
  switch (interfaceContext) {
    case "APPTCOMPOSE":
      return <AppointmentCompose />;
    // return <h2>Appointment Compose Coming Soon...</h2>;
    case "APPTREAD":
      return <AppointmentRead />;
    case "MSGREAD":
      return <InboxMessage />;
    case "MSGCOMPOSE":
      return <NewMessage />;
    default:
      return (
        <div style={{ minWidth: "200px", minHeight: "200px" }}>
          <h1 style={{ color: "#000", fontSize: "24px" }}>
            Proper context not supplied
          </h1>
        </div>
      );
  }
};

const onOfficeLoad = async ({
  AppStore,
  updateCannotLoadOwnProfile,
  updateMSOfficeError,
  updateVersionTooLow,
  setActiveVersion,
}: {
  AppStore: RecipientsProps;
  updateCannotLoadOwnProfile: (newVal: boolean) => void;
  updateMSOfficeError: (newVal: boolean) => void;
  updateVersionTooLow: (versionTooLow: boolean) => void;
  setActiveVersion: () => void;
}) => {
  awaitOfficeLoad(
    async () => {
      if (AppStore.dispatch) {
        AppStore.dispatch({ type: "OFFICE_LOADED", payload: {} });
        if (!Office.context.requirements.isSetSupported("Mailbox", "1.3")) {
          setActiveVersion();
          updateVersionTooLow(true);
        } else {
          const isDarkMode = getViewAsDarkMode();

          if (isDarkMode !== undefined) {
            AppStore.dispatch({
              payload: {
                isDarkMode
              },
              type: "DARK_MODE_PREF_LOADED"
            });
          }

          const sessionData = await retrieveStoredSession();

          if (sessionData && sessionData.session) {
            setActiveVersion();
            AppStore.dispatch({
              type: "USER_LOGGED_IN",
              payload: sessionData.session,
            });

            try {
              const storedDISCMini = getStoredPersonalDISCMini();
  
              if (storedDISCMini && storedDISCMini.personID === sessionData.session.userID) {
                AppStore.dispatch({
                  payload: {
                    discMini: storedDISCMini,
                    omitSettingsCall: true,
                  },
                  type: "USER_DISC_MINI_LOADED",
                });

                AppStore.dispatch({
                  payload: {
                    emailsUsed: [],
                    discMini: [storedDISCMini],
                  },
                  type: "DISC_MINI_PROFILES_LOADED",
                });
              }

              const personalInfo = await TytoCalls.DISCProfilesMini.get({
                personIDs: `${_.get(sessionData.session, "userID", 0)}`,
              })
  
              if (personalInfo.discProfiles && personalInfo.discProfiles.length) {
                if (!AppStore.dispatch) {
                  return;
                }
    
                AppStore.dispatch({
                  payload: {
                    discMini: _.get(personalInfo, "discProfiles[0]", undefined),
                  },
                  type: "USER_DISC_MINI_LOADED",
                });
    
                AppStore.dispatch({
                  payload: {
                    emailsUsed: [],
                    discMini: _.get(personalInfo, "discProfiles", []),
                  },
                  type: "DISC_MINI_PROFILES_LOADED",
                });
              }
            } catch (err) {
              updateCannotLoadOwnProfile(true);
              console.warn(
                "Error occurred while loading own DISCMini: ",
                err
              );
            }
          } else {
            AppStore.dispatch({
              type: "STORED_SESSION_CHECKED",
              payload: {
                failedSessionReason: _.get(
                  sessionData,
                  "failedReason",
                  undefined
                ),
                hasCheckedForStoredSession: true,
              },
            });
          }
        }
      }
    },
    () => {
      updateMSOfficeError(true);
    }
  );
};

const attemptToDetermineAPIVersion = () => {
  if (!Office) {
    return "1.1";
  }

  const versionString = [
    "1.2",
    "1.3",
    "1.4",
    "1.5",
    "1.6",
    "1.7",
    "1.8",
    "1.9",
  ].reduce((accum, curItem) => {
    if (Office.context.requirements.isSetSupported("Mailbox", curItem)) {
      return curItem;
    } else {
      return accum;
    }
  }, "1.1");

  return versionString;
};
