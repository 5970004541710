import { ENDPOINT_LOGIN_URL } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_LOGIN_URL;

export const LoginAuthenticate = {
  post(
    params: Endpoints.Tyto.LoginAuthenticate.GetParameters, callOpts?: CallOpts
  ): Promise<{ error?: any; session: SessionData }> {
    return callWrapper("post", endpoint, params || {}, { ...(callOpts || {}), omitSessionKey: true }) as Promise<{
      error?: any;
      session: SessionData;
    }>;
  }
};
