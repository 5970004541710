import React from "react";
import * as _ from "lodash";
import { ErrorBoundary } from "react-error-boundary";
import mixpanel from "mixpanel-browser";

import { StoreContextProvider } from "./data/stores/contexts/AppStore";

import Wrapper from "./interfaces/";

import "./styles/Main.scss";
import "./Style.scss";

mixpanel.init("fc74e144837331e015436972122a0d66");

const App: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={PrintError}>
      <StoreContextProvider>
        <Wrapper />
      </StoreContextProvider>
    </ErrorBoundary>
  );
};

const PrintError = ({ error, componentStack, resetErrorBoundary }: any) => {
  // const error = typeof err === "string" ? err : JSON.stringify(err);

  return (
    <div
      style={{ maxWidth: "100vw", padding: "10px", boxSizing: "border-box" }}
    >
      <h3
        style={{
          fontWeight: "bold",
          fontSize: "28px",
          margin: "0px",
          padding: "20px 0px",
          boxSizing: "border-box",
          color: "#EC7063",
        }}
      >
        Error
      </h3>
      <p
        style={{
          maxWidth: "100%",
          color: "#223958",
          margin: "0px",
          padding: "0px 0px 10px",
          boxSizing: "border-box",
        }}
      >
        {error.message}
      </p>
      <pre style={{ maxWidth: "100%", color: "#435369" }}>
        Stack Trace:
        <br />
        <br />
        {componentStack}
      </pre>

      <p
        style={{
          fontSize: "12px",
          margin: "0px",
          padding: "40px 0px 10px",
          boxSizing: "border-box",
          color: "#CCD1D1",
        }}
      >
        <b>User Agent:</b>
        <br />
        <br />
        {_.get(navigator, "userAgent", "")}
      </p>
    </div>
  );
};

export default App;
