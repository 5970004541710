/*
 * Component Description
 */
import * as React from "react";

import { StoreContext } from "../../../data/stores/contexts/AppStore";
import { Icon, UserThumb } from "../../../components/common/";
import { Tyto } from "../../../typings/tyto";

interface Props {
  discMini: Tyto.DISCProfileMini;
}

export default ({ discMini }: Props) => {
  let store = React.useContext(StoreContext);

  const emailInvalid = isInvalid(discMini);

  if (emailInvalid) {
    return <span style={{ visibility: "hidden" }} />;
  }

  return (
    <li className="recently-retrieved-person-cont">
      <button
        className="recently-retrieved-person-cont-button-wrapper"
        onClick={() => {
          if (store && store.dispatch) {
            store.dispatch({
              payload: {
                email: discMini.emails[0].toLowerCase(),
              },
              type: "SET_FOCUSED_PROFILE",
            });
          }
        }}
      >
        <UserThumb
          asset={discMini.profileImageAsset}
          className="recently-retrieved-person-userthumb"
          discMiniProfile={discMini}
          discValues={{
            d: discMini.d3,
            i: discMini.i3,
            s: discMini.s3,
            c: discMini.c3,
          }}
          // onClick={() => {
          //   if (store && store.dispatch) {
          //     store.dispatch({
          //       payload: {
          //         email: discMini.emails[0].toLowerCase()
          //       },
          //       type: "SET_FOCUSED_PROFILE"
          //     });
          //   }
          // }}
          size={43}
          userName={discMini.personName}
        />

        <div className="recently-retrieved-person-info-cont">
          <p className="recently-retrieved-person-info-name">
            {discMini.personName}
          </p>
          <p className="recently-retrieved-person-info-jobtitle">
            {discMini.jobTitle}
          </p>

          <div className="recently-retrieved-person-info-btn">
            <Icon
              // buttonProps={{
              //   className: "recently-retrieved-person-info-btn"
              // }}
              size={30}
              icon="angle-right"
              // isButton={true}
              // onClick={() => {
              //   if (store && store.dispatch) {
              //     store.dispatch({
              //       payload: {
              //         email: discMini.emails[0].toLowerCase()
              //       },
              //       type: "SET_FOCUSED_PROFILE"
              //     });
              //   }
              // }}
            />
          </div>
        </div>
      </button>
    </li>
  );
};

export const isInvalid = (discMini: Tyto.DISCProfileMini) => {
  return (
    !discMini ||
    !Array.isArray(discMini.emails) ||
    typeof discMini.emails[0] !== "string" ||
    !discMini.emails[0].toLowerCase()
  );
};
