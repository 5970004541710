import * as _ from "lodash";
import { Tyto } from "../../../typings/tyto";
import { calcStandardDeviation } from "../../../data/utils/helpers/";

const defaultDISCValue = {
  d: 0,
  i: 0,
  s: 0,
  c: 0,
};

export function calcGroupStyle(discMiniProfile: Tyto.DISCProfileMini[]) {
  // const discValuesAsObj = calcGroupStyleAsObj(discMiniProfile);
  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  const discValuesAsObj = calcSqrPositiveGroupStyleAsObj(discMinisFiltered);

  return _.orderBy(
    [
      { label: "d", value: discValuesAsObj.d },
      { label: "i", value: discValuesAsObj.i },
      { label: "s", value: discValuesAsObj.s },
      { label: "c", value: discValuesAsObj.c },
    ],
    ["value"],
    ["desc"]
  );
}

export function calcGroupStyleAsObj(discMiniProfile: Tyto.DISCProfileMini[]) {
  if (!Array.isArray(discMiniProfile)) {
    return defaultDISCValue;
  }

  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  if (!discMinisFiltered.length) {
    return defaultDISCValue;
  }

  const letterSums = discMinisFiltered.reduce(
    (accum: { d: number; i: number; s: number; c: number }, profile) => ({
      d: accum.d + profile.d3,
      i: accum.i + profile.i3,
      s: accum.s + profile.s3,
      c: accum.c + profile.c3,
    }),
    defaultDISCValue
  );

  return {
    d: letterSums.d / discMinisFiltered.length,
    i: letterSums.i / discMinisFiltered.length,
    s: letterSums.s / discMinisFiltered.length,
    c: letterSums.c / discMinisFiltered.length,
  };
}

export function calcSqrPositiveGroupStyleAsObj(
  discMiniProfile: Tyto.DISCProfileMini[]
) {
  if (!Array.isArray(discMiniProfile)) {
    return defaultDISCValue;
  }

  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  if (!discMinisFiltered.length) {
    return defaultDISCValue;
  }

  const letterSums = discMinisFiltered.reduce(
    (accum: { d: number; i: number; s: number; c: number }, profile) => ({
      d: accum.d + (profile.d3 > 0 ? Math.pow(profile.d3, 2) : 0),
      i: accum.i + (profile.i3 > 0 ? Math.pow(profile.i3, 2) : 0),
      s: accum.s + (profile.s3 > 0 ? Math.pow(profile.s3, 2) : 0),
      c: accum.c + (profile.c3 > 0 ? Math.pow(profile.c3, 2) : 0),
    }),
    defaultDISCValue
  );

  return {
    d: letterSums.d / discMinisFiltered.length,
    i: letterSums.i / discMinisFiltered.length,
    s: letterSums.s / discMinisFiltered.length,
    c: letterSums.c / discMinisFiltered.length,
  };
}

// * Returns values that will be used for surfacing insight about team
export function getGroupStyleWithStdDeviation(
  disc: { label: string; value: number }[]
) {
  if (!Array.isArray(disc) || !disc.length) {
    return "";
  }

  const values = disc.map((info) => info.value);
  const stdDeviation = calcStandardDeviation(values);

  const highestValue = _.orderBy(disc, ["value"], ["desc"])[0].value;
  const lowestAcceptedValue = highestValue - stdDeviation;

  return disc
    .filter((info) => info.value >= lowestAcceptedValue)
    .map((info) => info.label)
    .join("");
}
