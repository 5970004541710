/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { webAppFullProfileUrl } from "../../../data/utils/webapp/";
import { triggerMixPanelEvent } from "../../../data/logging/";
import {
  DISCValueRender,
  Icon,
  UserThumb,
  Message,
} from "../../../components/common/index";
import { Tyto } from "../../../typings/tyto";
import {
  calcGradientForIcon,
  getIconTypeFromDISCType,
  isValidIcon,
} from "../../../data/utils/helpers/";

import PersonNavCheck from "./PersonNavCheck";

import "./PersonHeader.scss";

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discData: Tyto.DISCInfo;
  discMini: Tyto.DISCProfileMini;
  loggedInUserDiscMini: Tyto.DISCProfileMini;
  sessionKey?: string;
  viewingTeam?: boolean;
}

export default ({
  discCompareProfile,
  discData,
  discMini,
  loggedInUserDiscMini,
  sessionKey,
  viewingTeam,
}: Props) => {
  const [discIcon, updateDiscIcon] = React.useState(() => {
    const icon = isValidIcon(discMini.graphic || "");

    console.log("Icon evaluated to: ", icon, " width mini info: ", discMini);
    return icon;
  });
  const [iconGradient, updateIconGradient] = React.useState(
    calcGradientForIcon({
      d: discMini.d3,
      i: discMini.i3,
      s: discMini.s3,
      c: discMini.c3,
    })
  );
  const [showConfirm, updateShowConfirm] = React.useState(false);

  React.useEffect(() => {
    isValidIcon(discMini.graphic || "");
    updateIconGradient(
      calcGradientForIcon({
        d: discMini.d3,
        i: discMini.i3,
        s: discMini.s3,
        c: discMini.c3,
      })
    );
  }, [discData]);

  const usersDomainName = _.get(discMini, "domainName", "");
  // const usersDomainID = _.get(discMini, "domainID", 0);

  return (
    <section className="person-header-cont">
      <div className="person-header-cont-images-cont">
        <UserThumb
          className="person-header-userthumb"
          hasGradient={true}
          key={discMini.personID}
          discMiniProfile={discMini}
          discValues={{
            d: discMini.d3,
            i: discMini.i3,
            s: discMini.s3,
            c: discMini.c3,
          }}
          asset={discMini.profileImageAsset}
          photoID={discMini.profileImageID}
          size={125}
          userName={discMini.personName}
          style={{
            zIndex: 1,
          }}
        />

        <div>
          <DISCValueRender
            // className="meta-sidepane-person-renderer-wrapper"
            iconSize={44}
            isCompareValue={true}
            compareProfile={discCompareProfile}
            omitHiddenMsg={true}
            targetKey="style.graphic"
            discMiniProfile={discMini}
          >
            <Icon
              className="person-header-disc-profile-icon"
              icon={discIcon}
              gradient={iconGradient}
              size={65}
            />
          </DISCValueRender>

          <p className="person-header-disc-profile-title">
            <DISCValueRender
              // className="meta-sidepane-person-renderer-wrapper"
              isCompareValue={true}
              compareProfile={discCompareProfile}
              omitHiddenMsg={true}
              targetKey="style.styleName"
              discMiniProfile={discMini}
            >
              {discMini.styleName3}
            </DISCValueRender>
          </p>
        </div>
      </div>

      <h1 className="person-header-name">{discMini.personName}</h1>

      {discMini.jobTitle && (
        <p className="person-header-jobtitle">{discMini.jobTitle}</p>
      )}

      <p className="person-header-jobtitle-contact-info">
        <Icon
          buttonProps={{
            className: "person-header-jobtitle-contact-icon-cont",
          }}
          icon="envelope"
          isButton={true}
          onClick={() => {
            updateShowConfirm(true);
          }}
          size={13}
        />
        {discMini.emails[0]}
      </p>
      {discMini.phone1 && (
        <p className="person-header-jobtitle-contact-info">
          <Icon
            buttonProps={{
              className: "person-header-jobtitle-contact-icon-cont",
            }}
            icon="phone"
            isButton={true}
            onClick={() => {
              // TODO
            }}
            size={13}
          />
          {discMini.phone1}
        </p>
      )}

      <p className="person-header-external-link-wrapper">
        {discMini && discMini.personID && (
          <a
            className="person-header-external-link"
            href={webAppFullProfileUrl({
              personID: discMini.personID,
              sessionKey,
            })}
            onClick={() =>
              _logFullProfileEventToMixPanel({
                isViewingTeam: !!viewingTeam,
                discMini,
                loggedInUserDiscMini,
              })
            }
            target="_blank"
          >
            View Full Profile
          </a>
        )}

        {discMini && discMini.personID && usersDomainName && (
          <span className="person-header-link-domain-name-divider">|</span>
        )}

        {usersDomainName && (
          <span className="person-header-link-domain-name">
            {usersDomainName}
          </span>
        )}
      </p>

      {showConfirm && (
        <PersonNavCheck
          // color={iconGradient ? iconGradient[0].color : "#fff"}
          color="#fff"
          discMini={discMini}
          onConfirm={() => {
            updateShowConfirm(false);
            _logOpenNewEmailEventToMixPanel(!!viewingTeam, discMini);
          }}
          onCancel={() => updateShowConfirm(false)}
          cancelText="Cancel"
          confirmText="Open New Email"
          help={{
            title: "How do I set Outlook as my default Mail Client?",
            childrenRenderFnc: () => (
              <span>
                You can find out how to set Outlook as your default mail client
                in the{" "}
                <a
                  href="https://support.office.com/en-us/article/make-outlook-the-default-program-for-email-contacts-and-calendar-ff7990c4-54c4-4390-8fe3-c0285226f021"
                  target="_blank"
                >
                  Microsoft Office Support Documentation
                </a>
              </span>
            ),
          }}
          href={`mailto:${discMini.emails[0]}`}
          message={`Open new Email addressed to ${discMini.personName}?`}
          subMessage="If Outlook is not set as the default email client it will open a new email in a different email software."
          type="link"
        />
      )}
    </section>
  );
};

const _logOpenNewEmailEventToMixPanel = (
  isViewingTeam: boolean,
  discMini: Tyto.DISCProfileMini
) => {
  if (!discMini) {
    return;
  }

  triggerMixPanelEvent("PERSON_LAUNCH_NEW_EMAIL", {
    // userID: discMini.personID,
    // userName: discMini.personName,
    // email: discMini.emails[0],
    isViewingTeam,
  });
};

const _logFullProfileEventToMixPanel = ({
  isViewingTeam,
  discMini,
  loggedInUserDiscMini,
}: {
  isViewingTeam: boolean;
  discMini: Tyto.DISCProfileMini;
  loggedInUserDiscMini: Tyto.DISCProfileMini;
}) => {
  if (!discMini) {
    return;
  }

  triggerMixPanelEvent("PERSON_LAUNCH_WEBAPP", {
    // userID: discMini.personID,
    // userName: discMini.personName,
    userStyleKey: _.get(loggedInUserDiscMini, "styleKey3", null),
    userStyleName: _.get(loggedInUserDiscMini, "styleName3", null),
    targetPersonStyleKey: discMini.styleKey3,
    targetPersonStyleName: discMini.styleName3,
    isViewingTeam,
  });
};
