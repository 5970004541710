import { ENDPOINT_LOGIN4_URL } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { AuthResult } from "../../typings";

const endpoint = ENDPOINT_LOGIN4_URL;

const timeoutMinutes = 60 * 24 * 90;

export const LoginAuthenticate4 = {
  post(
    params: Endpoints.Tyto.LoginAuthenticate.GetParameters,
    callOpts?: CallOpts
  ): Promise<{ error?: any; authResults: AuthResult[]; }> {
    return callWrapper(
      "post",
      endpoint,
      { ...(params || {}), timeoutMinutes },
      {
        ...(callOpts || {}),
        omitSessionKey: true,
      }
    ) as Promise<{
      error?: any;
      authResults: AuthResult[];
    }>;
  },
};
