/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon from "../icon/";
import { iconType } from "../icon/typings/";

import "./style.scss";

interface Props {
  autofocus?: boolean;
  containerClassName?: string;
  className?: string;
  disabled?: boolean;
  iconLeft?: iconType;
  hideClear?: boolean;
  iconProps?: Object;
  // inputRef?:
  name?: string;
  onChange: (value: string) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  type?: string;
  value: string;
}

export default (props: Props) => {
  return (
    <div
      className={cx("cc-input-cont", props.containerClassName)}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.iconLeft && (
        <div className="cc-input-icon-cont">
          <Icon
            buttonProps={{ name: `Icon for ${props.name}` }}
            className="cc-input-icon"
            {...(props.iconProps || {})}
            icon={props.iconLeft}
            size={14}
          />
        </div>
      )}

      <input
        autoFocus={props.autofocus}
        className={cx(
          "cc-input",
          props.iconLeft && "cc-input-has-icon",
          props.className
        )}
        disabled={props.disabled}
        name={props.name}
        onChange={e =>
          props.onChange((e.target as HTMLInputElement).value || "")
        }
        onKeyPress={e => {
          if (e.charCode === 13 && props.onEnter) {
            props.onEnter(e);
          } else if (props.onKeyPress) {
            props.onKeyPress(e);
          }
        }}
        placeholder={props.placeholder}
        style={props.style}
        type={props.type}
        value={props.value}
      />

      {!props.hideClear && !!props.value && (
        <div
          className="cc-input-icon-cont cc-input-clear-icon-cont"
          style={props.iconProps}
        >
          <Icon
            buttonProps={{
              name: "Clear Input"
            }}
            className="cc-input-icon"
            icon="close"
            isButton={true}
            onClick={() => props.onChange("")}
            size={14}
          />
        </div>
      )}
    </div>
  );
};
