/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import {
  StoreContext,
  RecipientsProps,
} from "../../data/stores/contexts/AppStore";
import {
  addAppointmentHandler,
  getAppointmentComposeAttendeesInfo,
  loadRecentlyRetrieved,
} from "../../data/utils";
import { getFocusedPerson } from "../../data/utils/helpers/";
import { StoreState } from "../../data/stores/contexts/AppStore";
import TytoCalls from "../../data/tyto/";
import { Button, Message } from "../../components/common/";
import RecentlyRetrieved from "../new-message/subcomponents/RecentlyRetrieved";
import Person from "../person/";
import Team from "../team/";

import "../new-message/NewMessage.scss";
import { Tyto } from "../../typings/tyto";

export default () => {
  let store = React.useContext(StoreContext);

  React.useEffect(() => {
    async function getRecipients() {
      if (store.dispatch && Office) {
        loadRecents(store);
        const recipients = await getAppointmentComposeAttendeesInfo();
        loadData(store, recipients);
      }
    }

    // * Gimmicky way of using async function within effect
    getRecipients();

    // * Handler for changes to "CC" and "To" field
    addAppointmentHandler((people: Office.EmailAddressDetails[]) => {
      // debugger;
      if (store.dispatch) {
        loadData(store, people);
      } else {
        console.log(
          "Tried to trigger recipients update but no store.dispatch found :("
        );
      }
    });
  }, []);

  const { state } = store;

  return (
    <section className="interface-section">
      <div style={{ paddingTop: "10px" }}>
        <Button
          onClick={async () => {
            if (store.dispatch && Office) {
              loadRecents(store);
              const recipients = await getAppointmentComposeAttendeesInfo();
              loadData(store, recipients);
            }
          }}
          value="Refresh"
        />
      </div>
      {state ? renderInterface(state) : <p>Creating State...</p>}
    </section>
  );
};

const renderInterface = (state: StoreState) => {
  // * [Option 1] No Recipients
  if (!state.recipients || !state.recipients.length) {
    if (
      !Array.isArray(state.recentlyRetrieved) ||
      !state.recentlyRetrieved.length
    ) {
      console.log("No recents found: ", state.recentlyRetrieved);
      return (
        <section>
          <Message value="No Recipients" style={{ fontWeight: "bold" }} />
        </section>
      );
    }

    if (!state.focusedPerson) {
      return <RecentlyRetrieved recentlyRetrieved={state.recentlyRetrieved} />;
    }
  }

  // * [Option 2] Only one Recipient
  if (
    (state.recipients && state.recipients.length === 1) ||
    state.focusedPerson
  ) {
    const firstPerson = getFocusedPerson({
      recipients: state.recipients,
      recentlyRetrieved: state.recentlyRetrieved,
      focusedPerson: state.focusedPerson,
    });

    if (!firstPerson || !firstPerson.emailAddress) {
      return <Message value="no info" />;
    }

    const discMini =
      state.discMini[firstPerson.emailAddress.toLowerCase()] ||
      state.recentlyRetrieved.find(
        (profile) =>
          firstPerson.emailAddress.toLowerCase() ===
          profile.emails[0].toLowerCase()
      );

    return (
      <Person
        discData={state.discData[firstPerson.emailAddress.toLowerCase()]}
        discMini={discMini}
        discProfileNA={state.discDataNull.some(
          (email) => email === firstPerson.emailAddress.toLowerCase()
        )}
        viewingTeam={_.get(state, "recipients.length", 1) > 1}
        email={firstPerson.emailAddress}
      />
    );
  }

  // * [Option 3] Multiple Recipients
  return (
    <Team
      discDataNull={state.discDataNull}
      discMini={state.discMini}
      recipients={state.recipients}
    />
  );
};

const loadData = (
  store: RecipientsProps,
  recipients: Office.EmailAddressDetails[]
) => {
  if (store.dispatch) {
    store.dispatch({
      callback: (callbackPayload) => {
        TytoCalls.DISCProfilesMini.get({
          emails: callbackPayload.emails.join(","),
        })
          .then((resp: any) => {
            if (!store.dispatch) {
              return;
            }

            store.dispatch({
              payload: {
                emailsUsed: callbackPayload.emails,
                discMini: resp.discProfiles,
              },
              type: "DISC_MINI_PROFILES_LOADED",
            });
          })
          .catch((err: any) => {
            console.warn(err);
          });
      },
      type: "RECIPIENTS_LOADED",
      payload: { recipients },
    });
  }
};

const loadRecents = (store: RecipientsProps) => {
  if (store.dispatch) {
    const recents = loadRecentlyRetrieved();
    store.dispatch({
      payload: {
        recentlyRetrieved: recents,
      },
      type: "RECENT_CONTACTS_LOADED",
    });
  }
};
