import {
  WEB_APP_ASSESSMENT_URI,
  WEB_APP_SESSION_CHECK_URI,
  WEB_APP_BASE_URL,
  WEB_APP_GROUPS_PREVIEW_URI,
  WEB_APP_HOME_URI,
  WEB_APP_OUTSIDE_SESSION_KEY_PARAM_KEY,
  WEB_APP_PROFILE_URI
} from "../../constants/";

export function webAppHomeUrl() {
  return `${WEB_APP_BASE_URL}${WEB_APP_HOME_URI}`;
}

export function webAppTakeAssessmentUrl() {
  return `${WEB_APP_BASE_URL}${WEB_APP_ASSESSMENT_URI}`;
}

export function webAppGroupsPreview() {
  return `${WEB_APP_BASE_URL}${WEB_APP_GROUPS_PREVIEW_URI}`;
}

export function webAppLinkWithSession(sessionKey: string) {
  return `${WEB_APP_BASE_URL}${WEB_APP_SESSION_CHECK_URI}?${WEB_APP_OUTSIDE_SESSION_KEY_PARAM_KEY}=${sessionKey}&redirect=`;
}

export function webAppFullProfileUrl({
  personID,
  sessionKey,
  subInterface
}: {
  personID: number;
  sessionKey?: string;
  subInterface?: string;
}) {
  if (!personID || !sessionKey) {
    return "";
  }

  //   return `http://localhost:3001${WEB_APP_SESSION_CHECK_URI}?${WEB_APP_OUTSIDE_SESSION_KEY_PARAM_KEY}=${sessionKey}&redirect=${WEB_APP_PROFILE_URI}${personID}`;
  return `${WEB_APP_BASE_URL}${WEB_APP_SESSION_CHECK_URI}?${WEB_APP_OUTSIDE_SESSION_KEY_PARAM_KEY}=${sessionKey}&redirect=${WEB_APP_PROFILE_URI}${personID}${
    subInterface ? `/${subInterface}` : ""
  }`;
}
