/*
 * Creates Context for Email Recipients
 */
import * as React from "react";
import * as _ from "lodash";
import { StoreAction, SessionData, InterfaceContext } from "../../../typings";
import {
  clearSessionData,
  getInterfaceContextParam,
  setSessionData,
  setViewAsDarkMode,
  setStoredPersonalDISCMini,
  loadRecentlyRetrieved,
  updateRecentlyRetrieved,
} from "../../utils";
import {
  getItem as getLocalStorageItem
} from "../../storage/utils"
import {
  findRecipientsWithoutDISCMiniInfo,
  mapMiniProfilesByEmails,
  mapPersonEmailsByPersonIDs,
} from "../../utils/helpers";
import {
  VIEW_AS_DARK_MODE
} from "../../constants/";
import { Tyto } from "../../../typings/tyto";
import {
  initMixPanelPerson,
  setMixPanelUserProperties,
  triggerMixPanelEvent,
} from "../../logging/";

export interface RecipientsProps {
  state?: StoreState;
  dispatch?: React.Dispatch<StoreAction<RecipientActionType>>;
}

export type PersonData = {
  loading: boolean;
  person?: Tyto.Person;
};

export interface StoreState {
  IS_DARK_MODE: boolean;
  hasCheckedForStoredSession: boolean;
  INTERFACE_CONTEXT: InterfaceContext;
  failedSessionReason?: string;
  focusedPerson?: string;
  loading?: boolean;
  officeIsReady?: boolean;
  recipients?: Office.EmailAddressDetails[];
  discCompareProfiles: {
    [x: number]: Tyto.DISCCompareProfile;
  };
  discData: {
    [x: string]: Tyto.DISCInfo;
  };
  discMini: {
    [x: string]: Tyto.DISCProfileMini;
  };
  discTeamStyle: {
    [x: string]: Tyto.DISCTeamProfile;
  };
  discDataNull: string[];
  discMiniNull: string[];
  loggedInUserID: number;
  loggedInUserPermit?: any;
  officeVersion: string;
  personIDEmailMap: {
    [x: number]: string;
  };
  personalDiscMini?: Tyto.DISCProfileMini;
  recentlyRetrieved: Tyto.DISCProfileMini[];
  sessionData?: any;
  showSettings: boolean;
}

type RecipientActionType =
  | "CLEAR_USER_SESSION_DATA"
  | "DARK_MODE_PREF_LOADED"
  | "DISC_MINI_PROFILES_LOADED"
  | "DISC_COMPARE_PROFILE_LOADED"
  | "DISC_TEAM_PROFILE_LOADED"
  | "RESET"
  | "RECIPIENTS_LOADED"
  | "USER_LOGGED_IN"
  | "OFFICE_LOADED"
  | "RECENT_CONTACTS_LOADED"
  | "SET_FOCUSED_PROFILE"
  | "SET_OFFICE_VERSION_NUMBER"
  | "UPDATE_DARK_MODE"
  | "STORED_SESSION_CHECKED"
  | "TOGGLE_SHOW_SETTINGS"
  | "USER_SEARCH_LOADED"
  | "USER_DISC_MINI_LOADED"
  | "USERS_DISC_INFO_LOADED";

let StoreContext: React.Context<RecipientsProps> = React.createContext({});

let initialState: StoreState = {
  INTERFACE_CONTEXT: getInterfaceContextParam(),
  IS_DARK_MODE: !!getLocalStorageItem(VIEW_AS_DARK_MODE),
  discCompareProfiles: {},
  discData: {},
  discMini: {},
  discTeamStyle: {},
  discDataNull: [],
  discMiniNull: [],
  hasCheckedForStoredSession: false,
  loading: false,
  loggedInUserID: 0,
  officeVersion: "1.1",
  personIDEmailMap: {},
  recipients: [],
  recentlyRetrieved: loadRecentlyRetrieved(),
  showSettings: false,
};

let reducer = (state: StoreState, action: StoreAction<RecipientActionType>) => {
  console.log(action);

  switch (action.type) {
    case "CLEAR_USER_SESSION_DATA":
      // clearSessionData();

      return {
        ...state,
        discCompareProfiles: {},
        loggedInUserID: 0,
        personIDEmailMap: {},
        recipients: [],
        discData: {},
        discMini: {},
        discTeamStyle: {},
        discDataNull: [],
        discMiniNull: [],
        showSettings: false,
        // hasCheckedForStoredSession: false,
        sessionData: undefined,
      };
    case "DARK_MODE_PREF_LOADED":
      return {
        ...state,
        IS_DARK_MODE: !!_.get(action, "payload.isDarkMode", state.IS_DARK_MODE)
      };
    case "DISC_COMPARE_PROFILE_LOADED":
      if (action.payload.discCompareProfile) {
        const personID = _.get(
          action.payload,
          "discCompareProfile.result.personID",
          0
        );
        if (personID) {
          const discCompareProfiles = {
            ...state.discCompareProfiles,
            [personID]: action.payload.discCompareProfile,
          };

          return {
            ...state,
            discCompareProfiles,
          };
        }
      }

      return state;
    case "DISC_TEAM_PROFILE_LOADED":
      if (action.payload.discStyle) {
        const discTeamStyle = {
          ...state.discTeamStyle,
          [action.payload.discStyle.styleKey.toLowerCase()]: action.payload
            .discStyle,
        };

        return {
          ...state,
          discTeamStyle,
        };
      }

      return state;
    case "OFFICE_LOADED":
      return {
        ...state,
        officeIsReady: true,
      };
    case "RECENT_CONTACTS_LOADED":
      if (action.payload && action.payload.recentlyRetrieved) {
        const recentlyRetrieved = [
          ...action.payload.recentlyRetrieved,
          ...state.recentlyRetrieved,
        ];

        const emailsByPersonIDsMap = mapPersonEmailsByPersonIDs(
          action.payload.recentlyRetrieved || []
        );

        const personIDEmailMap = {
          ...state.personIDEmailMap,
          ...emailsByPersonIDsMap,
        };

        const discProfilesByEmail = mapMiniProfilesByEmails(
          action.payload.recentlyRetrieved,
          _.get(state, "sessionData.domainID", 0)
        );
        console.log("Mini profiles in recents loaded: ", discProfilesByEmail);

        return {
          ...state,
          discMini: {
            ...discProfilesByEmail,
            ...state.discMini,
          },
          personIDEmailMap,
          recentlyRetrieved,
        };
      }

      return state;
    case "RECIPIENTS_LOADED":
      if (action.payload && Array.isArray(action.payload.recipients)) {
        const recipients = action.payload
          .recipients as Office.EmailAddressDetails[];

        const recipientsWithoutDiscInfo = findRecipientsWithoutDISCMiniInfo(
          state.discMini,
          recipients
        );

        let stateCopy = {
          ...state,
        };

        if (recipientsWithoutDiscInfo.length && action.callback) {
          const emails = recipientsWithoutDiscInfo.map((recipient) =>
            recipient.emailAddress
              ? (recipient.emailAddress as string).toLowerCase()
              : ""
          );

          action.callback({ emails });
        }

        return {
          ...stateCopy,
          recipients: _.uniqBy(recipients, "emailAddress"),
        };
      }

      return state;
    case "SET_FOCUSED_PROFILE":
      if (action.payload) {
        if (action.payload.email) {
          triggerMixPanelEvent("TEAM_PERSON_SELECTED", {
            userStyleKey: _.get(state, "personalDiscMini.styleKey3", null),
            userStyleName: _.get(state, "personalDiscMini.styleName3", null),
            targetPersonStyleKey: _.get(
              state,
              `discMini[${action.payload.email}].styleKey3`,
              null
            ),
            targetPersonStyleName: _.get(
              state,
              `discMini[${action.payload.email}].styleName3`,
              null
            ),
          });
        }

        return {
          ...state,
          focusedPerson: action.payload.email as string,
        };
      }

      return state;
    case "SET_OFFICE_VERSION_NUMBER":
      if (action.payload.version) {
        return {
          ...state,
          officeVersion: action.payload.version,
        };
      }

      return state;
    case "STORED_SESSION_CHECKED":
      if (action.payload) {
        return {
          ...state,
          hasCheckedForStoredSession: !!action.payload
            .hasCheckedForStoredSession,
          failedSessionReason: action.payload.failedSessionReason,
        };
      }

      return state;
    case "USER_LOGGED_IN":
      if (action.payload) {
        setSessionData(action.payload as SessionData);
        const loggedInUserID = _.get(action, "payload.userID", 0);

        if (loggedInUserID && !state.loggedInUserID) {
          initMixPanelPerson(loggedInUserID);
          setMixPanelUserProperties(loggedInUserID, {
            domainID: action.payload.domainID || 0,
          });

          triggerMixPanelEvent("APP_USER_LOGGED_IN", {
            userID: loggedInUserID,
            dominID: action.payload.domainID || null,
            INTERFACE_CONTEXT: state.INTERFACE_CONTEXT,
          });
        }

        return {
          ...state,
          sessionData: action.payload,
          hasCheckedForStoredSession: true,
          showSettings: false,
          loggedInUserID,
        };
      }

      return state;
    case "USER_SEARCH_LOADED":
      // if (action.payload && Array.isArray(action.payload.people)) {
      //   const peopleData = (action.payload.people as Tyto.Person[]).reduce(
      //     (accum: { [x: string]: PersonData }, person) => {
      //       if (person.email) {
      //         accum[person.email.toLowerCase()] = { loading: false, person };
      //       }

      //       return accum;
      //     },
      //     {}
      //   );

      //   const newPersonIDEmailMap: { [x: number]: string } = (action.payload
      //     .people as Tyto.Person[]).reduce(
      //     (accum: { [x: number]: string }, person) => {
      //       if (person.email) {
      //         accum[person.personID] = person.email.toLowerCase();
      //       }

      //       return accum;
      //     },
      //     {}
      //   );

      //   if (action.callback) {
      //     const personIDs = Object.keys(newPersonIDEmailMap);

      //     if (personIDs.length) {
      //       action.callback({ personIDs });
      //     }
      //   }

      //   return {
      //     ...state,
      //     peopleData: {
      //       ...state.peopleData,
      //       ...peopleData
      //     },
      //     personIDEmailMap: {
      //       ...state.personIDEmailMap,
      //       ...newPersonIDEmailMap
      //     }
      //   };
      // }

      return state;
    case "USERS_DISC_INFO_LOADED":
      if (action.payload && Array.isArray(action.payload.discProfiles)) {
        const newDiscData = (action.payload
          .discProfiles as Tyto.DISCInfo[]).reduce(
          (accum: { [x: string]: Tyto.DISCInfo }, discProfile) => {
            if (discProfile && discProfile.personID) {
              const key = state.personIDEmailMap[discProfile.personID];

              if (key && typeof key === "string") {
                accum[key.toLowerCase()] = discProfile as Tyto.DISCInfo;
              }
            }

            return accum;
          },
          {}
        );

        const discData = {
          ...state.discData,
          ...newDiscData,
        };

        console.log("New DISC Data should be: ", discData);

        return {
          ...state,
          discData,
        };
      }

      return state;
    case "DISC_MINI_PROFILES_LOADED":
      if (action.payload && Array.isArray(action.payload.discMini)) {
        const discProfiles: Tyto.DISCProfileMini[] = _.get(
          action.payload,
          "discMini",
          []
        );

        const discProfilesByEmail = mapMiniProfilesByEmails(
          discProfiles,
          _.get(state, "sessionData.domainID", 0)
        );
        const emailsByPersonIDsMap = mapPersonEmailsByPersonIDs(discProfiles);

        console.log("profilesByEmail: ", discProfilesByEmail);
        console.log("emailsByPErsonID: ", emailsByPersonIDsMap);

        const personIDEmailMap = {
          ...state.personIDEmailMap,
          ...emailsByPersonIDsMap,
        };

        // * Get Emails sent that didn't return in response
        const emailsUsed = action.payload.emailsUsed as string[];
        const omittedEmails = Array.isArray(emailsUsed)
          ? emailsUsed
              .filter((email) => !discProfilesByEmail[email.toLowerCase()])
              .map((email) => email.toLowerCase())
          : [];

        const discMini = {
          ...state.discMini,
          ...discProfilesByEmail,
        };

        console.log("New DISC Mini Data should be: ", discMini);
        const discMiniAsArray = Object.values(discMini);
        const recentlyRetrieved = _.slice(
          _.uniqBy(
            [...discMiniAsArray, ...state.recentlyRetrieved],
            "personID"
          ),
          0,
          10
        );

        updateRecentlyRetrieved(recentlyRetrieved);

        if (emailsUsed && emailsUsed.length) {
          const [profiles, nullProfiles] = emailsUsed.reduce(
            (accum: number[], email) => {
              if (discMini[email]) {
                return [accum[0] + 1, accum[1]];
              }

              return [accum[0], accum[1] + 1];
            },
            [0, 0]
          );

          triggerMixPanelEvent("PROFILES_LOADED", {
            emailsUsed: emailsUsed.length,
            nullProfiles,
            profiles,
          });
        }

        return {
          ...state,
          discMini,
          discDataNull: _.uniq([...state.discDataNull, ...omittedEmails]),
          personIDEmailMap,
          recentlyRetrieved,
        };
      }

      return state;
    case "TOGGLE_SHOW_SETTINGS":
      return {
        ...state,
        showSettings: !state.showSettings,
      };
    case "USER_DISC_MINI_LOADED":
      if (action.payload) {
        if (action.payload.discMini && !action.payload.omitSettingsCall) {
          setStoredPersonalDISCMini(action.payload.discMini);
        }

        return {
          ...state,
          personalDiscMini: action.payload.discMini,
          loggedInUserPermit: _.get(
            action.payload,
            "discMini.teamToolsPermit",
            undefined
          ),
        };
      }

      return state;
    case "UPDATE_DARK_MODE":
      const newDarkModeValue = !!_.get(action, "payload.isDarkMode", !!state.IS_DARK_MODE);

      // if (newDarkModeValue !== state.IS_DARK_MODE) {
      //   setViewAsDarkMode(newDarkModeValue);
      // }

      return {
        ...state,
        IS_DARK_MODE: newDarkModeValue
      };
    default:
      return state;
  }
};

// function updateStoreState(state: StoreState, key: keyof StoreState, value: any) {
//   return {
//     ...state,
//     [key]: {
//       ...state[key],
//       ...value
//     }
//   };
// }

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
