import * as React from "react";

import { IconProps } from "../typings";

export default ({ className, color, gradient, size }: IconProps) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            height={size}
            width={size}
            fill={color}
        >
            {Array.isArray(gradient) && (
                <defs>
                    <linearGradient id="IDCardGradient1" x1="0" x2="1" y1="0" y2="1">
                        {gradient.map(grad => (
                            <stop offset={`${grad.offsetPercent}%`} stopColor={grad.color} />
                        ))}
                    </linearGradient>
                </defs>
            )}
            <path
                fill={Array.isArray(gradient) ? "url(#IDCardGradient1)" : ""}
                d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 400H303.2c.9-4.5.8 3.6.8-22.4 0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6 0 26-.2 17.9.8 22.4H48V144h480v288zm-168-80h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm-168 96c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64z" />
            />
    </svg>
    );
};
