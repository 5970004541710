/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  calcGradient,
  getPersonValueRespectfully,
} from "../../../data/utils/helpers";

import { Tyto } from "../../../typings/tyto";
import { URL_BASE } from "../../../data/constants/index";
import Icon from "../icon";
import { iconType } from "../icon/typings";

import "./style.scss";

interface Props {
  altImageAssetID?: number;
  asset?: Tyto.Asset;
  borderWidth?: number;
  className?: string;
  discMiniProfile?: Tyto.DISCProfileMini;
  hasGradient?: boolean;
  omitHoverIcon?: boolean;
  onClickIcon?: iconType;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  photoID?: number;
  size?: number;
  style?: React.CSSProperties;
  userName: string;
  discValues: {
    d: number;
    i: number;
    s: number;
    c: number;
  };
}

export default ({
  altImageAssetID,
  asset,
  className,
  discMiniProfile,
  discValues,
  hasGradient = true,
  omitHoverIcon,
  onClickIcon,
  onMouseEnter,
  onMouseLeave,
  onClick,
  size = 25,
  style,
  userName,
}: Props) => {
  const [src, updateSrc] = React.useState(getSrc({ altImageAssetID, asset }));
  const [gradient, updateGradient] = React.useState(
    !hasGradient || doesFailPermissionCheck(discMiniProfile)
      ? ""
      : calcGradient(discValues)
  );
  const [initials, updateInitials] = React.useState(() => {
    return userName
      .split(" ")
      .map((word) => word.charAt(0))
      .filter((letter) => !!letter)
      .slice(0, 2)
      .join(". ");
  });

  // * [E-1] - Update Initials when userName changes
  React.useEffect(() => {
    updateInitials(
      userName
        .split(" ")
        .map((word) => word.charAt(0))
        .filter((letter) => !!letter)
        .join(". ")
    );
  }, [userName]);

  // * [E-2] - Update Src when asset changes
  React.useEffect(() => {
    updateSrc(getSrc({ altImageAssetID, asset }));
  }, [asset]);

  // * [E-3] - Update gradient if any related values change
  React.useEffect(() => {
    updateGradient(
      !hasGradient || doesFailPermissionCheck(discMiniProfile)
        ? ""
        : calcGradient(discValues)
    );
  }, [discValues, gradient, hasGradient]);

  const pseudoBorderWidth = getPseudoBorderWidth(size);
  const sizeAccountingForPadding = size - pseudoBorderWidth * 2;

  return (
    <div
      className={cx(
        "cc-userthumb-cont",
        hasGradient && "cc-userthumb-cont-has-gradient",
        !!onClick && "has-pointer-event",
        className
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        height: size,
        width: size,
        maxHeight: size,
        maxWidth: size,
        minHeight: size,
        minWidth: size,
        background: !!gradient
          ? `linear-gradient(to bottom right${gradient})`
          : "",
        ...(style || {}),
        padding: `${pseudoBorderWidth}px`,
      }}
      title={userName}
    >
      {src ? (
        <img
          className="cc-userthumb"
          src={src}
          style={{
            height: `${sizeAccountingForPadding}px`,
            width: `${sizeAccountingForPadding}px`,
            maxHeight: `${sizeAccountingForPadding}px`,
            maxWidth: `${sizeAccountingForPadding}px`,
            minHeight: `${sizeAccountingForPadding}px`,
            minWidth: `${sizeAccountingForPadding}px`,
          }}
        />
      ) : (
        <p
          className="cc-userthumb-no-img"
          style={{ fontSize: `${size * 0.35}px` }}
        >
          {initials.toUpperCase()}.
        </p>
      )}

      {onClick && !omitHoverIcon && (
        <Icon
          buttonProps={{
            className: "cc-userthumb-onclick-icon-cont",
          }}
          className="cc-userthumb-onclick-icon"
          isButton={true}
          icon={onClickIcon || "arrow-from-left"}
          size={size * 0.35}
        />
      )}
    </div>
  );
};

const doesFailPermissionCheck = (discMiniProfile?: Tyto.DISCProfileMini) => {
  if (!discMiniProfile) {
    return false; // ?
  }

  const permissionCheck = getPersonValueRespectfully({
    key: "d3",
    discMiniProfile,
  });

  return _.get(permissionCheck, "permitItem.HIDE", true);
};

const getPseudoBorderWidth = (size: number) => {
  return Math.ceil(size / 25);
};

const getSrc = ({
  altImageAssetID,
  asset,
}: {
  altImageAssetID?: number;
  asset?: Tyto.Asset;
}) => {
  if (!asset || !Array.isArray(asset.encodings)) {
    return altImageAssetID
      ? `https://${URL_BASE}/v25/user/photo/?assetID=${altImageAssetID}&encoding=ocDEFAULT`
      : "";
  }

  const encoding = asset.encodings.find(
    (enc) => enc.encodingType.toLowerCase() === "ocdefault"
  );

  return encoding ? `https://${URL_BASE}/${encoding.pathURL}` : "";
};
