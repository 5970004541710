import { ENDPOINT_PERSON_GET } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PERSON_GET;

export const Person = {
  get(params: Endpoints.Tyto.Person.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, { ...params }, callOpts);
  }
};
