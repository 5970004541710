/*
 * Component Description
 */
import * as React from "react";
import { Icon } from "../../../components/common/";

import "./PreviousNav.scss";

interface Props {
  onClick: () => void;
  buttonText?: string;
}

export default (props: Props) => {
  return (
    <nav className="nav-bar">
      <button className="nav-bar-btn" onClick={props.onClick}>
        <Icon size={30} icon="angle-left" onClick={props.onClick} />{" "}
        {props.buttonText || "Back"}
      </button>
    </nav>
  );
};
