import axios from "axios";
import * as _ from "lodash";

import { DEFAULT_LOCAL_STORAGE_EXPIRATION_TIME_MS } from "../constants/";
import {
  formatXMLRequestForStorage,
  getItem as getLocalStorageItem,
  setItem as setLocalStorageItem,
  getTimestamp
} from "../storage/utils";
import { getSessionKey } from "../utils/";

type CallType = "delete" | "get" | "post" | "put";

export type CallOpts = {
  omitSessionKey?: boolean;
  useLocalStorage?: boolean;
  localStorageAgeOverride?: number; // * In MS
  storeResponse?: boolean;
  respTarget?: string;
};

export function callWrapper(
  callType: CallType,
  endpoint: string,
  params?: object,
  opts?: CallOpts
) {
  // * Get sessionKey from roamingSettings
  //   const storedSessionData = Office.context.roamingSettings.get(
  //     SESSION_DATA_KEY
  //   );
  //   const sessionKey = _.get(storedSessionData, "sessionKey", undefined);
  const sessionKey = getSessionKey();

  if (!!opts && !opts.omitSessionKey && !sessionKey) {
    console.log("no session key found in callWrapper function when expected.");
  }

  const paramsAltered =
    sessionKey && !(opts && opts.omitSessionKey)
      ? { ...(params || {}), sessionKey }
      : params || {};

  switch (callType) {
    case "delete":
      return deleteCall(endpoint, paramsAltered);
    case "get":
      return getCall(endpoint, paramsAltered, opts);
    case "post":
      return postCall(endpoint, paramsAltered);
    case "put":
      return putCall(endpoint, paramsAltered);
    default:
      return new Promise((res, rej) => {
        rej(
          `Incorrect callType supplied. Expected 'delete', 'get', 'post', or 'put', but got ${callType}`
        );
      });
  }
}

export function deleteCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    axios
      .delete(endpoint, { params })
      .then(resp => {
        res(resp.data);
      })
      .catch(err => {
        rej(
          typeof err === "string"
            ? err
            : _.get(err, "error.msg", _.get(err, "msg", "An error occurred"))
        );
      });
  });
}

// axios.interceptors.response.use(
//   (onResp: any) => {
//     return onResp;
//   },
//   onRej => {
//     if (onRej && onRej.error) {
//       console.log("this got hit", onRej.error);
//     }

//     return onRej;
//   }
// );

export function getCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    if (opts && opts.useLocalStorage) {
      const localStorageKey = formatXMLRequestForStorage(endpoint, params);
      const timestamp = getTimestamp(localStorageKey);
      const timeAllotted =
        (opts && opts.localStorageAgeOverride) ||
        DEFAULT_LOCAL_STORAGE_EXPIRATION_TIME_MS;

      if (timestamp && timestamp + timeAllotted > Date.now()) {
        const storedData = getLocalStorageItem(localStorageKey);

        if (typeof storedData !== "undefined") {
          console.log(`Used stored Dat for ${endpoint}`);
          res(storedData);
        } else {
          console.log(
            `GET call to ${endpoint} opted out of localStorage because data was undefined.`
          );
        }
      } else {
        console.log(
          `GET call to ${endpoint} opted out of localStorage because data was not found or expired.`
        );
      }
    }

    axios
      .get(endpoint, { params })
      .then(resp => {
        const respTarget = _.get(opts, "respTarget", undefined);

        // * If a target was supplied return that, but if it isn't or data can't be found simply return resp
        const data = respTarget
          ? _.get(resp.data, respTarget, resp.data)
          : resp.data;

        if (opts && opts.storeResponse) {
          if (endpoint) {
            const key = formatXMLRequestForStorage(endpoint, params);

            setLocalStorageItem(key, data);
          } else {
            console.warn("Expected endpoint url to be defined but was not.");
          }
        }

        res(data);
      })
      .catch(err => {
        rej("An error occurred");
      });
  });
}

export function postCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    axios
      .post(endpoint, params)
      .then(resp => {
        res(resp.data);
      })
      .catch(err => {
        console.warn("Post Call errored", err);
        const errAsString = JSON.stringify(err);
        const error = _.get(err, "response.data.error", errAsString);
        rej(error || "An error occurred (could not parse error)");
      });
  });
}

export function putCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    axios
      .put(endpoint, params)
      .then(resp => {
        res(resp.data);
      })
      .catch(err => {
        console.log("PUt error: ", err);
        debugger;
        rej(
          typeof err === "string"
            ? err
            : _.get(err, "error.msg", _.get(err, "msg", "An error Occurred"))
        );
      });
  });
}
