/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { calcGroupStyle } from "../utils/";
import { getLetterColor } from "../../../data/utils/helpers/";
import { TOGGLE_SECTION_BLOCKS } from "../../../data/constants/";
import {
  Button,
  Checkbox,
  DISCPlotGraph,
  HeatMap,
  TextButton,
  ToggleSection,
} from "../../../components/common/";
import { Tyto } from "../../../typings/tyto";

import "./TeamGroupStyle.scss";

interface Props {
  discMini: Tyto.DISCProfileMini[];
  groupStyle: { label: string; value: number }[];
  groupStyleKeyWithStdDeviation: string;
  setFocusedUser?: (userID: number) => void;
  teamStyle?: Tyto.DISCTeamProfile;
  teamContRef: React.MutableRefObject<null | HTMLElement>;
}

const DEFAULT_WIDTH = 225;

export default (props: Props) => {
  const [showAsHeatMap, updateShowAsHeatMap] = React.useState(false);
  const [showAsStressed, updateShowAsStressed] = React.useState(false);
  const [filteredMinis, updateFilteredMini] = React.useState(
    filterMinis(props.discMini)
  );
  // const [groupStyle, updateGroupStyle] = React.useState(
  //   calcGroupStyle(props.discMini)
  // );

  React.useEffect(() => {
    updateFilteredMini(props.discMini);
  }, [props.discMini]);

  return (
    <ToggleSection
      className=""
      mixpanelKey={TOGGLE_SECTION_BLOCKS.Team.GROUP_STYLE}
      title="Group Style"
      renderFunction={(isExpanded, sectionWrapperRef) => {
        const [sectionWrapperWidth, updateSectionWrapperWidth] = React.useState(
          getSectionWidth(sectionWrapperRef)
        );
        React.useEffect(() => {
          updateSectionWrapperWidth(getSectionWidth(sectionWrapperRef));
        }, [sectionWrapperRef]);

        return !isExpanded ? (
          <span style={{ visibility: "hidden" }} />
        ) : (
          <>
            <h1 className="team-group-style-title">
              {props.groupStyle.map(({ label, value }) =>
                value > 0 &&
                props.groupStyleKeyWithStdDeviation.includes(label) ? (
                  <span
                    className={cx("team-group-style-title-letter", `title-letter-${label}`)}
                    key={label}
                    style={{
                      color: props.groupStyleKeyWithStdDeviation.includes(label)
                        ? getLetterColor(label)
                        : "#9e9e9e",
                    }}
                  >
                    {label.toUpperCase()}
                  </span>
                ) : (
                  <span key={label} style={{ visibility: "hidden" }} />
                )
              )}
            </h1>

            {/* {props.teamStyle && (
              <p className="team-group-style-description">
                {props.teamStyle.teamDescription}
              </p>
            )} */}

            {sectionWrapperWidth && (
              <>
                {showAsHeatMap ? (
                  <HeatMap
                    heatMapType={showAsStressed ? 2 : 3}
                    profiles={filteredMinis}
                    onUserSelect={(userID) => {
                      if (props.setFocusedUser) {
                        props.setFocusedUser(userID);
                      }
                    }}
                    size={sectionWrapperWidth}
                  />
                ) : (
                  <DISCPlotGraph
                    canSelectUser={true}
                    size={sectionWrapperWidth}
                    groupStyle={
                      filteredMinis
                        ? filteredMinis.map((discProfile) => ({
                            d: discProfile[showAsStressed ? "d2" : "d3"],
                            i: discProfile[showAsStressed ? "i2" : "i3"],
                            s: discProfile[showAsStressed ? "s2" : "s3"],
                            c: discProfile[showAsStressed ? "c2" : "c3"],
                            personName: discProfile.personName,
                            asset: discProfile.profileImageAsset,
                          }))
                        : []
                    }
                  />
                )}

                <div className="disc-heatmap-btns team-group-style-toggle-buttons-cont">
                  <Button
                    className={
                      "cc-disc-heatmap-btn" +
                      (!showAsHeatMap ? " cc-disc-heatmap-btn-selected" : "")
                    }
                    type={!showAsHeatMap ? "color" : "hollow"}
                    onClick={() => updateShowAsHeatMap(false)}
                    value="Plot Graph"
                  />
                  <Button
                    className={
                      "cc-disc-heatmap-btn" +
                      (showAsHeatMap ? " cc-disc-heatmap-btn-selected" : "")
                    }
                    onClick={() => updateShowAsHeatMap(true)}
                    type={showAsHeatMap ? "color" : "hollow"}
                    value="Heat Map"
                  />
                </div>

                <div className="disc-heatmap-btns team-group-style-stressed-toggle-cont">
                  <Checkbox
                    className="team-group-style-stressed-toggle"
                    checked={showAsStressed}
                    onCheck={(newVal) => updateShowAsStressed(newVal)}
                    size={16}
                  />

                  <TextButton
                    className="team-group-style-stressed-toggle-text"
                    onClick={() => updateShowAsStressed(!showAsStressed)}
                    value="Show Under Stress"
                  />
                </div>
              </>
            )}
          </>
        );
      }}
    />
  );
};

const getSectionWidth = (
  sectionContRef: React.MutableRefObject<null | HTMLElement>
) => {
  //   debugger;
  if (sectionContRef.current) {
    const info = sectionContRef.current.getBoundingClientRect();
    return info.width;
  }

  return 0;
};

const filterMinis = (discMinis: Tyto.DISCProfileMini[]) => {
  if (!discMinis || !discMinis.length) {
    return [];
  }

  return discMinis.filter(
    (discMini) => !_.get(discMini, "permitMatrix.SCORES_.HIDE", true)
  );
};

const getTeamContWidth = (
  teamContRef: React.MutableRefObject<null | HTMLElement>
) => {
  if (teamContRef.current) {
    const info = teamContRef.current.getBoundingClientRect();
    // debugger;
    return info.width;
  }

  return 0;
};
