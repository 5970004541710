/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as Icons from "./icons/";

import { iconType } from "./typings/";

import "./style.scss";

export interface IconProps {
  buttonProps?: {
    className?: string;
    disabled?: boolean;
    name?: string;
    style?: React.CSSProperties;
  };
  color?: string;
  className?: string;
  hasDropShadow?: boolean;
  gradient?: {
    offsetPercent: number;
    color: string;
  }[];
  style?: React.CSSProperties;
  icon: iconType;
  isButton?: boolean;
  title?: string;
  onClick?: () => void;
  size: number;
}

export default (props: IconProps) => {
  const [gradientKey] = React.useState(() => {
    return Array.from({ length: 10 }, () =>
      String.fromCharCode(Math.floor(Math.random() * 42) + 48)
    ).join("");
  });

  if (props.isButton) {
    return (
      <button
        name={props.icon}
        {...(props.buttonProps || {})}
        className={cx(
          "cc-icon-button",
          props.buttonProps && props.buttonProps.className
        )}
        onClick={props.onClick}
        title={props.buttonProps ? props.buttonProps.name : undefined}
      >
        {renderIcon(props, gradientKey)}
      </button>
    );
  }

  return renderIcon(props, gradientKey);
};

const renderIcon = (
  {
    color = "#223958",
    className,
    gradient,
    hasDropShadow,
    icon,
    size,
    style,
    title,
  }: IconProps,
  gradientKey: string
) => {
  const iconProps = {
    className: cx(
      "cc-icon",
      hasDropShadow && "cc-icon-has-drop-shadow",
      className
    ),
    color,
    gradient,
    gradientKey,
    size,
    style,
    title,
  };

  switch (icon) {
    case "anchor":
      return <Icons.Anchor {...iconProps} />;
    case "angle-down":
      return <Icons.AngleDown {...iconProps} />;
    case "angle-left":
      return <Icons.AngleLeft {...iconProps} />;
    case "angle-right":
      return <Icons.AngleRight {...iconProps} />;
    case "angle-up":
      return <Icons.AngleUp {...iconProps} />;
    case "arrow-alt-from-top":
      return <Icons.AngleAltFromTop {...iconProps} />;
    case "arrow-from-left":
      return <Icons.AngleFromLeft {...iconProps} />;
    case "balance-scale":
      return <Icons.BalanceScale {...iconProps} />;
    case "brain":
      return <Icons.Brain {...iconProps} />;
    case "check":
      return <Icons.Check {...iconProps} />;
    case "chess-knight":
      return <Icons.ChessKnight {...iconProps} />;
    case "chess-rook":
      return <Icons.ChessRook {...iconProps} />;
    case "circle":
      return <Icons.Circle {...iconProps} />;
    case "close":
      return <Icons.Close {...iconProps} />;
    case "cog":
      return <Icons.Cog {...iconProps} />;
    case "comment-alt-exclamation":
      return <Icons.CommentAltExclamation {...iconProps} />;
    case "compass":
      return <Icons.Compass {...iconProps} />;
    case "crown":
      return <Icons.Crown {...iconProps} />;
    case "crown-solid":
      return <Icons.CrownSolid {...iconProps} />;
    case "dove":
      return <Icons.Dove {...iconProps} />;
    case "drum":
      return <Icons.Drum {...iconProps} />;
    case "elipsis-h":
      return <Icons.ElipsisH {...iconProps} />;
    case "elipsis-v":
      return <Icons.ElipsisV {...iconProps} />;
    case "envelope":
      return <Icons.Envelope {...iconProps} />;
    case "external-link":
      return <Icons.ExternalLink {...iconProps} />;
    case "eye":
      return <Icons.Eye {...iconProps} />;
    case "filter":
      return <Icons.Filter {...iconProps} />;
    case "glass-cheers":
      return <Icons.GlassCheers {...iconProps} />;
    case "glasses":
      return <Icons.Glasses {...iconProps} />;
    case "globe":
      return <Icons.Globe {...iconProps} />;
    case "handshake":
      return <Icons.Handshake {...iconProps} />;
    case "hat-cowboy-side":
      return <Icons.HatCowboySide {...iconProps} />;
    case "home-lg-alt-solid":
      return <Icons.HomeLgAltSolid {...iconProps} />;
    case "home-lg-alt":
      return <Icons.HomeLgAlt {...iconProps} />;
    case "id-card":
      return <Icons.IDCard {...iconProps} />;
    case "info-circle":
      return <Icons.InfoCircle {...iconProps} />;
    case "knight":
      return <Icons.Knight {...iconProps} />;
    case "leaf-oak":
      return <Icons.LeafOak {...iconProps} />;
    case "lightbulb-on":
      return <Icons.LightbulbOn {...iconProps} />;
    case "list-ul":
      return <Icons.ListUL {...iconProps} />;
    case "lock-alt":
      return <Icons.LockAlt {...iconProps} />;
    case "minus":
      return <Icons.Minus {...iconProps} />;
    case "pencil-ruler":
      return <Icons.PencilRuler {...iconProps} />;
    case "pennant":
      return <Icons.Pennant {...iconProps} />;
    case "person-dolly":
      return <Icons.PersonDolly {...iconProps} />;
    case "phone":
      return <Icons.Phone {...iconProps} />;
    case "plus":
      return <Icons.Plus {...iconProps} />;
    case "project-diagram":
      return <Icons.ProjectDiagram {...iconProps} />;
    case "projector":
      return <Icons.Projector {...iconProps} />;
    case "puzzle-piece":
      return <Icons.PuzzlePiece {...iconProps} />;
    case "question":
    case "question-circle":
      return <Icons.QuestionCircle {...iconProps} />;
    case "rabbit-fast":
      return <Icons.RabbitFast {...iconProps} />;
    case "rocket-launch":
      return <Icons.RocketLaunch {...iconProps} />;
    case "route":
      return <Icons.Route {...iconProps} />;
    case "ruler-triangle":
      return <Icons.RulerTriangle {...iconProps} />;
    case "search":
      return <Icons.Search {...iconProps} />;
    case "seedling":
      return <Icons.Seedling {...iconProps} />;
    case "sign-out":
      return <Icons.SignOut {...iconProps} />;
    case "slash":
      return <Icons.Slash {...iconProps} />;
    case "solar-system":
      return <Icons.SolarSystem {...iconProps} />;
    case "square":
      return <Icons.Square {...iconProps} />;
    case "sword":
      return <Icons.Sword {...iconProps} />;
    case "table":
      return <Icons.Table {...iconProps} />;
    case "trash":
    case "trash-alt":
      return <Icons.TrashAlt {...iconProps} />;
    case "umbrella":
      return <Icons.Umbrella {...iconProps} />;
    case "undo-alt":
      return <Icons.UndoAlt {...iconProps} />;
    case "user-alt-solid":
      return <Icons.UserAltSolid {...iconProps} />;
    case "user":
    case "user-alt":
      return <Icons.UserAlt {...iconProps} />;
    case "user-crown-solid":
      return <Icons.UserCrownSolid {...iconProps} />;
    case "user-crown":
      return <Icons.UserCrown {...iconProps} />;
    case "users-class-solid":
      return <Icons.UsersClassSolid {...iconProps} />;
    case "users-class":
      return <Icons.UsersClass {...iconProps} />;
    case "user-slash":
      return <Icons.UserSlash {...iconProps} />;
    case "users-solid":
      return <Icons.UsersSolid {...iconProps} />;
    case "users":
      return <Icons.Users {...iconProps} />;
    case "window-close":
      return <Icons.WindowClose {...iconProps} />;
    default:
      return <span className={className} style={style}></span>;
  }
};
