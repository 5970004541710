import { ENDPOINT_PEOPLEKEYS_INITIALIZE_URL } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PEOPLEKEYS_INITIALIZE_URL;

export const PeopleKeysInitialize = {
  post(
    params: Endpoints.Tyto.PeopleKeys.Initialize.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, { ...params }, callOpts);
  }
};
