import { ENDPOINT_SESSION_CHECK } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_SESSION_CHECK;

export const AccountSession = {
  get(params: Endpoints.Tyto.AccountSession.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, params || {}, callOpts);
  }
};
