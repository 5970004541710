/*
 * Component Description
 */
import * as React from "react";

// import { StoreContext } from "../../../data/stores/contexts/AppStore";
import { TOGGLE_SECTION_BLOCKS } from "../../../data/constants/";
import {
  Button,
  DISC,
  DISCPlotGraph,
  DISCValueRender,
  ToggleSection,
} from "../../../components/common/";
import { getSectionWidth } from "../../../data/utils/helpers/";

import { Tyto } from "../../../typings/tyto";

import "./PersonDISCGraph.scss";

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discData: Tyto.DISCInfo;
  discMini: Tyto.DISCProfileMini;
  personName: string;
}

export default ({ discCompareProfile, discMini, personName }: Props) => {
  const [showClassic, updateShowClassic] = React.useState(false);

  return (
    <ToggleSection
      className=""
      mixpanelKey={TOGGLE_SECTION_BLOCKS.Person.DISC_GRAPH}
      title={`${personName} Profile`}
      renderFunction={(isExpanded, sectionWrapperRef) => {
        const [sectionWrapperWidth, updateSectionWrapperWidth] = React.useState(
          getSectionWidth(sectionWrapperRef)
        );
        React.useEffect(() => {
          updateSectionWrapperWidth(getSectionWidth(sectionWrapperRef));
        }, [sectionWrapperRef]);

        if (!isExpanded) {
          return <span style={{ visibility: "hidden" }} />;
        }

        return (
          <>
            <p className="person-disc-graph-keywords">
              <DISCValueRender
                // className="meta-sidepane-person-renderer-wrapper"
                compareProfile={discCompareProfile}
                isCompareValue={true}
                omitHiddenMsg={true}
                targetKey="style.styleTraits"
                discMiniProfile={discMini}
              >
                {discCompareProfile &&
                  discCompareProfile.style &&
                  discCompareProfile.style.styleTraits}
              </DISCValueRender>
            </p>

            <DISCValueRender
              className="meta-sidepane-person-renderer-wrapper"
              isCompareValue={false}
              targetKey="d3"
              discMiniProfile={discMini}
            >
              <div
                className="person-disc-graph-graph-cont"
                style={{
                  minHeight: sectionWrapperWidth
                    ? (sectionWrapperWidth * 275) / 296 + 20
                    : 250,
                }}
              >
                {sectionWrapperWidth &&
                  (showClassic ? (
                    <DISC profile={discMini} size={sectionWrapperWidth} />
                  ) : (
                    <DISCPlotGraph
                      groupStyle={[
                        {
                          d: discMini.d3,
                          i: discMini.i3,
                          s: discMini.s3,
                          c: discMini.c3,
                          personName: discMini.personName,
                        },
                      ]}
                      size={sectionWrapperWidth}
                    />
                  ))}
              </div>

              <div className="person-disc-graph-toggle-btn-cont">
                <Button
                  className="person-disc-graph-toggle-btn"
                  value={
                    showClassic ? "Toggle Line Graph" : "Toggle Classic View"
                  }
                  onClick={() => updateShowClassic(!showClassic)}
                  type="hollow"
                />
              </div>
            </DISCValueRender>
          </>
        );
      }}
    />
  );
};
