import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M634 471L36 3.5C29.1-2 19-.9 13.5 6l-10 12.5C-2 25.4-.9 35.5 6 41l598 467.5c6.9 5.5 17 4.4 22.5-2.5l10-12.5c5.5-6.9 4.4-17-2.5-22.5zM320 48c52.9 0 96 43.1 96 96 0 28.1-12.4 53.3-31.8 70.8l38.4 30c25.5-26 41.4-61.5 41.4-100.8C464 64.5 399.5 0 320 0c-51.9 0-97 27.7-122.4 68.9l38.2 29.9C252.1 68.7 283.5 48 320 48zM144 464v-25.6c0-47.6 38.8-86.4 86.4-86.4 4.2 0 9.5 1.2 16.4 3.6 23.7 8.3 48.3 12.4 73.2 12.4 8 0 15.9-1.1 23.8-2l-66.4-51.9c-4.9-1.3-10-2.2-14.8-3.8-10.4-3.6-21.2-6.2-32.2-6.2C156.2 304 96 364.2 96 438.4V464c0 26.5 21.5 48 48 48h352c9.3 0 17.9-2.8 25.2-7.3l-52-40.7H144z"
      />
    </svg>
  );
};
