/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../typings/tyto";
import { webAppFullProfileUrl } from "../../data/utils/webapp/";
import {
  Checkbox,
  Icon,
  TextButton,
  UserThumb,
  UserThumbPlaceholder,
} from "../../components/common/";
import {
  RecipientsProps,
  StoreContext,
} from "../../data/stores/contexts/AppStore";
import { clearSessionData, clearRecentlyRetrieved, setViewAsDarkMode } from "../../data/utils/";
import pkg from "../../../package.json";

import ResetPasword from "./subcomponents/Reset-Password";

import "./style.scss";

interface Props {}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);

  const sessionKey = _.get(AppStore, "state.sessionData.sessionKey", "");
  const [clearingRecents, updateClearingRecents] = React.useState(false);
  const loggedInUserID = _.get(AppStore, "state.loggedInUserID", undefined);
  const personEmail: string = _.get(
    AppStore,
    `state.personIDEmailMap[${loggedInUserID}]`,
    ""
  );
  const discMinis = _.get(AppStore, "state.discMini");
  const userDiscMini: Tyto.DISCProfileMini = _.get(
    AppStore,
    "state.personalDiscMini",
    _.get(discMinis, personEmail.toLowerCase(), undefined)
  );
  const [showResetPassword, updateShowResetPassword] = React.useState(false);
  const [loggingOut, updateLoggingOut] = React.useState(false);
  const permitAsJSON = JSON.stringify(
    _.get(AppStore, "state.loggedInUserPermit", {})
  );
  const isDarkMode = !!_.get(AppStore, "state.IS_DARK_MODE", false);

  return (
    <div className="main">
      <section className="interface-section settings-main-cont">
        <Icon
          className="settings-close-button"
          isButton={true}
          buttonProps={{
            className: "settings-close-button-cont",
            disabled: loggingOut,
          }}
          icon="close"
          onClick={() => {
            if (AppStore.dispatch) {
              AppStore.dispatch({
                payload: {},
                type: "TOGGLE_SHOW_SETTINGS",
              });
            }
          }}
          size={20}
        />

        <p className="settings-version-number">
          App Version{" "}
          <span className="settings-version-number-actual">
            {_.get(pkg, "version", "Unkown")}
          </span>{" "}
          | Office API Version{" "}
          <span className="settings-version-number-actual">
            {_.get(AppStore, "state.officeVersion", "1.1")}
          </span>
        </p>

        {!!loggedInUserID && (
          <div className="settings-top-cont">
            {userDiscMini ? (
              <UserThumb
                className="settings-top-userthumb"
                asset={userDiscMini.profileImageAsset}
                size={100}
                discMiniProfile={userDiscMini}
                discValues={{
                  d: userDiscMini.d3,
                  i: userDiscMini.i3,
                  s: userDiscMini.s3,
                  c: userDiscMini.c3,
                }}
                userName={userDiscMini.personName || ""}
              />
            ) : (
              <UserThumbPlaceholder
                className="settings-top-userthumb"
                size={100}
              />
            )}

            <div className="settings-top-info-cont">
              <h1 className="settings-top-username title-bold">
                {userDiscMini ? userDiscMini.personName : ""}
              </h1>
              <p className="settings-top-jobtitle">
                {userDiscMini ? userDiscMini.jobTitle : ""}
              </p>
            </div>
          </div>
        )}

        <ul className="settings-list">
          {!loggedInUserID ? (
            <li
              className="settings-list-item"
              style={{ animationDuration: "480ms" }}
            >
              <p style={{ color: "#ffffff", margin: "0px" }}>
                Whoops! Something went awry. Try to relaunching this Task Pane.
              </p>
            </li>
          ) : (
            <>
              <li
                className="settings-list-item"
                style={{ animationDuration: "480ms" }}
              >
                <div className="settings-list-item-icon-cont">
                  <Icon
                    className="settings-list-item-icon"
                    size={20}
                    icon="eye"
                  />
                </div>

                <TextButton
                  className="settings-item-button"
                  disabled={loggingOut}
                  onClick={() => updateShowResetPassword(!showResetPassword)}
                  value="Reset Password"
                />

                {showResetPassword && (
                  <ResetPasword
                    onCancel={() => updateShowResetPassword(false)}
                    onUpdate={() => {
                      if (AppStore.dispatch) {
                        AppStore.dispatch({
                          payload: {},
                          type: "CLEAR_USER_SESSION_DATA",
                        });
                      }
                    }}
                    // onUpdate={() => updateShowResetPassword(false)}
                  />
                )}
              </li>

              <li
                className="settings-list-item"
                style={{ animationDuration: "540ms" }}
              >
                <div className="settings-list-item-icon-cont">
                  <Icon
                    className="settings-list-item-icon"
                    size={20}
                    icon="user-alt"
                  />
                </div>

                <TextButton
                  className="settings-item-button"
                  disabled={loggingOut}
                  onClick={() => {
                    window.open(
                      webAppFullProfileUrl({
                        personID: loggedInUserID,
                        sessionKey,
                        subInterface: "tryyb-profile",
                      })
                    );
                  }}
                  value="Account"
                />
              </li>
            </>
          )}

          <li
            className="settings-list-item"
            style={{ animationDuration: "600ms" }}
          >
            <div className="settings-list-item-icon-cont">
              <Icon
                className="settings-list-item-icon"
                size={20}
                icon="user-slash"
              />
            </div>

            <TextButton
              className="settings-item-button"
              disabled={clearingRecents}
              onClick={() => {
                updateClearingRecents(true);

                clearRecentlyRetrieved(() => {
                  updateClearingRecents(false);
                });
              }}
              value={clearingRecents ? "Clearing..." : "Clear Recents List"}
            />
          </li>

          <li
            className="settings-list-item"
            style={{ animationDuration: "600ms" }}
          >
            <div className="settings-list-item-icon-cont">
              <Icon
                className="settings-list-item-icon"
                size={20}
                icon="sign-out"
              />
            </div>

            <TextButton
              className="settings-item-button"
              onClick={() => {
                startLogout(AppStore);
                // AppStore.dispatch({
                //   payload: {},
                //   type: "CLEAR_USER_SESSION_DATA"
                // });
              }}
              value={loggingOut ? "Logging out..." : "Logout"}
            />
          </li>
        </ul>

        <div className="settings-darkmode-toggle-cont">
          <Checkbox
            className="settings-darkmode-toggle"
            checked={isDarkMode}
            onCheck={newVal => {
              setViewAsDarkMode(newVal, () => {
                if (AppStore.dispatch) {
                  AppStore.dispatch({
                    payload: {
                      isDarkMode: newVal
                    },
                    type: "UPDATE_DARK_MODE"
                  })
                }
              });
            }}
            size={16}
          />
        
        <TextButton
          className="settings-darkmode-textbutton"
          onClick={() => {
            setViewAsDarkMode(!isDarkMode, () => {
              if (AppStore.dispatch) {
                AppStore.dispatch({
                  payload: {
                    isDarkMode: !isDarkMode
                  },
                  type: "UPDATE_DARK_MODE"
                })
              }
            });
          }}
          value="Dark Mode"
        />
        </div>
      </section>
    </div>
  );
};

const startLogout = async (AppStore: RecipientsProps) => {
  try {
    clearSessionData(() => {
      if (AppStore.dispatch) {
        AppStore.dispatch({
          payload: {},
          type: "CLEAR_USER_SESSION_DATA",
        });
      }
    });
  } catch (err) {
    console.log("Error occurred while attempting to clear session and logout");
  }
};
