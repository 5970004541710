/*
 * Component Description
 */
import * as React from "react";

import "./style.scss";

interface Props {}

export default (props: Props) => {
  return (
    <section className="nopermission-main-cont">
      <div className="nopermission-banner-img-cont">
        <img className="nopermission-banner-img" src="/work-from-home.jpg" />
      </div>

      <h1 className="nopermission-title">Welcome Back.</h1>

      <p className="nopermission-text">
        For now, you are waiting for your peers to complete their assessments.
        <br />
        <br />
        You'll get a notification when your trainer is ready to move forward and
        unlock your account.
      </p>
    </section>
  );
};
