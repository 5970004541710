/*
 * Component Description
 */
import * as React from "react";

import { Button, Message, UserThumb } from "../../../components/common/";

import "./PersonNavCheck.scss";
import { Tyto } from "../../../typings/tyto";

interface Props {
  color: string;
  cancelText: string;
  confirmText: string;
  discMini: Tyto.DISCProfileMini;
  help?: {
    title: string;
    childrenRenderFnc: () => any;
  };
  href?: string;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  subMessage?: string;
  type?: "trigger" | "link";
}

export default ({
  color,
  cancelText,
  confirmText,
  discMini,
  onCancel,
  onConfirm,
  help,
  href,
  message,
  subMessage,
  type = "trigger",
}: Props) => {
  return (
    <section className="full-screen-wrapper" style={{ background: color }}>
      {discMini && (
        <UserThumb
          className="full-screen-userthumb"
          asset={discMini.profileImageAsset}
          discMiniProfile={discMini}
          discValues={{
            d: discMini.d3,
            i: discMini.i3,
            s: discMini.s3,
            c: discMini.c3,
          }}
          size={125}
          userName={discMini.personName}
        />
      )}

      <Message className="full-screen-wrapper-msg" value={message} />

      {subMessage && (
        <Message className="full-screen-wrapper-submsg" value={subMessage} />
      )}

      <div className="full-screen-wrapper-btns-cont">
        <Button
          className="full-screen-wrapper-btn"
          onClick={onCancel}
          shape="square"
          type="hollow"
          value={cancelText || "Cancel"}
        />

        {type === "link" && !!href ? (
          <a className="full-screen-wrapper-btn-link" href={href}>
            <Button
              className="full-screen-wrapper-btn"
              onClick={onConfirm}
              shape="square"
              type="color"
              value={confirmText || "Yes"}
            />
          </a>
        ) : (
          <Button
            className="full-screen-wrapper-btn"
            onClick={onConfirm}
            shape="square"
            type="color"
            value={confirmText || "Yes"}
          />
        )}
      </div>

      {help && help.title && help.childrenRenderFnc && (
        <>
          <h3 className="full-screen-wrapper-help-section-title">
            Related Help
          </h3>

          <div className="full-screen-wrapper-help-cont">
            <details className="full-screen-wrapper-help-details">
              <summary className="full-screen-wrapper-help-title">
                {help.title}
              </summary>
              <span className="full-screen-wrapper-help-text">
                {help.childrenRenderFnc()}
              </span>
            </details>
          </div>
        </>
      )}
    </section>
  );
};
