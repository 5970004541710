/*
 * Component Description
 */
import * as React from "react";

import {
  PlaceholderText,
  Message,
  ToggleSection
} from "../../../components/common/";

interface Props {}

export default (props: Props) => {
  return (
    <ToggleSection
      className=""
      title=""
      renderFunction={() => {
        return (
          <div style={{ minHeight: "150px" }}>
            {/* <PlaceholderText value="Placeholder Name" />

            <PlaceholderText value="Placeholder Name Bfdhsgj fdls" />

            <PlaceholderText value="Placeholder" /> */}
          </div>
        );
      }}
    />
  );
};
