/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon, { IconProps } from "../icon/";

import "./style.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  iconProps?: IconProps;
  onClick: () => void;
  style?: React.CSSProperties;
  value: string;
}

export default (props: Props) => {
  return (
    <button
      className={cx("cc-textbutton", props.className)}
      disabled={props.disabled}
      style={props.style}
      onClick={props.onClick}
    >
      {props.iconProps && <Icon {...props.iconProps} />}

      {props.value}
    </button>
  );
};
