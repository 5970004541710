// * URLs
export const URL_BASE = "teamtoolsdemo.mocaworks.com";
export const API_URL = `https://${URL_BASE}/tyto/api`;
export const SENTRY_DSN = "https://c4625d1f2d9044949a4d3cea28d78c1c@o486049.ingest.sentry.io/5542247";

// * Web App URLs
export const WEB_APP_ASSESSMENT_URI = "/take-assessment";
export const WEB_APP_PROFILE_URI = "/profile/";
export const WEB_APP_SESSION_CHECK_URI = "/session-check/";
export const WEB_APP_HOME_URI = "/";
export const WEB_APP_GROUPS_PREVIEW_URI = "/groups/preview";
export const WEB_APP_BASE_URL = "https://app.teamtools.com";
export const WEB_APP_OUTSIDE_SESSION_KEY_PARAM_KEY = "outsideSessionKey";

// * Endpoints
export const ENDPOINT_DISC_PROFILES = `${API_URL}/DiscProfiles`;
export const ENDPOINT_DISC_PROFILES_MINI = `${API_URL}/DiscProfiles/Mini`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE = `${API_URL}/DiscProfile/Interactive`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE_MOCKUP = `${API_URL}/DiscProfile/Interactive/Mockup`;
export const ENDPOINT_DISC_PROFILE_TEAM = `${API_URL}/DiscProfile/Team`;
export const ENDPOINT_LOGIN_URL = `${API_URL}/Login/Authenticate`;
export const ENDPOINT_LOGIN4_URL = `${API_URL}/Login/Authenticate4`;
export const ENDPOINT_SESSION_CHECK = `${API_URL}/AccountSession`;
export const ENDPOINT_TEAM_INFO = `${API_URL}/TeamsByFunction`;
export const ENDPOINT_PEOPLEKEYS_INITIALIZE_URL = `${API_URL}/PeopleKeys/Initialize`;
export const ENDPOINT_PEOPLEKEYS_PAGES_URL = `${API_URL}/PeopleKeys/Pages`;
export const ENDPOINT_PEOPLEKEYS_NEXTPAGE_URL = `${API_URL}/PeopleKeys/NextPage`;
export const ENDPOINT_PEOPLEKEYS_REPORT_URL = `${API_URL}/PeopleKeysCallback/report`;
export const ENDPOINT_PEOPLEKEYS_RESPONSE_URL = `${API_URL}/PeopleKeys/Response`;
export const ENDPOINT_PERSON_GET = `${API_URL}/Person`;
export const ENDPOINT_PERSON_GET_ADVANCED = `${API_URL}/People/AdvancedSearch`;
export const ENDPOINT_PERSON_MYPASSWORD_URL = `${API_URL}/Person/MyPassword`;

// * Office Storage (roamingData)
export const SESSION_DATA_KEY = "team-tools-sessionData";
export const RECENTLY_RETRIEVED_PEOPLE = "team-tools-recently-retrieved";
export const TEAM_TOOLS_STORAGE_KEY = "::team-tools-outlook-data::";
export const STORED_PERSONAL_DISC_MINI = "team-tools-person-discmini";
export const VIEW_AS_DARK_MODE = "team-tools-view-as-darkmode";

// * DISC Colors

// * [1] - First Colors
// export const DISC_COLOR_D = "#bd487d";
// export const DISC_COLOR_I = "#3bb2eb";
// export const DISC_COLOR_S = "#6ab36b";
// export const DISC_COLOR_C = "#ffc52c";

// * [2] - Hakha's Colors
export const DISC_COLOR_D = "#af4986";
export const DISC_COLOR_I = "#2597d4";
export const DISC_COLOR_S = "#67b26a";
export const DISC_COLOR_C = "#fec42c";
export const DISC_COLOR_D_DARK = "#8f3688";
export const DISC_COLOR_I_DARK = "#1073c7";
export const DISC_COLOR_S_DARK = "#0d7746";
export const DISC_COLOR_C_DARK = "#f89a30";

// * Misc
export const GRADIENT_DIFF_PERCENT_WIDTH = 18;
export const SAVE_ROAMING_DATA_TIMEOUT_MS = 5000;
export const INTERFACE_CONTEXT_PARAM_KEY = "interfacecontext";
export const DEFAULT_INTERFACE_CONTEXT_VALUE = "MSGCOMPOSE";
export const DEFAULT_LOCAL_STORAGE_EXPIRATION_TIME_MS = 86400 * 1000; // * 1 Day in MS

// * Toggle Section Block Names
export const TOGGLE_SECTION_BLOCKS = {
  Person: {
    COMM_TIPS: "TS_BLOCK_PERSON_COMM_TIPS",
    COMPARE_GRAPH: "TS_BLOCK_PERSON_COMPARE_GRAPH",
    DISC_GRAPH: "TS_BLOCK_PERSON_DISC_GRAPH",
    INTERACTIONS: "TS_BLOCK_PERSON_INTERACTIONS",
    MOTIVATORS: "TS_BLOCK_PERSON_MOTIVATORS",
  },
  Team: {
    AVERAGE_PERSONALITY: "TS_BLOCK_TEAM_AVERAGE_PERSONALITY",
    GROUP_STYLE: "TS_BLOCK_TEAM_GROUP_STYLE",
    PEOPLE: "TS_BLOCK_TEAM_PEOPLE",
    TEAM_TIPS: "TS_BLOCK_TEAM_TEAM_TIPS",
    TEAM_TIPS_MEETING: "TS_BLOCK_TEAM_TEAM_MEETING_TIPS",
    TEAM_TIPS_DM: "TS_BLOCK_TEAM_TEAM_TIPS_DECISION_MAKING",
    TEAM_TIPS_STRENGTHS: "TS_BLOCK_TEAM_TEAM_TIPS_STRENGTHS",
    TEAM_TIPS_CHALLENGES: "TS_BLOCK_TEAM_TEAM_TIPS_CHALLENGES",
  },
};

// * Action Types
