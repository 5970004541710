/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { triggerMixPanelEvent } from "../../../data/logging/";
import Icon from "../icon/";

import "./style.scss";

interface Props {
  className: string;
  hideChildrenWhenToggled?: boolean;
  onLinkClick?: () => void;
  renderFunction: (
    isExpanded: boolean,
    sectionContRef: React.MutableRefObject<null>
  ) => React.ReactNode;
  style?: React.CSSProperties;
  title?: string;
  mixpanelKey?: string;
  closedAtStart?: boolean;
}

export const ToggleSection = (props: Props) => {
  const sectionContRef = React.useRef(null);
  const [isExpanded, updateIsExpanded] = React.useState(!props.closedAtStart);

  return (
    <section
      className={cx("cc-toggle-section", props.className)}
      ref={sectionContRef}
    >
      {props.title && (
        <h3 className="cc-toggle-section-title">{props.title}</h3>
      )}

      {!isExpanded && props.hideChildrenWhenToggled ? (
        <span style={{ visibility: "hidden" }} />
      ) : (
        props.renderFunction(isExpanded, sectionContRef)
      )}

      <Icon
        buttonProps={{
          className: "cc-toggle-section-toggle-btn",
        }}
        onClick={() => {
          updateIsExpanded(!isExpanded);

          _logSectionToggle({
            mixpanelKey: props.mixpanelKey,
            newToggledState: isExpanded,
            wasClosedAtStart: props.closedAtStart,
          });
        }}
        icon={isExpanded ? "minus" : "plus"}
        isButton={true}
        size={20}
      />
    </section>
  );
};

const _logSectionToggle = ({
  mixpanelKey,
  newToggledState,
  wasClosedAtStart,
}: {
  mixpanelKey?: string;
  newToggledState: boolean;
  wasClosedAtStart?: boolean;
}) => {
  if (!mixpanelKey) {
    return;
  }

  const toggleStateSemantic = newToggledState ? "open" : "closed";

  triggerMixPanelEvent("SIDEPANE_BLOCK_TOGGLED", {
    blockType: mixpanelKey,
    newToggledState: toggleStateSemantic,
    wasClosedAtStart: wasClosedAtStart === undefined ? null : wasClosedAtStart,
  });
};

export default ToggleSection;
