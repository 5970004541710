/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import {
  UserThumbPlaceholder,
  PlaceholderText
} from "../../../components/common/index";

import "../subcomponents/PersonHeader.scss";

interface Props {}

export default (props: Props) => {
  return (
    <section className="person-header-cont">
      <div className="person-header-cont-images-cont">
        <UserThumbPlaceholder className="person-header-userthumb" size={150} />

        <div>
          {/* <div
            className="person-header-disc-profile-icon"
            style={{
              height: "65px",
              width: "65px",
              minHeight: "65px",
              minWidth: "65px",
              borderRadius: "50%"
            }}
          /> */}
          <UserThumbPlaceholder className="person-header-userthumb" size={65} />

          <p className="person-header-disc-profile-title">
            <PlaceholderText value="Style Name" />
          </p>
        </div>
      </div>

      <h1 className="person-header-name">
        <PlaceholderText value="Placeholder Name" />
      </h1>

      <p className="person-header-jobtitle">
        <PlaceholderText value="Placeholder Job Title" />
      </p>
    </section>
  );
};
