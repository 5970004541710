import * as _ from "lodash";
import QueryString from "query-string";

import pkg from "../../../package.json";
import TytoCalls from "../tyto/";

import {
  DEFAULT_INTERFACE_CONTEXT_VALUE,
  INTERFACE_CONTEXT_PARAM_KEY,
  RECENTLY_RETRIEVED_PEOPLE,
  SAVE_ROAMING_DATA_TIMEOUT_MS,
  SESSION_DATA_KEY,
  STORED_PERSONAL_DISC_MINI,
  TEAM_TOOLS_STORAGE_KEY,
  VIEW_AS_DARK_MODE
} from "../constants";
import { AuthResult, SessionData, InterfaceContext } from "../../typings";
import {
  getItem as getLocalStorageItem,
  setItem as setLocalStorageItem,
} from "../storage/utils";

import { Tyto } from "../../typings/tyto";

const appBrand = "team-tools-outlook";
const appVersion = _.get(pkg, "version", "");

let saveKey: null | NodeJS.Timeout;

export function awaitOfficeLoad(callback: () => void, rej: () => void) {
  if (!Office || !Office.onReady) {
    rej();
  } else {
    try {
      Office.onReady((info) => {
        if (info.platform === null) {
          rej();
        } else {
          callback();
        }
      });
    } catch (err) {
      rej();
    }
  }
}

// export function getSessionKey(): string {
//   return "";
// }

export function clearSessionData(callback?: () => void) {
  sessionStorage.setItem(SESSION_DATA_KEY, "");

  const sessionData = sessionStorage.getItem(SESSION_DATA_KEY);
  console.log("SESSION DATA CLEARED - VALUE NOW: ", sessionData);

  Office.context.roamingSettings.set(SESSION_DATA_KEY, sessionData);

  Office.context.roamingSettings.saveAsync(() => {
    console.log("Session Roaming Data saved");
    if (callback) {
      callback();
    }
  });
}

export function clearRecentlyRetrieved(callback: () => void) {
  Office.context.roamingSettings.set(RECENTLY_RETRIEVED_PEOPLE, undefined);
  Office.context.roamingSettings.saveAsync(() => callback());
}

export function getSessionData(): SessionData | undefined {
  const sessionData = sessionStorage.getItem(SESSION_DATA_KEY);
  console.log("SESSION DATA: ", sessionData);

  if (!sessionData || typeof sessionData !== "string") {
    return undefined;
  }

  const parsedSessionData = JSON.parse(sessionData);

  return parsedSessionData as SessionData;
}

export function getSessionKey(): string {
  // ! Needs to switch to sessionData if that is desired
  const sessionData = getRoamingSettingsSessionData();
  // const sessionData = getSessionData();

  if (!sessionData || typeof sessionData !== "object") {
    return "";
  }

  return sessionData.sessionKey || "";
}

// export async function login(email: string, password: string) {
//   try {
//     const info = await TytoCalls.LoginAuthenticate.post({
//       username: email,
//       password
//     });

//     return info;
//   } catch (err) {
//     debugger;
//     return {
//       error: {
//         msg: err
//       },
//       session: undefined
//     };
//   }
// }

export function login(
  email: string,
  password: string,
  domain?: string
): Promise<{
  error?: any;
  authResults: AuthResult[];
}> {
  return new Promise((res, rej) => {
    const timeOutMinutes = 60 * 24 * 90;

    TytoCalls.Login.Authenticate4.post({
      domain,
      username: email,
      password,
      appBrand,
      appVersion,
      timeOutMinutes,
    })
      .then((info) => {
        res(info);
      })
      .catch((err) => {
        res({
          error: err,
          authResults: []
        });
      });

    // TytoCalls.LoginAuthenticate.post({
    //   domain,
    //   username: email,
    //   password,
    //   appBrand,
    //   appVersion,
    //   timeOutMinutes,
    // })
    //   .then((info) => {
    //     res(info);
    //   })
    //   .catch((err) => {
    //     res({
    //       error: err,
    //       session: undefined,
    //     });
    //   });
  });
}

export function addHandler(
  callback: (recipients: any[]) => void,
  rej: (reason: string) => void
) {
  if (!_.get(Office, "context.mailbox.item.addHandlerAsync", undefined)) {
    rej("addHandlerAsync non-existant");
    callback([]);
    return;
  }

  Office.context.mailbox.item.addHandlerAsync(
    "olkRecipientsChanged",
    ({
      changedRecipientFields,
    }: {
      changedRecipientFields: { bcc: boolean; cc: boolean; to: boolean };
      type: string;
    }) => {
      if (changedRecipientFields.cc || changedRecipientFields.to) {
        getRecipientsWithCallback()
          .then((people) => callback(people))
          .catch((err) => {
            debugger;
          });
      }
    }
  );
}

export function addAppointmentHandler(
  callback: (recipients: any[]) => void,
  rej?: (reason: string) => void
) {
  if (!_.get(Office, "context.mailbox.item.addHandlerAsync", undefined)) {
    if (rej) {
      rej("addHandlerAsync non-existant");
    }
    callback([]);
    return;
  }

  // * Item.RecipientChanged
  Office.context.mailbox.item.addHandlerAsync(
    Office.EventType.RecipientsChanged,
    ({
      changedRecipientFields,
    }: {
      changedRecipientFields: { bcc: boolean; cc: boolean; to: boolean };
      type: string;
    }) => {
      // debugger;
      if (changedRecipientFields.cc || changedRecipientFields.to) {
        getRecipientsWithCallback()
          .then((people) => callback(people))
          .catch((err) => {
            debugger;
          });
      }
    },
    {},
    (data: any) => {
      debugger;
    }
  );
  // // * ItemChanged
  // Office.context.mailbox.addHandlerAsync(
  //   Office.EventType.ItemChanged,
  //   (change: any) => {
  //     debugger;
  //     if (change) {
  //       getRecipientsWithCallback()
  //         .then(people => callback(people))
  //         .catch(err => {
  //           debugger;
  //         });
  //     }
  //   }
  // );
  // // * Settings Changed
  // Office.context.mailbox.addHandlerAsync(
  //   Office.EventType.SettingsChanged,
  //   (change: any) => {
  //     debugger;
  //     if (change) {
  //       getRecipientsWithCallback()
  //         .then(people => callback(people))
  //         .catch(err => {
  //           debugger;
  //         });
  //     }
  //   }
  // );
  // // * Appointment Time Changed
  // Office.context.mailbox.item.addHandlerAsync(
  //   Office.EventType.AppointmentTimeChanged,
  //   (change: any) => {
  //     debugger;
  //     if (change) {
  //       getRecipientsWithCallback()
  //         .then(people => callback(people))
  //         .catch(err => {
  //           debugger;
  //         });
  //     }
  //   },
  //   {},
  //   (data: any) => {
  //     debugger;
  //   }
  // );
}

export function addInboxHandler(callback: (recipients: any[]) => void) {
  if (!_.get(Office, "context.mailbox.addHandlerAsync", undefined)) {
    callback([]);
    return;
  }

  Office.context.mailbox.addHandlerAsync(
    Office.EventType.ItemChanged,
    (change) => {
      const initialData: any = _.get(change, "initialData", {});

      if (initialData && typeof initialData === "object" && initialData.from) {
        const cc = Array.isArray(initialData.cc) ? initialData.cc : [];
        const to = Array.isArray(initialData.to) ? initialData.to : [];

        const ccFormatted = cc.map((recipient: any) => ({
          emailAddress: recipient.address,
          displayName: recipient.name,
        }));
        const toFormatted = to.map((recipient: any) => ({
          emailAddress: recipient.address,
          displayName: recipient.name,
        }));

        callback([
          {
            emailAddress: initialData.from.address,
            displayName: initialData.from.name,
          },
          ...(toFormatted as Office.EmailAddressDetails[]),
          ...(ccFormatted as Office.EmailAddressDetails[]),
        ]);
      }
    }
  );

  // Office.context.mailbox.item.addHandlerAsync(
  //   "olkRecipientsChanged",
  //   ({
  //     changedRecipientFields
  //   }: {
  //     changedRecipientFields: { bcc: boolean; cc: boolean; to: boolean };
  //     type: string;
  //   }) => {
  //     if (changedRecipientFields.cc || changedRecipientFields.to) {
  //       getInboxRecipientInfo()
  //         .then(people => callback(people))
  //         .catch(err => {
  //           debugger;
  //         });
  //     }
  //   }
  // );
}

export async function getRecipientsWithCallback() {
  try {
    const recipients = await Promise.all([
      getCCRecipients(),
      getToRecipients(),
    ]);

    // return [{ emailAddress: "gotthisfar@gmail.com" }];
    return _.flatten(recipients);
  } catch (err) {
    return [];
  }
}

export function getRoamingSettingsSessionData(): SessionData | undefined {
  try {
    if (!_.get(Office, "context.roamingSettings.get", undefined)) {
      return undefined;
    }

    const sessionData = Office.context.roamingSettings.get(SESSION_DATA_KEY);
    // const sessionData = localStorage.getItem(
    //   `${TEAM_TOOLS_STORAGE_KEY}::sessionData`
    // );

    // const parsedSessionData = sessionData ? JSON.parse(sessionData) : undefined;

    // return parsedSessionData as SessionData | undefined;
    return sessionData;
  } catch (err) {
    return undefined;
  }
}

export async function getAppointmentAttendeesInfo() {
  try {
    const recipients = await Promise.all([
      getAppointmentRequiredAttendees(),
      getAppointmentOptionalAttendees(),
      getAppointmentOrganizer(),
    ]);

    // debugger;
    // return [{ emailAddress: "gotthisfar@gmail.com" }];
    return _.flatten(recipients);
  } catch (err) {
    return [];
  }
}

export async function getAppointmentComposeAttendeesInfo() {
  try {
    const recipients = await Promise.all([
      getAppointmentComposeRequiredAttendees(),
      getAppointmentComposeOptionalAttendees(),
      getAppointmentComposeOrganizer(),
    ]);

    // debugger;
    // return [{ emailAddress: "gotthisfar@gmail.com" }];
    return _.flatten(recipients);
  } catch (err) {
    return [];
  }
}

export async function getInboxRecipientInfo() {
  try {
    const recipients = await Promise.all([
      getInboxCCRecipients(),
      getInboxToRecipients(),
      getFromAddresses(),
    ]);

    // debugger;
    // return [{ emailAddress: "gotthisfar@gmail.com" }];
    return _.flatten(recipients);
  } catch (err) {
    return [];
  }
}

export function getAppointmentRequiredAttendees(): Promise<
  Office.EmailAddressDetails[]
> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.requiredAttendees")) {
      console.warn(
        "Expected 'mailbox.item.requiredAttendees' to be defined but was not."
      );
      res([]);
    }

    const attendees = Office.context.mailbox.item.requiredAttendees || [];
    // debugger;
    if (Array.isArray(attendees)) {
      res(attendees);
    } else {
      res([]);
    }
  });
}

export function getAppointmentComposeOptionalAttendees(): Promise<
  Office.EmailAddressDetails[]
> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.optionalAttendees")) {
      console.warn(
        "Expected 'mailbox.item.optionalAttendees' to be defined but was not."
      );
      res([]);
    }

    Office.context.mailbox.item.optionalAttendees.getAsync(
      (resp: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        console.log("CC getAsync resp: ", resp);
        // @ts-ignore
        if (resp && resp.status === "succeeded") {
          res(resp.value);
        } else {
          //   rej(undefined);
          res([]);
        }
      }
    );
  });
}

export function getAppointmentComposeRequiredAttendees(): Promise<
  Office.EmailAddressDetails[]
> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.requiredAttendees")) {
      console.warn(
        "Expected 'mailbox.item.requiredAttendees' to be defined but was not."
      );
      res([]);
    }

    Office.context.mailbox.item.requiredAttendees.getAsync(
      (resp: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        console.log("CC getAsync resp: ", resp);
        // @ts-ignore
        if (resp && resp.status === "succeeded") {
          res(resp.value);
        } else {
          //   rej(undefined);
          res([]);
        }
      }
    );
  });
}

export function getAppointmentOptionalAttendees(): Promise<
  Office.EmailAddressDetails[]
> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.optionalAttendees")) {
      console.warn(
        "Expected 'mailbox.item.optionalAttendees' to be defined but was not."
      );
      res([]);
    }

    const optionalAttendees =
      Office.context.mailbox.item.optionalAttendees || [];
    // debugger;
    if (Array.isArray(optionalAttendees)) {
      res(optionalAttendees);
    } else {
      res([]);
    }
  });
}

export function getAppointmentOrganizer(): Promise<
  Office.EmailAddressDetails[]
> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.organizer")) {
      console.warn(
        "Expected 'mailbox.item.organizer' to be defined but was not."
      );
      res([]);
    }

    const organizer = Office.context.mailbox.item.organizer;
    // debugger;
    if (Array.isArray([organizer])) {
      res([organizer]);
    } else {
      res([]);
    }
  });
}

export function getAppointmentComposeOrganizer(): Promise<
  Office.EmailAddressDetails[]
> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.organizer")) {
      console.warn(
        "Expected 'mailbox.item.organizer' to be defined but was not."
      );
      res([]);
    }

    Office.context.mailbox.item.organizer.getAsync(
      (resp: Office.AsyncResult<Office.EmailAddressDetails>) => {
        console.log("CC getAsync resp: ", resp);
        // @ts-ignore
        if (resp && resp.status === "succeeded") {
          res([resp.value]);
        } else {
          //   rej(undefined);
          res([]);
        }
      }
    );
  });
}

export function getCCRecipients(): Promise<Office.EmailAddressDetails[]> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.cc")) {
      console.warn("Expected 'mailbox.item.cc' to be defined but was not.");
      res([]);
    }
    Office.context.mailbox.item.cc.getAsync(
      (resp: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        console.log("CC getAsync resp: ", resp);
        // @ts-ignore
        if (resp && resp.status === "succeeded") {
          res(resp.value);
        } else {
          //   rej(undefined);
          res([]);
        }
      }
    );
  });
}

export function getInboxCCRecipients(): Promise<Office.EmailAddressDetails[]> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.cc")) {
      console.warn("Expected 'mailbox.item.cc' to be defined but was not.");
      res([]);
    }
    const cc = Office.context.mailbox.item.cc;

    if (Array.isArray(cc)) {
      res(cc);
    } else {
      res([]);
    }
  });
}

export function getFromAddresses(): Promise<Office.EmailAddressDetails[]> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.from")) {
      console.warn("Expected 'mailbox.item.from' to be defined but was not.");
      res([]);
    }

    const from = Office.context.mailbox.item.from || [];
    // debugger;
    if (Array.isArray([from])) {
      res([from]);
    } else {
      res([]);
    }
  });
}

export function getToRecipients(): Promise<Office.EmailAddressDetails[]> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.to")) {
      console.warn("Expected 'mailbox.item.to' to be defined but was not.");
      res([]);
    }
    Office.context.mailbox.item.to.getAsync(
      (resp: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        console.log("To getAsync resp: ", resp);
        // TODO Fix typing - resp should be { status: string; value: Office.EmailAddressDetails[] }
        // @ts-ignore
        if (resp && resp.status === "succeeded") {
          res(resp.value);
        } else {
          //   rej(undefined);
          res([]);
        }
      }
    );
  });
}

export function getInboxToRecipients(): Promise<Office.EmailAddressDetails[]> {
  return new Promise((res, rej) => {
    if (!_.get(Office, "context.mailbox.item.cc")) {
      console.warn("Expected 'mailbox.item.cc' to be defined but was not.");
      res([]);
    }
    const to = Office.context.mailbox.item.to;

    if (Array.isArray(to)) {
      res(to);
    } else {
      res([]);
    }
  });
}

export async function loadPeople(emails: string[]): Promise<Tyto.Person[]> {
  try {
    // const sessionData = Office.context.roamingSettings.get(SESSION_DATA_KEY);
    const sessionData = getRoamingSettingsSessionData();
    const domainID = _.get(sessionData, "domainID");

    const calls = emails.map((email) =>
      TytoCalls.Person.get({ domainID, logonName: email })
    );

    const peopleData = (await Promise.all(calls)) as Array<Tyto.Person>;

    const personObjects = peopleData
      .map((personResp) => _.get(personResp, "person"))
      .filter((personObj) => !!personObj);

    return personObjects;
  } catch (err) {
    console.warn(
      "An error occured while loading people from Advanced Search: ",
      err
    );
    return [];
  }
}

export function loadRecentlyRetrieved(): Tyto.DISCProfileMini[] {
  try {
    const recentlyRetrieved = Office.context.roamingSettings.get(
      RECENTLY_RETRIEVED_PEOPLE
    );

    console.log("Recent Contacts From Raoming Data: ", recentlyRetrieved);
    if (!Array.isArray(recentlyRetrieved)) {
      return [];
    }

    return recentlyRetrieved;
  } catch (err) {
    console.warn("An error occured while loading Recent Contacts: ", err);
    return [];
  }
}

export function logout(callback: () => void) {
  // * TRIAL ==================================
  const sessionDataAsString = JSON.stringify(undefined);
  localStorage.setItem(
    `${TEAM_TOOLS_STORAGE_KEY}::sessionData`,
    sessionDataAsString
  );
  // * ========================================

  Office.context.roamingSettings.set(SESSION_DATA_KEY, undefined);
  Office.context.roamingSettings.set(RECENTLY_RETRIEVED_PEOPLE, undefined);
  Office.context.roamingSettings.saveAsync(() => callback());
}

async function validateSession(storedSessionData?: SessionData) {
  if (!storedSessionData) {
    return undefined;
  }

  try {
    const sessionCheck = await TytoCalls.AccountSession.get({
      params: { sessionKey: storedSessionData.sessionKey },
    });

    return sessionCheck;
  } catch (err) {
    return undefined;
  }
}

export async function retrieveStoredSession() {
  try {
    // * [1] Look in storage for session Data
    // const storedSessionData:
    //   | SessionData
    //   | undefined = Office.context.roamingSettings.get(SESSION_DATA_KEY);
    const storedSessionData:
      | SessionData
      | undefined = getRoamingSettingsSessionData();

    console.log("Stored SessionData: ", storedSessionData);

    // * [2] Check if session is "alive" and return sessionData if it is
    if (storedSessionData && storedSessionData.sessionKey) {
      const sessionCheck = await validateSession(storedSessionData);

      const status = _.get(sessionCheck, "error.sts", undefined);

      const sessionIsAlive = status === 0;

      if (sessionIsAlive) {
        return { session: storedSessionData };
      } else {
        return {
          failedReason:
            "Stored Session Information found, but session is expired.",
        };
      }
    }

    // * [3] If session is not found, or is not "alive" return undefined
    return { failedReason: "No Stored Session Information found." };
  } catch (err) {
    // debugger;
    return {
      failedReason:
        "An Error occurred while attempting to retrieve and validate a stored session",
    };
  }
}

export function setSessionData(
  sessionData: SessionData,
  callback?: () => void
) {
  if (sessionData) {
    // * TRIAL ==================================
    // const sessionDataAsString = JSON.stringify(sessionData);
    // localStorage.setItem(
    //   `${TEAM_TOOLS_STORAGE_KEY}::sessionData`,
    //   sessionDataAsString
    // );
    // * ========================================

    if (_.get(Office, "context.roamingSettings.set", undefined)) {
      Office.context.roamingSettings.set(SESSION_DATA_KEY, sessionData);

      Office.context.roamingSettings.saveAsync(() => {
        console.log("Session Roaming Data saved");
        if (callback) {
          callback();
        }
      });
    }
  }
}

// * Stored Personal DISC Mini ==================
export function getStoredPersonalDISCMini(): Tyto.DISCProfileMini | undefined {
  if (!_.get(Office, "context.roamingSettings")) {
    return;
  }

  try {
    const storedPersonalDISCMini = Office.context.roamingSettings.get(
      STORED_PERSONAL_DISC_MINI
    );

    const parsedPersonalDISCMini = JSON.parse(storedPersonalDISCMini);

    return parsedPersonalDISCMini || undefined;
  } catch (err) {
    return undefined;
  }
}

export function setStoredPersonalDISCMini(discMini: Tyto.DISCProfileMini) {
  if (!_.get(Office, "context.roamingSettings")) {
    return;
  }

  try {
    const discMiniAsJSON = JSON.stringify(discMini);

    Office.context.roamingSettings.set(
      STORED_PERSONAL_DISC_MINI,
      discMiniAsJSON
    );
  } catch (err) {
    console.warn("Failed to set Personal DISC Mini Profile");
  }
}

// * Dark Mode Setting ==============================
export function getViewAsDarkMode(): boolean | undefined {
  if (!_.get(Office, "context.roamingSettings")) {
    return undefined;
  }

  try {
    const storedDarkModeVal = Office.context.roamingSettings.get(
      VIEW_AS_DARK_MODE
    );

    const parsedDarkModeVal = JSON.parse(storedDarkModeVal);

    return !!parsedDarkModeVal;
  } catch (err) {
    return undefined;
  }
}

export function setViewAsDarkMode(newVal: boolean, callback?: () => void) {
  if (!_.get(Office, "context.roamingSettings")) {
    return;
  }

  try {
    const darkModeValAsJSON = !!newVal;

    Office.context.roamingSettings.set(
      VIEW_AS_DARK_MODE,
      darkModeValAsJSON
    );

    setLocalStorageItem(VIEW_AS_DARK_MODE, !!newVal);
    
    if (callback){
      callback();
    }
  } catch (err) {
    console.warn("Failed to set Dark Mode Preference");
  }
}

// * Recently Retrieved ================================
export function updateRecentlyRetrieved(people: Tyto.DISCProfileMini[]) {
  if (!Array.isArray(people) || !people.length) {
    return;
  }

  console.log("Updating Recent with: ", people);

  try {
    const recents = Office.context.roamingSettings.get(
      RECENTLY_RETRIEVED_PEOPLE
    );
    const mergedRecents = _.slice(
      _.uniqBy(_.flatten([people, recents || []]), "personID"),
      0,
      10
    );

    Office.context.roamingSettings.set(
      RECENTLY_RETRIEVED_PEOPLE,
      mergedRecents
    );

    saveRecentlyRetrieved();
  } catch (err) {
    console.warn(
      "An error occured while attempting to update Recently Retrieved list."
    );
  }
}

export function saveRecentlyRetrieved() {
  console.log("Starting timeout for saving roaming data.");
  if (saveKey) {
    clearTimeout(saveKey);
  }

  saveKey = setTimeout(() => {
    try {
      Office.context.roamingSettings.saveAsync(() => {
        console.log("Roaming Data saved");
      });
    } catch (err) {
      console.warn(
        "An error occured while attempting to update Recently Retrieved list."
      );
    }
  }, SAVE_ROAMING_DATA_TIMEOUT_MS);
}

export function getInterfaceContextParam(): InterfaceContext {
  const params = QueryString.parse(window.location.search);

  return params[INTERFACE_CONTEXT_PARAM_KEY] || DEFAULT_INTERFACE_CONTEXT_VALUE;
}
